import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as actions from 'domain/content/container/actions';
import { clearMachineConfigurations } from 'domain/recipe-designer/container/actions';
import {
  EditingModelConfigModel,
  MachineModelModel,
  EditableMachineModelModel,
} from 'domain/models/machine-type-model';
import { TosRootState } from 'reducers';
import MachineInformation from 'domain/content/components/machines/machineinformation/machineinformation';
import { getNewConfig, machineModelSelector, mapToAPIConfig } from './utils';

export interface OwnProps extends RouteComponentProps<{
  machineTypeId: string;
  machineId: string;
  languageId: string;
}> {
  isAddingNewModel: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

type Props = OwnProps & ReduxProps;

const Machines: React.FC<Props> = ({
  getMachineModelConfig,
  getDimrMachine,
  updateMachineModelConfigurations,
  addMachine,
  deleteMachineModel,
  updateMachineModel,
  ...props
}) => {
  const [selectedMachineModel, setSelectedMachineModel] = useState<
  MachineModelModel
  >();
  const [activeConfig, setActiveConfig] = useState<EditingModelConfigModel[]>(
    []
  );
  const [editableMachineModel, setEditableMachineModel] = useState<EditableMachineModelModel>({ isPublic: false, toolSystemId: '', machineModelTypeId: '' });

  // Update selected model when user clicks a model
  // or when machinemodels has finished loading
  useEffect(() => {
    if (props.machineModels) {
      setSelectedMachineModel(
        machineModelSelector(props.match.params.machineId, props.machineModels)
      );

      if (props.match.params.machineId) {
        getMachineModelConfig(props.match.params.machineId);
      }
    }
  }, [props.match.params.machineId, props.machineModels, getMachineModelConfig]);

  useEffect(() => {
    setEditableMachineModel({
      isPublic: selectedMachineModel?.isPublic || false,
      toolSystemId: selectedMachineModel?.toolSystemId || '',
      machineModelTypeId: selectedMachineModel?.machineModelTypeId || props.match.params.machineTypeId
    });
    setActiveConfig(selectedMachineModel?.possibleConfigs || []);
  }, [selectedMachineModel]);

  // Set dimrMachine to be the selected model when dimrMachine has loaded
  useEffect(() => {
    setSelectedMachineModel(props.dimrMachine);
  }, [props.dimrMachine]);

  useEffect(() => {
    setActiveConfig(props.machineModelConfig || []);
    setSelectedMachineModel(
      machineModelSelector(props.match.params.machineId, props.machineModels)
    );
  }, [props.machineModelConfig]);

  // Toggle config in model
  const toggleConfig = useCallback(
    (connectorTypeId: string, toolQuantity: string) => {
      const newConfig = getNewConfig(
        activeConfig,
        connectorTypeId,
        toolQuantity
      );

      setActiveConfig(newConfig);
    },
    [setActiveConfig, activeConfig]
  );

  // Save machine model
  const onSaveClick = useCallback(() => {
    const mappedConfig = mapToAPIConfig(activeConfig);

    if (props.isAddingNewModel) {
      addMachine(selectedMachineModel!.productNumber, mappedConfig, editableMachineModel);
      return;
    }
    props.clearMachineConfigurations();
    updateMachineModelConfigurations(selectedMachineModel!.id, mappedConfig);
    updateMachineModel(selectedMachineModel!.id, editableMachineModel);
  }, [
    selectedMachineModel,
    activeConfig,
    addMachine,
    updateMachineModelConfigurations,
    props.isAddingNewModel,
    editableMachineModel,
  ]);

  const onArticleNoInput = useCallback(
    articleNo => {
      getDimrMachine(articleNo);
    },
    [getDimrMachine]
  );

  const handleEditableModelChanged =
  (modelSubset: Partial<EditableMachineModelModel>) => {
    setEditableMachineModel({ ...editableMachineModel, ...modelSubset });
  };

  return (
    <div className="machine-information-wrapper">
      {(selectedMachineModel || props.isAddingNewModel) && (
        <>
          <MachineInformation
            machineModel={selectedMachineModel}
            toggleConfig={toggleConfig}
            configs={activeConfig}
            disableArticleNoInput={!props.isAddingNewModel}
            onEditableModelChanged={handleEditableModelChanged}
            onArticleNoInput={onArticleNoInput}
            isFetchingDimrMachine={props.isFetchingDimrMachine}
            machineTypes={props.machineModels}
            machineModelTypeId={editableMachineModel.machineModelTypeId}
            toolSystems={props.toolSystems}
            toolSystemId={editableMachineModel.toolSystemId}
          />
          {selectedMachineModel && !props.isFetchingDimrMachine && (
            <div className="button-wrapper">
              <button
                className="large primary"
                onClick={onSaveClick}
                type="button"
                disabled={
                  props.isSavingModelConfiguration || activeConfig?.length === 0
                }
              >
                Save
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({
  toolSystems,
  machineModels,
  content: { machines, },
}: TosRootState) => ({
  machineModels: machineModels.items,
  isFetchingMachines: machineModels.isFetching,
  isSavingModelConfiguration: machines.isSavingModelConfiguration,
  isFetchingDimrMachine: machines.isFetchingDimrMachine,
  dimrMachine: machines.dimrMachine,
  savedMachineModelId: machines.savedMachineModelId,
  machineModelConfig: machines.machineModelConfig,
  toolSystems: toolSystems.items
});

const connector = connect(
  mapStateToProps,
  { ...actions, clearMachineConfigurations }
);

export default connector(withRouter(Machines));
