import * as actionConstants from 'reducers/action-constants';

import { AnyAction } from 'redux';
import { ToolSelectionId } from 'domain/models/tool-selection-model';
import { ToolFamilyId } from 'domain/recipe-designer/container/recipe-config-reducer';
import { RecipeId } from 'domain/models/recipe-model';
import { MaterialId } from 'domain/models/material-type-model';
import { FinishId } from 'domain/models/finish-type-model';
import { MarketId } from 'domain/markets/container/markets-reducer';
import { MachineModelId } from 'domain/models/machine-type-model';

export interface RecipeUsage {
  recipeId: RecipeId
  materialId: MaterialId
  materialName: string
  finishId: FinishId
  finishName: string
  machineModelId: MachineModelId
  machineModelName: string
  marketId: MarketId
  marketName: string
}

export interface ToolApiModel {
  id: ToolSelectionId,
  updatedAt: string,
  toolFamilyId: ToolFamilyId,
  dimrToolId: string,
  productNumber: string,
  connectorTypeId: string,
  diameter: string,
  model:string,
  name: string,
  imageId: string
  imageURL: string
  recipes: RecipeUsage[]
}

export interface ToolStore {
  item?: ToolApiModel
  isFetching: boolean
}

export interface GetToolFailureAction extends AnyAction {
  type: typeof actionConstants.TOOL_REQ_FAILURE
}
export interface GetToolSuccessAction extends AnyAction {
  type: typeof actionConstants.TOOL_REQ_SUCCESS
}
export interface GetToolReqAction extends AnyAction {
  type: typeof actionConstants.TOOL_REQ
}

export type ToolReqActionTypes = GetToolReqAction | GetToolSuccessAction | GetToolFailureAction;

const getInitialState = () => ({
  isFetching: false
});

export default (state = getInitialState(), action: ToolReqActionTypes): ToolStore => {
  switch (action.type) {
    case actionConstants.TOOL_REQ:
      return {
        isFetching: true
      };
    case actionConstants.TOOL_REQ_SUCCESS:
      return {
        isFetching: false,
        item: action.response
      };
    case actionConstants.TOOL_REQ_FAILURE:
      return {
        isFetching: false
      };
    default:
      return state;
  }
};
