import { MaterialModelWithTexts } from 'domain/models/material-type-model';

export const nameTextSelector = (
  material: MaterialModelWithTexts | undefined,
  languageId: string
) => {
  return material?.nameTexts.find(f => f.languageCode === languageId)
    ?.translatedText;
};

export const descriptionTextSelector = (
  material: MaterialModelWithTexts | undefined,
  languageId: string
) => {
  return material?.descriptionTexts.find(f => f.languageCode === languageId)
    ?.translatedText;
};
