import { FinishFormModel } from 'domain/content/components/finishes/finish-form/finish-form-model';
import { FinishModelWithTexts } from 'domain/models/finish-type-model';

export const allRequiredEditFinishFieldsIsFilledIn = (formState: FinishFormModel) => {
  return !!formState.name && formState.uiOrder;
};

export const nameTextSelector = (
  finish: FinishModelWithTexts | undefined,
  languageId: string
) => {
  return finish?.nameTexts.find(f => f.languageCode === languageId)
    ?.translatedText;
};

export const subtitleTextSelector = (
  finish: FinishModelWithTexts | undefined,
  languageId: string
) => {
  return finish?.subtitleTexts.find(f => f.languageCode === languageId)
    ?.translatedText;
};

export const descriptionTextSelector = (
  finish: FinishModelWithTexts | undefined,
  languageId: string
) => {
  return finish?.descriptionTexts.find(f => f.languageCode === languageId)
    ?.translatedText;
};
