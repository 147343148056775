import { RecipeStep } from './machine-recipe-reducer';

export const moveUp = (steps: Array<RecipeStep>, step: RecipeStep, up = true): { steps: Array<RecipeStep>, isChanged: boolean } => {
  const pos = steps.findIndex(elem => elem.toolFamilyId === step.toolFamilyId);
  const newPos = up ? (pos - 1) : (pos + 1);
  if (newPos < 0 || newPos >= steps.length) {
    return { steps, isChanged: false };
  }

  const newSteps = [...steps];
  newSteps.splice(pos, 1);
  newSteps.splice(newPos, 0, step);
  return { steps: newSteps, isChanged: true };
};
