import React, { useState } from 'react';
import ListContainer from 'domain/tool-families/container/list';
import ToolFamilyContainer from 'domain/tool-families/container/tool-family';
import './content.scss';

const ToolFamiliesContent = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [toolFamilyType, setToolFamilyType] = useState('GRINDING');

  const handleCreate = (inIsCreating: boolean, inToolFamilyType: string = 'GRINDING') => {
    setIsCreating(inIsCreating);
    setToolFamilyType(inToolFamilyType);
  };

  return <div className="tool-families">
    <ListContainer onCreateClicked={handleCreate} />
    <ToolFamilyContainer
      isCreatingNew={isCreating}
      selectedToolFamilyType={toolFamilyType}
      onCreate={() => handleCreate(false, toolFamilyType)} />
  </div>;
};

export default ToolFamiliesContent;
