/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import List from 'domain/tool-families/components/view/list';
import { ToolFamilyModel } from 'domain/models/tool-family';
import * as actions from './actions';

type InputProps = {
  onCreateClicked: (isCreating: boolean) => void,
};

type Props = ConnectedProps<typeof connector> & InputProps;

const ToolFamiliesListContainer: React.FC<Props> = (props) => {
  useEffect(() => {
    props.getAllToolFamilies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Please Confirm\nDo you really want to delete tool family?')) {
      props.deleteToolFamily(id);
    }
  };

  return <List
    grindingList={props.grindingList}
    polishingList={props.polishingList}
    chemicalList={props.chemicalList}
    holderList={props.holderList}
    selectedId={props.selectedId}
    selectToolFamily={props.selectToolFamily}
    onCreateClicked={props.onCreateClicked}
    handleDelete={handleDelete}
  />;
};

const mapStateToProps = (state: any) => {
  const toolFamilyItems: Map<string, Array<ToolFamilyModel>> = state.allToolFamilies.itemsByType;

  return {
    isFetching: state.allToolFamilies.isFetching,
    grindingList: toolFamilyItems?.get('GRINDING'),
    polishingList: toolFamilyItems?.get('POLISHING'),
    chemicalList: toolFamilyItems?.get('CHEMICAL'),
    holderList: toolFamilyItems?.get('HOLDER'),
    selectedId: state.allToolFamilies.selectedToolFamilyId
  };
};

const connector = connect(
  mapStateToProps,
  actions
);

export default connector(ToolFamiliesListContainer);
