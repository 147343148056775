import React, { useCallback, useEffect, useState } from 'react';
import Input from 'app/form/input/input';
import {
  EditableMachineModelModel,
  MachineModelModel,
} from 'domain/models/machine-type-model';

export interface Props {
  machineModel?: MachineModelModel;
  onEditableModelChanged(data: Partial<EditableMachineModelModel>): void;
}

const MachineInfoForm: React.FC<Props> = ({
  machineModel,
  onEditableModelChanged
}) => {
  const [isPublic, setIsPublic] = useState<boolean>(
    machineModel?.isPublic || false
  );

  const handlePublicChanged = useCallback(
    e => {
      setIsPublic(e.target.checked);
      onEditableModelChanged({ isPublic: e.target.checked });
    },
    [onEditableModelChanged]
  );

  // Update article number when switching machineModel
  useEffect(() => {
    setIsPublic(machineModel?.isPublic || false);
  }, [machineModel]);

  return (
    <div className="card">
      <div className="card-title">Properties</div>
      <div className="values">
        <div>Public (visible in app)</div>
        <div className="value">
          <Input
            type="checkbox"
            name="isPublic"
            checked={isPublic}
            onChange={handlePublicChanged}
            styleOnWhite />
        </div>
      </div>
    </div>
  );
};

export default MachineInfoForm;
