import React, { MouseEvent } from 'react';
import cls from 'classnames';

interface Props {
  selectedId: string | undefined;
  listItem: {
    id: string;
    name: string;
    subListName?: string;
    subList?: Array<any>;
    isPublic?: boolean;
  };
  showDeleteIcon: boolean;
  isDeletable?: boolean;
  onRowClick(id: string, parentId?: string): void;
  onDeleteClick?(id: string, event?: MouseEvent): void;
}

const ListRow: React.FC<Props> = ({
  selectedId,
  listItem,
  showDeleteIcon,
  isDeletable,
  onRowClick,
  onDeleteClick,
}) => {
  const handleOnRowClick = (itemId: string, parentId?: string) => {
    onRowClick(itemId, parentId);
  };

  const handleOnDeleteClick = (e: MouseEvent, itemId: string) => {
    e.stopPropagation();
    // eslint-disable-next-line no-alert
    if (window.confirm('Do you really want to delete this entity?')) {
      onDeleteClick!(itemId);
    }
  };
  const showNotPublicIcon = listItem.isPublic === false;
  return (
    <>
      <div
        onClick={() => handleOnRowClick(listItem.id)}
        className={cls('list-row', {
          selected: selectedId === listItem.id,
        })}
      >
        <div className="name">{listItem.name}</div>
        {showDeleteIcon && (
          <div
            className={cls('symbols', { disabled: !isDeletable })}
            onClick={isDeletable ? (event) => handleOnDeleteClick(event, listItem.id) : undefined}
          >
            { showNotPublicIcon && <i className="far fa-eye-slash"></i>}
            <i className="far fa-trash-alt" />
          </div>
        )}
      </div>
      {listItem.subList && listItem.subList.length > 0 &&
        <div className="sub-list-rows">
          {listItem.subList?.map(subListItem => (
            <ListRow
              key={subListItem.id}
              selectedId={selectedId}
              listItem={subListItem}
              isDeletable={subListItem.isDeletable}
              showDeleteIcon={showDeleteIcon}
              onRowClick={() => handleOnRowClick(subListItem.id, `${listItem.id}/${listItem.subListName}`)}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </div>}
    </>
  );
};

export default ListRow;
