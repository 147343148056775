import React, { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import StatelessInput from 'app/form/labeled-input/input';
import TextArea from 'app/form/labeled-textarea/textarea';
import { TypeFormModel } from 'domain/content/components/any-types/type-form-model';

interface Props {
  formState: TypeFormModel;
  isSavingNewType: boolean;
  setFormState: Dispatch<SetStateAction<TypeFormModel>>;
  saveNewType: Function;
  setIsAdding: Function;
  isLoadingSuggestedUIOrder: boolean;
}

const isFormSubmittable = (formState: TypeFormModel) => formState.name && formState.uiOrder;

const AddNewType: React.FC<Props> = (props) => {
  const { setFormState, saveNewType, setIsAdding } = props;

  const onInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState(state => ({
        ...state,
        [name]: value,
      }));
    },
    [setFormState]
  );

  const onSaveNewType = useCallback(() => {
    saveNewType(
      props.formState.name,
      props.formState.description,
      props.formState.uiOrder
    );
    setIsAdding(false);
  }, [saveNewType, props.formState.name, props.formState.description, props.formState.uiOrder, setIsAdding]);

  return (
    <div className="add-new-type flex-col">
      <div className="two-part">
        <div className="flex-col">
          <StatelessInput
            name="name"
            label=" type name"
            value={props.formState.name}
            onChange={onInputChange}
          />
          <TextArea
            name="description"
            label="Description"
            value={props.formState.description}
            onChange={onInputChange}
          />
          <StatelessInput
            name="uiOrder"
            label="Sort order"
            type="number"
            value={props.formState.uiOrder}
            onChange={onInputChange}
            disabled={props.isLoadingSuggestedUIOrder}
          />
          <div className="button-wrapper">
            <button
              className="large primary"
              type="button"
              onClick={onSaveNewType}
              disabled={props.isSavingNewType || !isFormSubmittable(props.formState)}
            >
              Save
            </button>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default AddNewType;
