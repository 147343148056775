import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { TosRootState } from 'reducers';
import { ToolSystemFormState } from 'domain/models/tool-system-model';
import * as actions from 'domain/content/container/actions/content-tool-systems-actions';
import EditToolSystem from './edit-tool-system/edit-tool-system';
import './tool-systems.scss';

export interface Props
  extends RouteComponentProps<{
    toolSystemId: string;
    languageId: string;
  }>,
  ConnectedProps<typeof connector> {
  isAddingNewToolSystem: boolean;
}

const ToolSystems: React.FC<Props> = (props) => {

  const { toolSystemModels } = props;
  const { toolSystemId } = props?.match?.params;
  const selectedToolSystem = toolSystemModels?.find(toolSystem => toolSystem.id === toolSystemId);

  const onSubmit = (formState: ToolSystemFormState) => {
    if (props.isAddingNewToolSystem) {
      props.saveNewToolSystem(formState.name, formState.uiOrder);
    } else if (toolSystemId) {
      props.updateToolSystem(toolSystemId, formState.name, formState.uiOrder);
    }
  };

  return (
    <div className="tool-system-view">
      { toolSystemId
        ? (
          <div className="card">
            <EditToolSystem
              addNew={props.isAddingNewToolSystem}
              toolSystem={selectedToolSystem}
              disableInput={props.isSavingNewToolSystem || props.isUpdatingToolSystem}
              onSubmit={onSubmit}
            />
          </div>)
        : null}
    </div>
  );
};

const mapStateToProps = ({
  toolSystems,
  content: { toolSystems: contentToolSystems },
}: TosRootState) => ({
  toolSystemModels: toolSystems.items,
  isFetchingToolSystems: contentToolSystems.isFetching,
  isSavingNewToolSystem: contentToolSystems.isSavingNewToolSystem,
  isUpdatingToolSystem: contentToolSystems.isUpdatingToolSystem
});

const connector = connect(
  mapStateToProps,
  {
    ...actions
  }
);

export default connector(withRouter(ToolSystems));
