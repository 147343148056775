import { CALL_API } from 'app/middleware/api';
import { success } from 'app/general/messages/toast-util';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import { RecipeId } from 'domain/models/recipe-model';
import { MachineModelId } from 'domain/models/machine-type-model';
import { recipeKey } from 'domain/recipe-designer/container/recipe-config-reducer';
import { TosRootState } from 'reducers/index';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import * as action from 'reducers/action-constants';
import { ToolSystemId } from 'domain/models/tool-system-model';

const createGetRecipesAction = (
  filter?: RecipeFiltersModel,
  types = [
    action.QUERY_RECIPIES_REQ,
    action.QUERY_RECIPIES_REQ_SUCCESS,
    action.QUERY_RECIPIES_REQ_FAILURE
  ],
  selectables?: any
): AnyAction => {
  const criteria = [];
  if (filter && filter.materialId) {
    criteria.push(`materialId=${filter.materialId}`);
  }
  if (filter && filter.finishId) {
    criteria.push(`finishId=${filter.finishId}`);
  }
  if (filter && filter.marketId) {
    criteria.push(`marketId=${filter.marketId}`);
  }
  if (filter && filter.machineModelId) {
    criteria.push(`machineModelId=${filter.machineModelId}`);
  }
  const queryString = criteria.join('&');
  const pathname = `/v1/surface-prep/admin/recipes?${queryString}`;

  return {
    type: 'thunk',
    [CALL_API]: {
      types,
      pathname,
      context: {
        filter,
        selectables
      }
    }
  };
};

/**
 * Requests all recipes
 */
export const getRecipes = (filter?: RecipeFiltersModel):
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => dispatch(createGetRecipesAction(filter));

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
const marketActions = [action.Q_MARKETS_REQ, action.Q_MARKETS_REQ_SUCCESS, action.Q_MARKETS_REQ_FAILURE];
/**
 * Find selectable markets
 */
export const getMarketSelectables = ():
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any
): Promise<any> => {
  const state: TosRootState = getState();
  return dispatch(createGetRecipesAction({}, marketActions, state.markets.selectables));
};

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
const materialActions = [action.Q_MATERIAL_REQ, action.Q_MATERIAL_REQ_SUCCESS, action.Q_MATERIAL_REQ_FAILURE];
/**
 * Find selectable markets
 */
export const getMaterialSelectables = (filter?: RecipeFiltersModel):
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any
): Promise<any> => {
  const state: TosRootState = getState();
  return dispatch(createGetRecipesAction({ marketId: filter?.marketId }, materialActions, state.materials.selectables));
};

/**
 * Clears the selactables
 */
export const clearMaterialSelection = () => (dispatch: (action: any) => void) => {
  dispatch({
    type: action.MATERIAL_CLEAR
  });
};

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
const finishActions = [action.Q_FINISH_REQ, action.Q_FINISH_REQ_SUCCESS, action.Q_FINISH_REQ_FAILURE];
/**
 * Find selectable finishes
 */
export const getFinishSelectables = (filter?: RecipeFiltersModel):
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any
): Promise<any> => {
  const state: TosRootState = getState();
  const f = {
    marketId: filter?.marketId,
    materialId: filter?.materialId
  };
  return dispatch(createGetRecipesAction(f, finishActions, state.finishes.selectables));
};

/**
 * Clears the selactables
 */
export const clearFinishSelection = () => (dispatch: (action: any) => void) => {
  dispatch({
    type: action.FINISH_CLEAR
  });
};

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
const machineModelActions = [action.Q_MACHINE_REQ, action.Q_MACHINE_REQ_SUCCESS, action.Q_MACHINE_REQ_FAILURE];
/**
 * Find selectable finishes
 */
export const getMachineModelSelectables = (filter?: RecipeFiltersModel):
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any
): Promise<any> => {
  const state: TosRootState = getState();
  const f = {
    marketId: filter?.marketId,
    materialId: filter?.materialId,
    finishId: filter?.finishId
  };
  return dispatch(createGetRecipesAction(f, machineModelActions, state.machineModels.selectables));
};

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
const toolSystemActions = [action.Q_TOOLSYSTEM_REQ, action.Q_TOOLSYSTEM_REQ_SUCCESS, action.Q_TOOLSYSTEM_REQ_FAILURE];
/**
 * Find selectable finishes
 */
export const getToolSystemSelectables = (filter?: RecipeFiltersModel):
ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => any
): Promise<any> => {
  const state: TosRootState = getState();
  const f = {
    marketId: filter?.marketId,
    materialId: filter?.materialId,
    finishId: filter?.finishId,
    machineModelId: filter?.machineModelId,
  };
  return dispatch(createGetRecipesAction(f, toolSystemActions, state.toolSystems.selectables));
};

export const clearToolSystemSelection = () => (dispatch: (action: any) => void) => {
  dispatch({
    type: action.TOOLSYSTEM_CLEAR
  });
};

/**
 * Clears the selactables
 */
export const clearMachineModelSelection = () => (dispatch: (action: any) => void) => {
  dispatch({
    type: action.MACHINE_CLEAR
  });
};

export const clearRecipesFilter = () => (dispatch: Function) => dispatch({
  type: action.CLEAR_QUERY_RECIPIES_FILTER
});

export const clearMachineRecipe = () => (dispatch: Function) => dispatch({
  type: action.CLEAR_MACHINE_RECIPE
});

const createGetTFAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.GET_ALL_TOOLFAMILY_REQ,
      action.GET_ALL_TOOLFAMILY_REQ_SUCCESS,
      action.GET_ALL_TOOLFAMILY_REQ_FAILURE
    ],
    pathname: '/v1/surface-prep/admin/tool-families',
    context: {
      pathname: '/v1/surface-prep/admin/tool-families'
    }
  }
});

export const getAllToolFamilies = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetTFAction());
  };
};

/**
 * Materials action
 */
const createGetMaterialsAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.MATERIALS_REQ,
      action.MATERIALS_REQ_SUCCESS,
      action.MATERIALS_REQ_FAILURE
    ],
    pathname: '/v1/surface-prep/materials?languageCode=en'
  }
});

/**
 * Requests all materials
 */
export const getMaterials = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any): Promise<any> => {
    const state: TosRootState = getState();
    if (state.materials && state.materials.items) {
      return Promise.resolve({});
    }
    return dispatch(createGetMaterialsAction());
  };
};

const createGetFinishesAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.FINISHES_REQ,
      action.FINISHES_REQ_SUCCESS,
      action.FINISHES_REQ_FAILURE
    ],
    pathname: '/v2/surface-prep/finishes?languageCode=en',
    context: {
      path: '/v2/surface-prep/finishes?languageCode=en'
    }
  }
});

/**
 * Requests all finishes
 */
export const getFinishes = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any): Promise<any> => {
    const finishesState = getState().finishes;
    if (finishesState && finishesState.items) {
      return Promise.resolve({});
    }
    return dispatch(createGetFinishesAction());
  };
};

const createSaveConfigAction = (recipeId: RecipeId, machineModelId: MachineModelId, toolSystemId: ToolSystemId, stepConfig: Array<any>): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.SAVE_RECIPE_CONFIG_REQ,
      action.SAVE_RECIPE_CONFIG_REQ_SUCCESS,
      action.SAVE_RECIPE_CONFIG_REQ_FAILURE
    ],
    pathname: `/v1/surface-prep/admin/recipes/${recipeId}/machine-model/${machineModelId}/tool-system/${toolSystemId}/defaultConfig`,
    options: {
      method: 'PUT',
      body: JSON.stringify(stepConfig)
    },
    context: {
      recipeId,
      machineModelId
    }
  }
});

/**
 * Requests all tool families
 */
export const saveConfig = (recipeId: RecipeId, machineModelId: MachineModelId, toolSystemId: ToolSystemId): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const store: TosRootState = getState();
    const recipe = store.recipeConfig.configByRecipe.get(recipeKey(recipeId, machineModelId));
    if (!recipe) {
      throw new Error('No config for recipe');
    }
    const payload : Array<any> = [];
    recipe.stepConfig.forEach((value) => {
      payload.push({
        toolFamilyId: value.toolFamilyId,
        configurationId: value.configurationId,
        area: value.area,
        toolSelectionId: value.toolSelectionId,
        inactive: value.inactive
      });

    });
    const outcome = await dispatch(createSaveConfigAction(recipeId, machineModelId, toolSystemId, payload));
    if (outcome.type === action.SAVE_RECIPE_CONFIG_REQ_SUCCESS) {
      success('Recipe saved');
    }
    return outcome;
  };
};

const createGetMachineModelsAction = () => ({
  [CALL_API]: {
    types: [
      action.MODELS_REQ,
      action.MODELS_REQ_SUCCESS,
      action.MODELS_REQ_FAILURE
    ],
    pathname: '/v2/surface-prep/admin/machine-models'
  }
});

/**
 * Requests all machines
 */
export const getMachineModels = () => (dispatch: (action: any) => void, getState: () => any) => {
  const modelstate = getState().machineModels;
  if (modelstate && modelstate.items) {
    return Promise.resolve({});
  }
  return dispatch(createGetMachineModelsAction());
};

const createGetToolSystemsAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.TOOLSYSTEMS_REQ,
      action.TOOLSYSTEMS_REQ_SUCCESS,
      action.TOOLSYSTEMS_REQ_FAILURE
    ],
    pathname: '/v1/surface-prep/admin/tool-systems',
    options: {
      method: 'GET',
    },
  }
});

/**
 * Requests all toolsystems for given machine model
 */
export const getToolSystems = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any): Promise<any> => {
    const state: TosRootState = getState();
    if (state.toolSystems && state.toolSystems.items) {
      return Promise.resolve({});
    }
    return dispatch(createGetToolSystemsAction());
  };
};
