import * as React from 'react';
import './wait.scss';

interface Props {
  size?: string,
  slowReveal?: boolean
}

const Wait: React.FC<Props> = ({ size, slowReveal = false }: Props) => {

  return <div className={`w ${size || ''}${slowReveal ? ' delayed' : ''}`}>
    <div className="loader">Loading...</div>
  </div>;
};

export default Wait;
