import * as React from 'react';
import cls from 'classnames';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import './selectable-item.scss';

export interface Props {
  selectable: UISelectableModel
  isSelected:boolean
  isAvailable?:boolean
  onClick: Function
}

const SelectableItem: React.FC<Props> = ({ selectable, isSelected, isAvailable, onClick }: Props) => {
  return <div
    onClick={() => onClick(selectable)}
    className={cls('rsi-comp', {
      selected: isSelected,
      unavailable: !isAvailable
    })}>{selectable.label}
  </div>;
};

export default SelectableItem;
