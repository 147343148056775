import * as React from 'react';
import './body-with-top-menu.scss';

interface Props {
  className?: any;
  topbar?: any;
  content?: any;
  contentClassName?: string;
}

const BodyWithTopmenu: React.FC<Props> = ({ className, topbar, content, contentClassName }: Props) => {
  return <div className={`tom${className ? ` ${className}` : ''}`}>
    <div className="top">
      { topbar }
    </div>
    <div className={`main ${contentClassName ? ` ${contentClassName}` : ''}`}>
      { content }
    </div>
  </div>;
};

export default BodyWithTopmenu;
