import * as React from 'react';
import './input.scss';

interface InpProps {
  onChange?: (event: any) => void
  onValueChanged?: (event: any) => void
  name: string
  type: string
  maxLength?: number;
  tabIndex?: number
  label?: string
  placeholder?: string
  value?: number | string | boolean
  disabled?: boolean
  autoFocus?: boolean
  required?: boolean
  title?: string
  styleOnWhite?: boolean
  checked?: boolean
}
interface State {
  value?: any
  initial?: any
}

class Input extends React.PureComponent<InpProps, State> {
  constructor (props: InpProps) {
    super(props);
    this.state = {
      value: props.value || '',
      initial: props.value
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onChange (event: any) {
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  onFocus (event: any) {
    this.setState({ initial: event.target.value });
  }

  onBlur (event: any) {
    const changed = (this.state.initial !== event.target.value);
    if (changed && this.props.onValueChanged) {
      this.props.onValueChanged(event);
    }
  }

  render () {
    return (
      <div className={`ic ${this.props.disabled ? 'disabled' : ''}${this.props.styleOnWhite ? ' on-white' : ''}`}>
        {this.props.label && (
          <label htmlFor={this.props.name}>
            {this.props.label}
          </label>
        )}
        <input
          type={this.props.type}
          name={this.props.name}
          id={this.props.name}
          tabIndex={this.props.tabIndex}
          value={this.state.value}
          checked={this.props.checked}
          autoComplete="off"
          disabled={this.props.disabled || false}
          readOnly={this.props.disabled || false}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          // @ts-ignore
          // eslint-disable-next-line
          ref={element => (element || {}).onsearch = this.onChange}
          title={this.props.title}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur} />
      </div>
    );
  }
}

export default Input;
