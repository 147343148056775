import { Reducer } from 'redux';

import {
  LOOKUP_DIMR_TOOL_REQ, LOOKUP_DIMR_TOOL_REQ_SUCCESS, LOOKUP_DIMR_TOOL_REQ_FAILURE
} from 'reducers/action-constants';

export interface DimrToolStore {
  isFetching: boolean,
  item?: any
}

const getInitialState = () => {
  return {
    isFetching: false
  };
};

const reducer: Reducer<DimrToolStore> = (state = getInitialState(), action: any) => {
  switch (action.type) {
    case LOOKUP_DIMR_TOOL_REQ:
      return {
        ...state,
        isFetching: true
      };
    case LOOKUP_DIMR_TOOL_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: {
          ...action.response
        }
      };
    case LOOKUP_DIMR_TOOL_REQ_FAILURE:
      return getInitialState();
    default:
      return state;
  }
};

export default reducer;
