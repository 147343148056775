import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Input from 'app/form/labeled-input/input';
import { ToolSystemFormState, ToolSystemModel } from 'domain/models/tool-system-model';

export interface Props {
  disableInput?: boolean;
  addNew?: boolean;
  toolSystem?: ToolSystemModel;
  onSubmit(formState: ToolSystemFormState): void;
}

const AddNewToolSystem = (props: Props) => {
  const { addNew = false, disableInput = false, toolSystem, onSubmit } = props;

  const initialFormState = {
    name: '',
    uiOrder: ''
  };
  const [formState, setFormState] = useState<ToolSystemFormState>(initialFormState);

  useEffect(() => {
    setFormState({
      name: toolSystem?.name || '',
      uiOrder: toolSystem?.uiOrder || ''
    });
  }, [toolSystem]);

  const onChange = (prop: string) => useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFormState({ ...formState, [prop]: e.target.value }),
    [formState, setFormState]);

  const onClick = (): void => {
    onSubmit(formState);
    if (addNew) {
      setFormState(initialFormState);
    }
  };

  return (
    <div className="card">
      <div className="card-title">Tool System Attributes</div>
      <div className="two-part">
        <Input
          name="name"
          type="text"
          label="Name"
          disabled={disableInput}
          value={formState.name}
          onChange={onChange('name')}
        />
        <Input
          name="uiOrder"
          type="text"
          label="UiOrder"
          disabled={disableInput}
          value={formState.uiOrder}
          onChange={onChange('uiOrder')}
        />
      </div>
      <div className="button-wrapper">
        <button
          className="large primary"
          onClick={onClick}
          disabled={disableInput}
          type="button">
          {addNew ? 'Add' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default AddNewToolSystem;
