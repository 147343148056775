import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TosRootState } from 'reducers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MachineType } from 'domain/models/machine-type-model';
import * as actions from 'domain/content/container/actions';
import './machine-types.scss';
import { TypeFormModel } from 'domain/content/components/any-types/type-form-model';
import EditType from 'domain/content/components/any-types/edit-type/edit-type';
import AddNewType from 'domain/content/components/any-types/add-new-type/add-new-type';
import { initialFormState } from '../any-types/type-form-model';

export interface Props extends RouteComponentProps<{
  machineTypeId: string;
  languageId: string;
}> {
  selectedId: string;
  machineTypes?: Array<MachineType>;
  isAddingNewMachineType: boolean;
  setIsAdding: Function;
}

type ReduxProps = ConnectedProps<typeof connector>;

const MachineTypes: React.FC<Props & ReduxProps> = (props) => {
  const [formState, setFormState] = useState<TypeFormModel>({ ...initialFormState });

  const { getMachineType } = props;

  const handleLanguageClick = useCallback(
    (languageId: string) => {
      props.history.push(
        `/content/machine-types/${props.match.params.machineTypeId}/${languageId}`
      );
    },
    [props.history, props.match]
  );

  useEffect(() => {
    if (!props.isAddingNewMachineType && !!props.match.params.machineTypeId && props.match.params.machineTypeId !== 'new' && !props.match.params.languageId) {
      handleLanguageClick('en');
    }
  }, [handleLanguageClick, props.isAddingNewMachineType, props.match.params.machineTypeId, props.match.params.languageId]);

  // Fetch machine type when top machineTypeId changes
  useEffect(() => {
    if (props.isSavingTextItems || !props.match.params.machineTypeId || props.match.params.machineTypeId === 'new') {
      return;
    }

    if (!props.isSavingTextItems) {
      getMachineType(props.match.params.machineTypeId);
    }
  }, [getMachineType, props.match.params.machineTypeId, props.isSavingTextItems]);

  useEffect(() => {
    if (props.isAddingNewMachineType) {
      setFormState(initialFormState);
    }
  }, [props.isAddingNewMachineType]);

  useEffect(() => {
    if (props.createdMachineType) {
      props.history.push(`/content/machine-types/${props.createdMachineType.id}/en`);
    }
  }, [props.createdMachineType, props.history]);

  return (
    <div className="machine-types-view">
      {props.isAddingNewMachineType ? (
        <AddNewType
          formState={formState}
          setFormState={setFormState}
          isSavingNewType={props.isSavingNewMachineType}
          saveNewType={props.saveNewMachineType}
          setIsAdding={props.setIsAdding}
          isLoadingSuggestedUIOrder={props.isLoadingSuggestedUIOrder}
          />
      ) : (
        (props.match.params.machineTypeId && props.loadedMachineType) && (
          <EditType
            id="machine-types"
            isLoadingType={props.isLoadingMachineType}
            languageId={props.match.params.languageId}
            selectedId={props.selectedId}
            formState={formState}
            setFormState={setFormState}
            handleLanguageClick={handleLanguageClick}
            loadedType={props.loadedMachineType}
            saveTextItems={props.saveTextItems}
            isSavingTextItems={props.isSavingTextItems}
          />
        )
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getMachineType: actions.getMachineType,
  saveTextItems: actions.saveTextItems,
  saveNewMachineType: actions.saveNewMachineType,
};

const mapStateToProps = ({ content: { machineTypes } }: TosRootState) => ({
  isSavingNewMachineType: machineTypes.isSavingNewMachineType,
  suggestedUIOrder: machineTypes.suggestedUIOrder,
  createdMachineType: machineTypes.createdMachineType,
  loadedMachineType: machineTypes.loadedMachineType,
  isLoadingMachineType: machineTypes.isLoadingMachineType,
  isSavingTextItems: machineTypes.isSavingTextItems,
  isLoadingSuggestedUIOrder: machineTypes.isLoadingSuggestedUIOrder
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(MachineTypes));
