/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import { Reducer } from 'redux';
import * as actionConstants from 'reducers/action-constants';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import { availableSelectableFromItems } from './recipe-utils';

interface SelectablesFilterModel {
  isFetching?: boolean,
  submittedFilter?: any,
  selectables?: Array<UIAvailableSelectableModel>
}
interface RecipePickerStore {
  fetchingCount: number,
  markets: SelectablesFilterModel
  materials: SelectablesFilterModel
  finishes: SelectablesFilterModel
  machines: SelectablesFilterModel
  toolSystems: SelectablesFilterModel
}

const getInitialState = (): RecipePickerStore => ({
  fetchingCount: 0,
  markets: {},
  materials: {},
  finishes: {},
  machines: {},
  toolSystems: {}
});

const handleSelectables = (
  propName: string,
  action: any,
  state: RecipePickerStore,
  req: string,
  reqSuccess: string,
  reqFailure: string,
  reqClear: string
) => {
  switch (action.type) {
    case req:
      return {
        ...state,
        fetchingCount: state.fetchingCount + 1,
        [propName]: {
          isFetching: true,
          submittedFilter: action.context.filter
        }
      };
    case reqSuccess:
      return {
        ...state,
        fetchingCount: state.fetchingCount - 1,
        [propName]: {
          // @ts-ignore
          ...state[propName],
          isFetching: false,
          selectables: availableSelectableFromItems(
            'value',
            'label',
            action.context.selectables,
            action.response.filters[propName]
          )
        }
      };
    case reqFailure:
      return {
        ...state,
        fetchingCount: state.fetchingCount - 1,
        [propName]: {
          // @ts-ignore
          ...state[propName],
          isFetching: false,
          selectables: []
        }
      };
    case reqClear:
      return {
        ...state,
        [propName]: {
          // @ts-ignore
          ...state[propName],
          selectables: []
        }
      };
    default:
      return undefined;
  }
};

const reducer: Reducer<RecipePickerStore> = (state = getInitialState(), action: any): any => {
  return (
    handleSelectables('markets', action, state, actionConstants.Q_MARKETS_REQ, actionConstants.Q_MARKETS_REQ_SUCCESS, actionConstants.Q_MARKETS_REQ_FAILURE, 'ignored') ||
    handleSelectables('materials', action, state, actionConstants.Q_MATERIAL_REQ, actionConstants.Q_MATERIAL_REQ_SUCCESS, actionConstants.Q_MATERIAL_REQ_FAILURE, actionConstants.MATERIAL_CLEAR) ||
    handleSelectables('finishes', action, state, actionConstants.Q_FINISH_REQ, actionConstants.Q_FINISH_REQ_SUCCESS, actionConstants.Q_FINISH_REQ_FAILURE, actionConstants.FINISH_CLEAR) ||
    handleSelectables('machines', action, state, actionConstants.Q_MACHINE_REQ, actionConstants.Q_MACHINE_REQ_SUCCESS, actionConstants.Q_MACHINE_REQ_FAILURE, actionConstants.MACHINE_CLEAR) ||
    handleSelectables('toolSystems', action, state, actionConstants.Q_TOOLSYSTEM_REQ, actionConstants.Q_TOOLSYSTEM_REQ_SUCCESS, actionConstants.Q_TOOLSYSTEM_REQ_FAILURE, actionConstants.TOOLSYSTEM_CLEAR) ||
    state
  );
};

export default reducer;
