import Input from 'app/form/input/input';
import React, { FC } from 'react';
import './active-input.scss';

interface Props {
  readonly inactive?: boolean
  readonly onClick: (inactive: boolean) => void
}

const ActiveInput: FC<Props> = ({ inactive, onClick }: Props) => {
  return <div className="active-cmp">
    <div>
      Active
    </div>
    <div>
      Uncheck this to hide this step for the given selection
    </div>
    <div>
      <Input
        type="checkbox"
        name="isActive"
        checked={!inactive}
        onChange={() => onClick(!inactive)}
        styleOnWhite />
    </div>
  </div>;
};

export default ActiveInput;
