import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  path: string;
  headers: Array<{
    name: string;
    text: string;
  }>;
  filter?: (name: string) => void;
  selected?: string;
}

const ListHeader: React.FC<Props> = ({ path, headers }) => {
  const hasOneHeader = headers.length === 1;

  return (
    <div className="list-header">
      {hasOneHeader ? (
        <span>{headers[0].text}</span>
      ) : (
        headers.map(header => (
          <NavLink
            key={header.name}
            to={`/${path}/${header.name}`}
            activeClassName="selected"
          >
            {header.text}
          </NavLink>
        ))
      )}
    </div>
  );
};

export default ListHeader;
