import * as React from 'react';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import './selectable-item.scss';

interface Props {
  selectable: UIAvailableSelectableModel
  isSelected:boolean
  onClick: Function
  allAvailible?: boolean
}

const SelectableItem: React.FC<Props> = ({ selectable, isSelected, onClick, allAvailible }: Props) => {
  return <div
    onClick={() => onClick(selectable)}
    className={`rsi-comp${!selectable.isAvailable && !allAvailible ? ' unavailable' : ''}${isSelected ? ' selected' : ''}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: selectable.label }}>
  </div>;
};

export default SelectableItem;
