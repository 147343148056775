import React, { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import Input from 'app/form/labeled-input/input';
import TextArea from 'app/form/labeled-textarea/textarea';
import List, { Header } from 'app/list/list';
import { MaterialTypeModel } from 'domain/models/material-type-model';
import { MaterialFormModel } from './material-form-model';

const headers: Array<Header> = [
  {
    text: 'Material Type',
    name: 'materialTypeId',
  },
];

interface Props {
  formState: MaterialFormModel;
  isSavingNewMaterial: boolean;
  setFormState: Dispatch<SetStateAction<MaterialFormModel>>;
  materialTypes?: Array<MaterialTypeModel>;
  saveNewMaterial: Function;
  setIsAdding: Function;
  suggestedUIOrder?: string;
  isLoadingSuggestedUIOrder: boolean;
  getSuggestedUIOrder: Function;
}

const isFormSubmittable = (formState: MaterialFormModel) => formState.materialTypeId && formState.name && formState.uiOrder;

const AddNewMaterial: React.FC<Props> = (props) => {
  const { setFormState, saveNewMaterial, setIsAdding, getSuggestedUIOrder } = props;

  const onInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState(state => ({
        ...state,
        [name]: value,
      }));
    },
    [setFormState]
  );

  const selectableValues = {
    materialTypeId: props.materialTypes?.map(materialType => ({
      id: materialType.id,
      name: materialType.name,
    })),
  };

  const onRowClick = useCallback(
    (id: string) => {
      setFormState(state => ({
        ...state,
        materialTypeId: id,
      }));
      getSuggestedUIOrder(id);
    },
    [setFormState, getSuggestedUIOrder]
  );

  const onSaveNewMaterial = useCallback(() => {
    saveNewMaterial(
      props.formState.materialTypeId,
      props.formState.name,
      props.formState.description
    );
    setIsAdding(false);
  }, [saveNewMaterial, props.formState.materialTypeId, props.formState.name, props.formState.description, setIsAdding]);

  return (
    <div className="add-new-material flex-col">
      <div className="two-part">
        <List
          path="content"
          selectedType="materialTypeId"
          selectedId={props.formState.materialTypeId}
          headers={headers}
          lists={selectableValues}
          onRowClick={onRowClick}
          isItemsDeletable={false}
          listItemsUppercase
        />
        <div className="flex-col">
          <Input
            name="name"
            label="Material name"
            value={props.formState.name}
            onChange={onInputChange}
          />
          <TextArea
            name="description"
            label="Description"
            value={props.formState.description}
            onChange={onInputChange}
          />
          <Input
            name="uiOrder"
            label="Sort order"
            type="number"
            value={props.formState.uiOrder}
            onChange={onInputChange}
            disabled={props.isLoadingSuggestedUIOrder}
          />
        </div>
      </div>
      <div className="button-wrapper">
        <button
          className="large primary"
          onClick={onSaveNewMaterial}
          disabled={props.isSavingNewMaterial || !isFormSubmittable(props.formState)}
          type="button">
          Save
        </button>
      </div>
    </div>
  );
};

export default AddNewMaterial;
