import React from 'react';
import cls from 'classnames';
import Wait from 'app/wait/wait';
import ListHeader from './table/list-header';
import ListRow from './table/list-row';
import './content-list.scss';

export type Header = {
  name: string;
  text: string;
};

export type ListItem = {
  id: string;
  name: string;
  subListName?: string;
  subList?: Array<any>;
  isDeletable?: boolean;
};

interface Props {
  path: string;
  selectedType: string;
  selectedId: string | undefined;
  headers: Array<Header>;
  lists: {
    [key: string]: Array<ListItem> | undefined;
  };
  footer?: React.ReactNode;
  isItemsDeletable?: boolean;
  listItemsUppercase?: boolean;
  onRowClick(id: string, parentId?: string): void;
  onRemoveClick?(id: string): void;
}

const List: React.FC<Props> = props => {
  const itemsForActiveType = props.lists[props.selectedType];

  return (
    <div className="content-list">
      <div className="card">
        <ListHeader path={props.path} headers={props.headers} />
        <div
          className={cls('row-container', {
            uppercase: props.listItemsUppercase,
          })}
        >
          {!itemsForActiveType ? (
            <div>
              <Wait />
            </div>
          ) : (
            itemsForActiveType.map(item => (
              <ListRow
                key={item.id}
                selectedId={props.selectedId}
                listItem={item}
                isDeletable={item.isDeletable}
                showDeleteIcon={props.isItemsDeletable!}
                onRowClick={props.onRowClick}
                onDeleteClick={props.onRemoveClick}
              />
            ))
          )}
        </div>
        {props.footer && <div className="list-footer">{props.footer}</div>}
      </div>
    </div>
  );
};

List.defaultProps = {
  isItemsDeletable: true,
};

export default List;
