import { MaterialId } from 'domain/models/material-type-model';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CALL_API } from 'app/middleware/api';
import * as actionConstants from 'reducers/action-constants';
import * as toastUtils from 'app/general/messages/toast-util';

/**
 * Get materials
 */
export const createGetMaterialsActions = () => ({
  [CALL_API]: {
    types: [
      actionConstants.MATERIALS_REQ,
      actionConstants.MATERIALS_REQ_SUCCESS,
      actionConstants.MATERIALS_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/materials',
    options: {
      method: 'GET',
    },
  },
});

export const getMaterials = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMaterialsActions());
};

/**
 * Get material
 */
const createGetMaterialActions = (materialId: string | MaterialId): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.MATERIAL_REQ,
      actionConstants.MATERIAL_REQ_SUCCESS,
      actionConstants.MATERIAL_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/materials/${materialId}?languageCode=en`,
    options: {
      method: 'GET',
    },
  },
});

export const getMaterial = (materialId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMaterialActions(materialId));
};

/**
 * Get material
 */
const createGetMaterialTypeActions = (materialTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.GET_MATERIAL_TYPE_REQ,
      actionConstants.GET_MATERIAL_TYPE_REQ_SUCCESS,
      actionConstants.GET_MATERIAL_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/material-types/${materialTypeId}?languageCode=en`,
    options: {
      method: 'GET',
    },
  },
});

export const getMaterialType = (materialTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMaterialTypeActions(materialTypeId));
};

/**
 * Save new material
 */
const createSaveNewMaterialAction = (
  materialTypeId: string,
  name: string,
  description: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_MATERIAL_REQ,
      actionConstants.SAVE_NEW_MATERIAL_REQ_SUCCESS,
      actionConstants.SAVE_NEW_MATERIAL_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/materials?languageCode=en',
    options: {
      method: 'POST',
      body: JSON.stringify({
        materialTypeId,
        name,
        description,
      }),
    },
  },
});

export const saveNewMaterial = (
  materialTypeId: string,
  name: string,
  description: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveNewMaterialAction(materialTypeId, name, description)
  ).then(resp => {
    if (resp.type === actionConstants.SAVE_NEW_MATERIAL_REQ_SUCCESS) {
      toastUtils.success('New material successfully saved');
    }

    return dispatch(createResetCreatedMaterial()).then(() => {
      return dispatch(createGetMaterialsActions());
    });
  });
};

const createResetCreatedMaterial = () => ({
  type: actionConstants.RESET_CREATED_MATERIAL,
});

/**
 * Delete material
 */
const createDeleteMaterialAction = (materialId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_MATERIAL_REQ,
      actionConstants.DELETE_MATERIAL_REQ_SUCCESS,
      actionConstants.DELETE_MATERIAL_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/materials/${materialId}`,
    options: {
      method: 'DELETE',
    },
    context: {
      materialId,
    },
  },
});

export const deleteMaterial = (materialId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteMaterialAction(materialId)).then(e => {
    if (e.type === actionConstants.DELETE_MATERIAL_REQ_SUCCESS) {
      toastUtils.success('Material successfully deleted');
    }
    return dispatch(createGetMaterialsActions());
  });
};

/**
 * Save material image
 */
const createSaveMaterialImageAction = (
  materialId?: MaterialId,
  imageId?: string,
  filename?: string
): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.ATTACH_MATERIAL_IMAGE_REQ,
      actionConstants.ATTACH_MATERIAL_IMAGE_REQ_SUCCESS,
      actionConstants.ATTACH_MATERIAL_IMAGE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/materials/${materialId}/images`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        imageId,
        filename,
      }),
    },
  },
});

/**
 * Save image to a material
 * @param id
 * @param imageId
 * @param fileName
 */
export const saveMaterialImage = (
  id: MaterialId,
  imageId: string,
  fileName: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    const outcome = await dispatch(createSaveMaterialImageAction(id, imageId, fileName));
    if (outcome.type === actionConstants.ATTACH_MATERIAL_IMAGE_REQ_SUCCESS) {
      toastUtils.success('Image was successfully replaced');
      dispatch(createGetMaterialActions(id));
    }
  };
};

const createGetMaterialTypesAction = () => ({
  [CALL_API]: {
    types: [
      actionConstants.GET_MATERIAL_TYPES_REQ,
      actionConstants.GET_MATERIAL_TYPES_REQ_SUCCESS,
      actionConstants.GET_MATERIAL_TYPES_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/material-types',
    options: {
      method: 'GET',
    },
  },
});

export const getMaterialTypes = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMaterialTypesAction());
};

/**
 * Save new material type
 */
const createSaveNewMaterialTypeAction = (
  name: string,
  description: string,
  uiOrder: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ,
      actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ_SUCCESS,
      actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/material-types',
    options: {
      method: 'POST',
      body: JSON.stringify({
        materialTypeName: name,
        description,
        uiOrder,
      }),
    },
  },
});

export const saveNewMaterialType = (
  name: string,
  description: string,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveNewMaterialTypeAction(name, description, uiOrder)
  ).then(result => {
    if (result.type === actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ_SUCCESS) {
      toastUtils.success('New Material Type successfully created');
    }

    return dispatch(createGetMaterialsActions());
  });
};

/**
 * Delete material type
 */
const createDeleteMaterialTypeAction = (materialTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_MATERIAL_TYPE_REQ,
      actionConstants.DELETE_MATERIAL_TYPE_REQ_SUCCESS,
      actionConstants.DELETE_MATERIAL_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/material-types/${materialTypeId}`,
    options: {
      method: 'DELETE',
    },
  },
});

export const deleteMaterialType = (materialTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteMaterialTypeAction(materialTypeId)).then(
    result => {
      if (result.type === actionConstants.DELETE_MATERIAL_TYPE_REQ_SUCCESS) {
        toastUtils.success('Material Type successfully deleted');
      }

      return dispatch(createGetMaterialsActions());
    }
  );
};

/**
 * Get suggested material type uiOrder
 */
const createGetSuggestedMaterialTypeUIOrderActions = () => ({
  [CALL_API]: {
    types: [
      actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ,
      actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_SUCCESS,
      actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/material-types/ui-order',
    options: {
      method: 'GET',
    },
  },
});

export const getSuggestedMaterialTypeUIOrder = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetSuggestedMaterialTypeUIOrderActions());
};

/**
 * Get suggested material uiOrder
 */
const createGetSuggestedMaterialUIOrderActions = (materialTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ,
      actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_SUCCESS,
      actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/materials/ui-order/${materialTypeId}`,
    options: {
      method: 'GET',
    },
  },
});

export const getSuggestedMaterialUIOrder = (materialTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetSuggestedMaterialUIOrderActions(materialTypeId));
};
