import {
  AUTHENTICATION_INVALID,
  SERVER_ERROR,
  GENERAL_ERROR,
  REQUEST_ERROR,
  UNAUTHORIZED,
  SERVER_UNDERLYING_SYSTEM_ERROR,
  DB_TEMPORARILY_UNAVAILABLE,
  NOT_FOUND,
  VALIDATION_VIOLATION
} from 'app/utils/error-constants';

const errorFromHttp = (status: number) => {
  switch (status) {
    case 400:
      return REQUEST_ERROR;
    case 401:
      return AUTHENTICATION_INVALID;
    case 403:
      return UNAUTHORIZED;
    case 404:
      return NOT_FOUND;
    case 422:
      return VALIDATION_VIOLATION;
    case 504:
      return SERVER_UNDERLYING_SYSTEM_ERROR;
    case 503:
      return DB_TEMPORARILY_UNAVAILABLE;
    default:
      return SERVER_ERROR;
  }
};
export default async (error: any) => {
  if (error.ok === false) {
    const results = await error.json();
    return composeError(results.error, results.detail, errorFromHttp(error.status));
  }

  if (typeof error === 'string') {
    return composeError('Input error', error, GENERAL_ERROR);
  }
  // eslint-disable-next-line no-console
  console.error('Middleware caught an error', error);

  // eslint-disable-next-line no-console
  console.log('error', error.message);
  return composeError('Oops, we failed to carry out this action...', error.message || 'Unexpected error', SERVER_ERROR);
};

const composeError = (title:string, detail?:string, code?:string) => {
  return { title, detail, code };
};
