import React from 'react';
import ToolSelectionRow from 'domain/tool-families/components/view/editor/tool-selection-row';
import { ToolSelectionModel, ToolSelectionId } from 'domain/models/tool-selection-model';
import ToolSelectionAdd from 'domain/tool-families/components/view/editor/tool-selection-add';

type Props = {
  toolSelections?: Array<ToolSelectionModel>,
  handleAddTool: (articleNo: string, toolName: string) => void,
  handleSearchTool: (articleNumber: string) => void,
  handleDeleteToolSelection: (id: string) => void,
  onViewToolInfo: (id: ToolSelectionId) => void,
  isSearching: boolean,
  isFetchingDimrTool: boolean,
  toolSearchList: Array<any>
};

const ToolSelectionsEditor: React.FC<Props> = (props) => {
  return <div className="card">
    <div className="tool-family-table tool-selection">
      <div className="list-header">
        <div className="name">Article</div>
        <div className="number">Article No</div>
        <div className="action" />
        <div className="action" />
      </div>
      {props.toolSelections && props.toolSelections.length > 0 &&
        <div className="row-container">
          {props.toolSelections && props.toolSelections
            .filter(ts => ts.status !== 'DELETE')
            .map(ts => <ToolSelectionRow
              {...ts}
              key={ts.id}
              onDelete={props.handleDeleteToolSelection}
              onViewToolInfo={props.onViewToolInfo} />)}
        </div>}
      <ToolSelectionAdd
        handleAddTool={props.handleAddTool}
        handleSearchTool={props.handleSearchTool}
        isSearching={props.isSearching}
        isFetchingDimrTool={props.isFetchingDimrTool}
        toolSearchList={props.toolSearchList}
      />
    </div>
  </div>;
};

export default ToolSelectionsEditor;
