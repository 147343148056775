import { ToolFamilyModel } from 'domain/models/tool-family';

export const nameComparator = (a: ToolFamilyModel, b: ToolFamilyModel) => {
  return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
};

export const gritSizeComparator = (a: ToolFamilyModel, b: ToolFamilyModel) => {
  const gritSizeA = parseInt(a.gritSize, 10);
  const gritSizeB = parseInt(b.gritSize, 10);
  if (gritSizeA > gritSizeB) {
    return 1;
  }
  if (gritSizeA === gritSizeB) {
    return nameComparator(a, b);
  }
  return 0;
};

export const toolFamilyComparator = (a: ToolFamilyModel, b: ToolFamilyModel) => {
  if (a.gritSize && b.gritSize) {
    return gritSizeComparator(a, b);
  }
  return nameComparator(a, b);
};
