export interface TypeFormModel {
  name: string;
  description: string;
  uiOrder: string;
}

export const initialFormState: TypeFormModel = {
  name: '',
  description: '',
  uiOrder: '',
};
