import React from 'react';
import Menu from 'app/top-menu/menu';
import Body from 'app/body-with-left-pane/body-with-top-menu';
import UserSections from 'domain/users/components/wrapper/user-sections';
import { UserApiModel, UserId } from '../container/user-models';
import { DetailedUserApiModel } from '../container/reducer';

interface Props {
  users: Array<UserApiModel>
  usersPending: boolean
  userDetails?: DetailedUserApiModel
  onUserSelected: (id: UserId) => void
  onUserChanged: (user: UserApiModel) => void
}

const UsersPage: React.FC<Props> = (props) => {
  return <Body
    topbar={<Menu />}
    content={<UserSections {...props} />}
    />;
};

export default UsersPage;
