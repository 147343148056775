import {
  QUERY_RECIPIES_REQ, QUERY_RECIPIES_REQ_SUCCESS, QUERY_RECIPIES_REQ_FAILURE, CLEAR_QUERY_RECIPIES_FILTER
} from 'reducers/action-constants';

import { RecipesFilterStateModel } from 'domain/recipe-picker/container/recipes-filter-state-model';

const getInitialState = (): RecipesFilterStateModel => ({
  submittedFilter: {},
  isFetching: false,
  isFetched: false
});

export default (state = getInitialState(), action: any): RecipesFilterStateModel => {
  switch (action.type) {
    case QUERY_RECIPIES_REQ:
      return {
        ...state,
        isFetching: true,
        submittedFilter: action.context.filter
      };
    case QUERY_RECIPIES_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        recipes: action.response,
        fetchedFilter: action.context.filter
      };
    case QUERY_RECIPIES_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: false
      };
    case CLEAR_QUERY_RECIPIES_FILTER:
      return getInitialState();
    default:
      return state;
  }
};
