import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Input from 'app/form/labeled-input/input';
import TextArea from 'app/form/labeled-textarea/textarea';
import Wait from 'app/wait/wait';
import { MaterialModelWithTexts } from 'domain/models/material-type-model';
import { saveTextItems as saveTextItemsAction } from 'domain/content/container/actions';
import ImageArea from 'app/file-upload/image-area';
import { initialFormState } from 'domain/content/components/materials/materials';
import LanguageList from 'domain/content/components/language-list/language-list';
import { MaterialFormModel } from 'domain/content/components/materials/add-new-material/material-form-model';
import {
  descriptionTextSelector,
  nameTextSelector,
} from '../utils';

interface Props extends RouteComponentProps<{ languageId: string }> {
  isLoadingMaterial: boolean;
  isSavingMaterialTexts: boolean;
  languageId: string;
  selectedId: string;
  loadedMaterial?: MaterialModelWithTexts;
  formState: MaterialFormModel;
  onFileUploaded(imageId: string, file: File): void;
  setFormState: Dispatch<SetStateAction<MaterialFormModel>>;
  saveTextItems: typeof saveTextItemsAction;
  handleLanguageClick(languageId: string): void;
}

const EditMaterial: React.FC<Props> = (props) => {
  const { setFormState, formState, saveTextItems, match, loadedMaterial } = props;

  // Map name and description when languageId or material changes
  useEffect(() => {
    if (!match.params.languageId) {
      setFormState(initialFormState);
      return;
    }

    setFormState(state => ({
      ...state,
      name:
        nameTextSelector(loadedMaterial, match.params.languageId) ||
        '',
      description:
        descriptionTextSelector(
          loadedMaterial,
          match.params.languageId
        ) || '',
    }));
  }, [match.params.languageId, loadedMaterial, setFormState]);

  const onInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState(state => ({
        ...state,
        [name]: value,
      }));
    },
    [setFormState]
  );

  const onSaveMaterialTexts = useCallback(() => {
    if (!loadedMaterial) {
      throw new Error('Illegal state, expected loadedMaterial');
    }
    saveTextItems('material', props.match.params.languageId, [
      {
        id: loadedMaterial.nameTextItemId,
        text: formState.name,
      },
      {
        id: loadedMaterial.descriptionTextItemId,
        text: formState.description,
      },
    ]);
  }, [loadedMaterial, saveTextItems, props.match.params.languageId, formState.name, formState.description]);

  return (
    <div className="selected-material">
      <div className="card">
        <div className="two-part">
          {props.isLoadingMaterial ? (
            <Wait />
          ) : (
            <>
              <LanguageList
                path="content"
                selectedLanguageId={props.languageId}
                handleLanguageClick={props.handleLanguageClick}
              />
              <div className="flex-col">
                <Input
                  name="name"
                  label="Name"
                  value={formState.name}
                  onChange={onInputChange}
                />
                <TextArea
                  name="description"
                  label="Description"
                  width="wide"
                  value={formState.description}
                  onChange={onInputChange}
                />
              </div>
            </>
          )}
        </div>
        <div className="button-wrapper">
          {!props.isLoadingMaterial && props.selectedId && (
            <button
              className="large primary"
              onClick={onSaveMaterialTexts}
              disabled={props.isSavingMaterialTexts}
              type="button">
              Save
            </button>
          )}
        </div>
      </div>
      { props.selectedId && <div className="card flex flex-col">
        <div className="card-title">Picture upload</div>
        <div className="two-part">
          <div className="card image">
            <ImageArea onFileUploaded={props.onFileUploaded} imageUrl={props.loadedMaterial?.imageURL} imageSize={props.loadedMaterial?.imageSize} />
          </div>
          <div />
        </div>
      </div> }
    </div>
  );
};

export default withRouter(EditMaterial);
