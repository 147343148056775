import { FinishId } from 'domain/models/finish-type-model';
import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CALL_API } from 'app/middleware/api';
import * as actionConstants from 'reducers/action-constants';
import * as toastUtils from 'app/general/messages/toast-util';

export const createGetFinishesActions = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.FINISHES_REQ,
      actionConstants.FINISHES_REQ_SUCCESS,
      actionConstants.FINISHES_REQ_FAILURE,
    ],
    pathname: '/v2/surface-prep/finishes?languageCode=en',
  },
});

const createGetFinishActions = (finishId: string): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.FINISH_REQ,
      actionConstants.FINISH_REQ_SUCCESS,
      actionConstants.FINISH_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/finishes/${finishId}`,
  },
});

/**
 * Save finish image
 */
const createSaveFinishImageAction = (
  finishId?: FinishId,
  imageId?: string,
  filename?: string
): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.ATTACH_FINISH_IMAGE_REQ,
      actionConstants.ATTACH_FINISH_IMAGE_REQ_SUCCESS,
      actionConstants.ATTACH_FINISH_IMAGE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/finishes/${finishId}/images`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        imageId,
        filename,
      }),
    },
  },
});

/**
 * Edit finish
 */
const createSaveFinishAction = (
  finishId: FinishId,
  finishTypeId: string,
  languageCode: string,
  items: Array<{
    id: string;
    text: string;
  }>,
  uiOrder: string
): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.SAVE_FINISH_REQ,
      actionConstants.SAVE_FINISH_REQ_SUCCESS,
      actionConstants.SAVE_FINISH_REQ_FAILURE,
    ],
    pathname: `/v2/surface-prep/admin/finishes/${finishId}`,
    options: {
      method: 'PUT',
      body: JSON.stringify({
        finishTypeId,
        languageCode,
        items,
        uiOrder,
      }),
    },
  },
});

/**
 * Requests all finishes
 */
export const getFinishes = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => dispatch(createGetFinishesActions());
};

/**
 * Requests all finishes
 */
export const getFinish = (finishId: string) => (
  dispatch: (action: any) => Promise<any>
) => {
  return dispatch(createGetFinishActions(finishId));
};

/**
 * Save image to a finish
 * @param id
 * @param imageId
 * @param fileName
 */
export const saveFinishImage = (
  id: FinishId,
  imageId: string,
  fileName: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    const outcome = await dispatch(createSaveFinishImageAction(id, imageId, fileName));
    if (outcome.type === actionConstants.ATTACH_FINISH_IMAGE_REQ_SUCCESS) {
      toastUtils.success('Image was successfully replaced');
      dispatch(createGetFinishActions(id));
    }
  };
};

export const saveFinish = (
  finishId: FinishId,
  finishTypeId: string,
  languageCode: string,
  items: Array<{
    id: string;
    text: string;
  }>,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveFinishAction(finishId, finishTypeId, languageCode, items, uiOrder)
  ).then(resp => {
    if (resp.type === actionConstants.SAVE_FINISH_REQ_SUCCESS) {
      toastUtils.success('Finish successfully updated');
    }
    return dispatch(createGetFinishesActions());
  });
};

/**
 * Delete finish
 */
const createDeleteFinishAction = (finishId: FinishId) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_FINISH_REQ,
      actionConstants.DELETE_FINISH_REQ_SUCCESS,
      actionConstants.DELETE_FINISH_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/finishes/${finishId}`,
    options: {
      method: 'DELETE',
    },
    context: {
      finishId,
    },
  },
});

export const deleteFinish = (finishId: FinishId) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteFinishAction(finishId)).then(e => {
    if (e.type === actionConstants.DELETE_FINISH_REQ_SUCCESS) {
      toastUtils.success('Finish successfully deleted');
    }
    return dispatch(createGetFinishesActions());
  });
};

/**
 * Save new finish
 */
const createSaveNewFinishAction = (
  finishTypeId: string,
  name: string,
  subtitle: string,
  description: string,
  uiOrder: string
): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_FINISH_REQ,
      actionConstants.SAVE_NEW_FINISH_REQ_SUCCESS,
      actionConstants.SAVE_NEW_FINISH_REQ_FAILURE,
    ],
    pathname: '/v2/surface-prep/admin/finishes',
    options: {
      method: 'POST',
      body: JSON.stringify({
        finishTypeId,
        name,
        subtitle,
        description,
        uiOrder,
      }),
    },
  },
});

const createResetCreatedFinishId = (): Action => ({
  type: actionConstants.RESET_CREATED_FINISH_ID,
});

export const saveNewFinish = (
  finishTypeId: string,
  name: string,
  subtitle: string,
  description: string,
  uiOrder: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(
      createSaveNewFinishAction(finishTypeId, name, subtitle, description, uiOrder)
    ).then((resp: AnyAction) => {
      if (resp.type === actionConstants.SAVE_NEW_FINISH_REQ_SUCCESS) {
        toastUtils.success('New finish successfully saved');
      }
      dispatch(createResetCreatedFinishId());

      return dispatch(createGetFinishesActions());
    });
  };
};

/**
 * Get suggested finish uiOrder
 */
const createGetSuggestedFinishUIOrderAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ,
      actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ_SUCCESS,
      actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/finishes/ui-order',
    options: {
      method: 'GET',
    },
  },
});

export const getSuggestedFinishUIOrder = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetSuggestedFinishUIOrderAction());
  };
};

export const getSuggestedUIOrderOld = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetSuggestedFinishUIOrderAction());
};

/**
 * Get finish type
 */
const createGetFinishTypeActions = (finishTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.FINISH_TYPE_REQ,
      actionConstants.FINISH_TYPE_REQ_SUCCESS,
      actionConstants.FINISH_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/finish-types/${finishTypeId}?languageCode=en`,
    options: {
      method: 'GET',
    },
  },
});

export const getFinishType = (finishTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetFinishTypeActions(finishTypeId));
};

/**
 * Save new finish type
 */
const createSaveNewFinishTypeAction = (
  name: string,
  description: string,
  uiOrder: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_FINISH_TYPE_REQ,
      actionConstants.SAVE_NEW_FINISH_TYPE_REQ_SUCCESS,
      actionConstants.SAVE_NEW_FINISH_TYPE_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/finish-types',
    options: {
      method: 'POST',
      body: JSON.stringify({
        finishTypeName: name,
        description,
        uiOrder,
      }),
    },
  },
});

export const saveNewFinishType = (
  name: string,
  description: string,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveNewFinishTypeAction(name, description, uiOrder)
  ).then(result => {
    if (result.type === actionConstants.SAVE_NEW_FINISH_TYPE_REQ_SUCCESS) {
      toastUtils.success('New Finish Type successfully created');
    }

    return dispatch(createGetFinishesActions());
  });
};

/**
 * Delete finish type
 */
const createDeleteFinishTypeAction = (finishTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_FINISH_TYPE_REQ,
      actionConstants.DELETE_FINISH_TYPE_REQ_SUCCESS,
      actionConstants.DELETE_FINISH_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/finish-types/${finishTypeId}`,
    options: {
      method: 'DELETE',
    },
  },
});

export const deleteFinishType = (finishTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteFinishTypeAction(finishTypeId)).then(
    result => {
      if (result.type === actionConstants.DELETE_FINISH_TYPE_REQ_SUCCESS) {
        toastUtils.success('Finish Type successfully deleted');
      }

      return dispatch(createGetFinishesActions());
    }
  );
};

/**
 * Get suggested finish type uiOrder
 */
const createGetSuggestedFinishTypeUIOrderActions = () => ({
  [CALL_API]: {
    types: [
      actionConstants.GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ,
      actionConstants.GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_SUCCESS,
      actionConstants.GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/finish-types/ui-order',
    options: {
      method: 'GET',
    },
  },
});

export const getSuggestedFinishTypeUIOrder = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetSuggestedFinishTypeUIOrderActions());
};
