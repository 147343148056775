import { ApiMachineConfiguration,
  EditingModelConfigModel,
  MachineModelModel,
  MachineType,
} from 'domain/models/machine-type-model';

export const machineModelSelector = (
  id: string | undefined,
  machineTypes?: Array<MachineType>
): MachineModelModel | undefined => {
  if (!machineTypes) {
    return undefined;
  }
  const allMachines = machineTypes.reduce((a, m) => {
    return [...a, ...m.machineModels];
  }, [] as MachineModelModel[]);
  return allMachines.find(machineModel => machineModel.id === id);
};

export const getNewConfig = (
  activeConfig: Array<EditingModelConfigModel>,
  connectorTypeId: string,
  toolQuantity: string
) => {
  const hasConnectionType = activeConfig.find(
    config => config.connectorTypeId === connectorTypeId &&
      config.toolQuantity === toolQuantity
  );

  if (hasConnectionType) {
    return activeConfig.filter(
      config => config.toolQuantity !== toolQuantity ||
        config.connectorTypeId !== connectorTypeId
    );
  }
  return [
    ...activeConfig,
    {
      toolQuantity,
      connectorTypeId,
    },
  ];

};

export const mapToAPIConfig = (
  activeConfig: Array<EditingModelConfigModel>
): Array<ApiMachineConfiguration> => {
  return activeConfig.map(config => ({
    connectorType: config.connectorTypeId,
    toolQuantity: config.toolQuantity,
  }));
};
