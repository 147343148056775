import React from 'react';
import Body from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import Tool from 'domain/tools/container/tool';

const ToolPage = () => {
  return <Body
    topbar={<Menu />}
    content={<Tool />}
  />;
};

export default ToolPage;
