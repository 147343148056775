import React from 'react';
import './design-header.scss';

interface Props {
  readonly isDisabled: boolean
}
/**
 * Beware that is shares css props, defined by rd-row-cp
 * @param props {Props}
 */
const RecipeDesignHeader: React.FC<Props> = ({ isDisabled }: Props) => {
  return <div className={`design-header-comp ${isDisabled ? ' disabled' : ''}`}>
    <div>Step</div>
    <div>Type</div>
    <div>Tool Family</div>
    <div></div>
    <div>Active</div>
    <div>Default tool</div>
    <div>Item</div>
    <div title="Default config">Default config</div>
    <div>Connector</div>
    <div>Lifetime</div>
    <div>Action</div>
    <div></div>
  </div>;
};

export default RecipeDesignHeader;
