import React, { FC, useState, useCallback } from 'react';
import Modal from 'domain/recipe-designer/components/table/box/modal';

const ModalSample: FC = () => {
  const [showInfo, setShowInfo] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleInfoCancel = useCallback(() => setShowInfo(false), []);
  const handleConfirmCancel = useCallback(() => setShowConfirm(false), []);
  const handleConfirmOk = useCallback(() => setShowConfirm(false), []);

  return <div>
    <p>Sample info dialog</p>
    <button
      className="secondary"
      onClick={() => { setShowInfo(true); }}
      type="button">Launch info dialog</button>

    <p>...and a confirm dialog</p>
    <button
      className="primary"
      onClick={() => { setShowConfirm(true); }}
      type="button">Launch confirm dialog</button>
    <Modal
      show={showInfo}
      heading="Info dialog without ok button"
      onClose={handleInfoCancel}>
      <div>
        <p>Modal dialog content</p>
      </div>
    </Modal>
    <Modal
      show={showConfirm}
      heading="Confirm something"
      onClose={handleConfirmCancel}
      onOk={handleConfirmOk}>
      <div>
        <p>Are you really sure about the red pill?</p>
      </div>
    </Modal>
  </div>;
};

export default ModalSample;
