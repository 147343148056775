import { Reducer } from 'redux';
import { asSelectables } from 'app/form/utils';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import * as actionConstants from 'reducers/action-constants';
import { MachineTypeModelWithTexts } from 'domain/models/machine-type-model';

interface ContentMachinesStore {
  isLoadingMachineTypes: boolean;
  isSavingNewMachineType: boolean;
  selectables?: Array<UISelectableModel>;
  createdMachineType?: any;
  suggestedUIOrder?: number;
  isLoadingSuggestedUIOrder: boolean;
  isLoadingMachineType: boolean;
  loadedMachineType?: MachineTypeModelWithTexts;
  isSavingTextItems: boolean;
}

const getInitialState = () => ({
  isLoadingMachineTypes: false,
  isSavingNewMachineType: false,
  isLoadingMachineType: false,
  isSavingTextItems: false,
  isLoadingSuggestedUIOrder: false
});

const reducer: Reducer<ContentMachinesStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case actionConstants.MACHINE_TYPE_REQ:
      return {
        ...state,
        isLoadingMachineType: true,
        loadedMachineType: undefined,
      };

    case actionConstants.MACHINE_TYPE_REQ_SUCCESS:
      return {
        ...state,
        isLoadingMachineType: false,
        loadedMachineType: action.response,
      };

    case actionConstants.MACHINE_TYPE_REQ_FAILURE:
      return {
        ...state,
        isLoadingMachineType: false,
      };

    case actionConstants.MACHINE_TYPES_REQ:
      return {
        ...state,
        isLoadingMachineTypes: true,
      };

    case actionConstants.MACHINE_TYPES_REQ_SUCCESS:
      return {
        ...state,
        isLoadingMachineTypes: false,
        selectables: asSelectables(action.response, 'translatedName', 'id'),
      };

    case actionConstants.MACHINE_TYPES_REQ_FAILURE:
      return {
        ...state,
        isLoadingMachineTypes: false,
      };

    case actionConstants.SAVE_NEW_MACHINE_TYPE_REQ:
      return {
        ...state,
        isSavingNewMachineType: true,
      };

    case actionConstants.SAVE_NEW_MACHINE_TYPE_REQ_SUCCESS:
      return {
        ...state,
        isSavingNewMachineType: false,
        createdMachineType: action.response,
      };

    case actionConstants.SAVE_NEW_MACHINE_TYPE_REQ_FAILURE:
      return {
        ...state,
        isSavingNewMachineType: false,
      };
    default:
      return state;
  }
};

export default reducer;
