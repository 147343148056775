import {
  ERROR_RESET,
  SET_ERROR
} from './action-constants';

export default (state = '', action: any) => {
  const { type, error } = action;
  if (type === SET_ERROR) {
    return error;
  } if (type === ERROR_RESET) {
    return '';
  } if (error) {
    return error;
  }

  return state;
};
