import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FinishModelWithTexts, FinishTypeModel } from 'domain/models/finish-type-model';
import Wait from 'app/wait/wait';
import ImageArea from 'app/file-upload/image-area';
import {
  descriptionTextSelector,
  nameTextSelector,
  subtitleTextSelector,
} from 'domain/content/components/finishes/utils';
import { FinishFormModel } from 'domain/content/components/finishes/finish-form/finish-form-model';
import { initialFormState } from 'domain/content/components/finishes/finishes';
import LanguageList from 'domain/content/components/language-list/language-list';
import FinishForm from 'domain/content/components/finishes/finish-form/finish-form';
import List, { Header } from 'app/list/list';

const headers: Array<Header> = [
  {
    text: 'Finish Type',
    name: 'finidhTypeId',
  },
];
export interface Props
  extends RouteComponentProps<{ id: string; languageId: string }> {
  selectedId: string;
  selectedFinishTypeId: string;
  languageId: string;
  isLoadingFinish: boolean;
  isSavingFinish: boolean;
  loadedFinish?: FinishModelWithTexts;
  formState: FinishFormModel;
  setFormState: Dispatch<SetStateAction<FinishFormModel>>;
  handleLanguageClick(languageId: string): void;
  onFileUploaded(imageId: string, file: File): void;
  saveFinish: any;
  finishTypes?: Array<FinishTypeModel>
}

const isFormSubmittable = (formState: FinishFormModel) => formState.name && formState.subtitle && formState.uiOrder;

const EditFinish: React.FC<Props> = (props) => {

  const {
    setFormState,
    formState,
    match,
    loadedFinish,
    selectedId,
    saveFinish,
    isLoadingFinish,
    isSavingFinish,
    handleLanguageClick,
    onFileUploaded
  } = props;

  // Map name and description when languageId or material changes
  useEffect(() => {
    if (!match.params.languageId) {
      setFormState(initialFormState);
      return;
    }

    setFormState(state => ({
      ...state,
      name:
        nameTextSelector(loadedFinish, match.params.languageId) ||
        '',
      subtitle:
        subtitleTextSelector(
          loadedFinish,
          match.params.languageId
        ) || '',
      description:
        descriptionTextSelector(
          loadedFinish,
          match.params.languageId
        ) || '',
      uiOrder: loadedFinish?.uiOrder || '99',
      finishTypeId: props.selectedFinishTypeId
    }));
  }, [match.params.languageId, loadedFinish, setFormState]);

  const onSaveFinish = useCallback(() => {
    saveFinish(
      selectedId,
      formState.finishTypeId,
      match.params.languageId,
      [
        {
          id: loadedFinish?.nameTextItemId,
          text: formState.name,
        },
        {
          id: loadedFinish?.subtitleTextItemId,
          text: formState.subtitle,
        },
        {
          id: loadedFinish?.descriptionTextItemId,
          text: formState.description,
        },
      ],
      formState.uiOrder
    );
  }, [
    saveFinish,
    match.params.languageId,
    loadedFinish,
    formState,
    selectedId,
  ]);

  const onRowClick = useCallback(
    (id: string) => {
      setFormState(state => ({
        ...state,
        finishTypeId: id,
      }));
    },
    [setFormState]
  );

  const selectableValues = {
    finishTypeId: props.finishTypes?.map(finishType => ({
      id: finishType.id,
      name: finishType.name,
    })),
  };

  return (
    <div className="selected-finish flex flex-col">
      <div className="card">
        <div className="two-part">
          {isLoadingFinish ? (
            <Wait />
          ) : (
            <>
              <LanguageList
                path="finish"
                selectedLanguageId={match.params.languageId}
                handleLanguageClick={handleLanguageClick}
              />
              <FinishForm
                formState={formState}
                setFormState={setFormState}
                isNew={false}
              />
            </>
          )}
        </div>
      </div>
      { selectedId && <div className="card">
        <div className="two-part">
          <List
            path="content"
            selectedType="finishTypeId"
            selectedId={props.formState.finishTypeId}
            headers={headers}
            lists={selectableValues}
            onRowClick={onRowClick}
            isItemsDeletable={false}
            listItemsUppercase
          />
          <div className="card image">
            <div className="card-title">Picture upload</div>
            <ImageArea onFileUploaded={onFileUploaded} imageUrl={loadedFinish?.imageURL} imageSize={loadedFinish?.imageSize} />
          </div>
        </div>
        <div className="button-wrapper">
          {!isLoadingFinish && selectedId && (
            <button
              className="large primary"
              onClick={onSaveFinish}
              disabled={isSavingFinish || !isFormSubmittable(formState)}
              type="button">
              Save
            </button>
          )}
        </div>
      </div> }
    </div>
  );
};

export default withRouter(EditFinish);
