import { Reducer } from 'redux';
import {
  GET_TOOLFAMILY_REQ,
  GET_TOOLFAMILY_REQ_SUCCESS,
  GET_TOOLFAMILY_REQ_FAILURE,
  UPDATE_TOOLFAMILY_REQ,
  UPDATE_TOOLFAMILY_REQ_FAILURE,
  UPDATE_TOOLFAMILY_REQ_SUCCESS,
  CREATE_TOOLFAMILY_REQ,
  CREATE_TOOLFAMILY_REQ_FAILURE,
  CREATE_TOOLFAMILY_REQ_SUCCESS,
  DELETE_TOOLFAMILY_REQ
} from 'reducers/action-constants';
import { ToolFamilyModel } from 'domain/models/tool-family';

export interface ToolFamilyStore {
  isFetching: boolean,
  isSaving: boolean,
  isCreated?: boolean,
  item?: ToolFamilyModel
}

const reducer: Reducer<ToolFamilyStore> = (state = { isFetching: false, isSaving: false }, action: any) => {
  switch (action.type) {
    case GET_TOOLFAMILY_REQ:
      return {
        ...state,
        item: undefined,
        isFetching: true
      };
    case GET_TOOLFAMILY_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: {
          ...action.response
        },
      };
    case GET_TOOLFAMILY_REQ_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_TOOLFAMILY_REQ:
    case CREATE_TOOLFAMILY_REQ:
      return {
        ...state,
        isSaving: true
      };
    case UPDATE_TOOLFAMILY_REQ_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };
    case CREATE_TOOLFAMILY_REQ_SUCCESS:
      return {
        ...state,
        item: {
          ...action.response
        },
        isCreated: true,
        isSaving: false,
      };
    case UPDATE_TOOLFAMILY_REQ_FAILURE:
    case CREATE_TOOLFAMILY_REQ_FAILURE:
      return {
        ...state,
        isSaving: false
      };
    case DELETE_TOOLFAMILY_REQ:
      return {
        ...state,
        item: action.context === state.item?.id ? undefined : state.item
      };
    default:
      return state;
  }
};

export default reducer;
