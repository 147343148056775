import { Reducer } from 'redux';
import * as actionConstants from 'reducers/action-constants';

interface ContentToolSystemsStore {
  isFetching: boolean;
  isSavingNewToolSystem: boolean;
  isUpdatingToolSystem: boolean;
  suggestedUIOrder?: string;
  isLoadingSuggestedUIOrder: boolean;
}

const getInitialState = () => ({
  isFetching: false,
  isSavingNewToolSystem: false,
  isUpdatingToolSystem: false,
  isLoadingSuggestedUIOrder: false
});

const reducer: Reducer<ContentToolSystemsStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {

    case actionConstants.SAVE_NEW_TOOLSYSTEM_REQ:
      return {
        ...state,
        isSavingNewToolSystem: true,
      };

    case actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS:
      return {
        ...state,
        isSavingNewToolSystem: false,
        createdToolSystem: action?.response,
      };

    case actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_FAILURE:
      return {
        ...state,
        isSavingNewToolSystem: false,
      };

    case actionConstants.RESET_CREATED_TOOLSYSTEM:
      return {
        ...state,
        createdToolSystem: undefined
      };

    case actionConstants.UPDATE_TOOLSYSTEM_REQ:
      return {
        ...state,
        isUpdatingToolSystem: true,
      };

    case actionConstants.UPDATE_TOOLSYSTEM_REQ_SUCCESS:
      return {
        ...state,
        isUpdatingToolSystem: false
      };

    case actionConstants.UPDATE_TOOLSYSTEM_REQ_FAILURE:
      return {
        ...state,
        isUpdatingToolSystem: false,
      };

    case actionConstants.RESET_SUGGESTED_UI_ORDER:
      return {
        ...state,
        suggestedUIOrder: undefined
      };

    default:
      return state;
  }
};

export default reducer;
