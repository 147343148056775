type Subscriber = (payload: any) => void;

class PubSub {
  subscribers: Array<Subscriber>;

  constructor () {
    this.subscribers = [];
  }

  subscribe (callback: Subscriber) {
    this.subscribers.push(callback);
  }

  unsubscribe (callback: Subscriber) {
    this.subscribers = this.subscribers.filter(subscriber => subscriber !== callback);
  }

  notifyAll (payload: any) {
    this.subscribers.forEach((subscriptionCallback) => {
      subscriptionCallback.apply(this, [payload]);
    });
  }
}

export default PubSub;
