import {
  GET_RECIPE_REQ, GET_RECIPE_REQ_SUCCESS, GET_RECIPE_REQ_FAILURE,
  DELETE_RECIPE_REQ, DELETE_RECIPE_REQ_SUCCESS, DELETE_RECIPE_REQ_FAILURE,
  COPY_MACHINE_CONF_REQ, COPY_MACHINE_CONF_REQ_SUCCESS, COPY_MACHINE_CONF_REQ_FAILURE,
  UPDATE_RECIPE_STEPS_REQ, UPDATE_RECIPE_STEPS_REQ_SUCCESS, UPDATE_RECIPE_STEPS_REQ_FAILURE,
  CREATE_RECIPE_REQ, CREATE_RECIPE_REQ_SUCCESS, CREATE_RECIPE_REQ_FAILURE
} from 'reducers/action-constants';
import { RecipeModel, RecipeId } from 'domain/models/recipe-model';
import { ToolFamilyId } from 'domain/recipe-designer/container/recipe-config-reducer';
import { MachineModelId } from 'domain/models/machine-type-model';
import { ToolSelectionId } from 'domain/models/tool-selection-model';

export interface RecipeStore {
  item?: RecipeModel
  isFetching: boolean
  isFetched: boolean
  isDeleted?: boolean
  isCopied?: boolean
  isCreated?: boolean
  copyResults?: RecipeCopyResults
  ongoing?: any
  ongoingSteps?: any
}

export interface RecipeCopyResults {
  status: string
  rows:number
  results: Array<{
    recipeId: RecipeId
    toolFamilyId: ToolFamilyId
    machineModelId: MachineModelId
    configurationId: string,
    toolSelectionId: ToolSelectionId
    area: string
  }>
}

const getInitialState = ():RecipeStore => {
  return {
    isFetched: false,
    isFetching: false,
    ongoing: undefined
  };
};

const overwriteRecipeSteps = (recipe: RecipeModel, steps: Array<string>) => {
  return {
    ...recipe,
    steps
  };
};

export default (state: any = getInitialState(), action: any): RecipeStore => {
  switch (action.type) {
    case GET_RECIPE_REQ:
      return {
        ...state,
        item: undefined,
        isFetching: true,
        isCreated: false
      };
    case GET_RECIPE_REQ_SUCCESS:
      return {
        isFetching: false,
        isFetched: true,
        item: action.response[0]
      };
    case GET_RECIPE_REQ_FAILURE:
      return getInitialState();
    case UPDATE_RECIPE_STEPS_REQ_FAILURE:
      return {
        ...state,
        ongoing: undefined,
        isFetching: false
      };

    case UPDATE_RECIPE_STEPS_REQ:
      return {
        ...state,
        item: overwriteRecipeSteps(state.item, action.context),
        ongoingSteps: action.context,
        isFetching: true
      };
    case UPDATE_RECIPE_STEPS_REQ_SUCCESS:
      return {
        ...state,
        ongoing: undefined,
        item: { ...state.item, recipeToolFamilies: state.ongoingSteps },
        isFetching: true
      };
    case CREATE_RECIPE_REQ:
      return {
        isFetching: true,
        isFetched: false,
        isCreated: false
      };
    case CREATE_RECIPE_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isCreated: true,
        item: action.response
      };
    case CREATE_RECIPE_REQ_FAILURE:
      return {
        isFetching: false,
        isFetched: false,
        isCreated: false
      };
    case DELETE_RECIPE_REQ:
      return {
        isFetching: false,
        isFetched: false,
        isDeleted: false
      };
    case DELETE_RECIPE_REQ_SUCCESS:
      return {
        isFetching: false,
        isDeleted: true,
        isFetched: false
      };
    case DELETE_RECIPE_REQ_FAILURE:
      return {
        isFetching: false,
        isFetched: false
      };
    case COPY_MACHINE_CONF_REQ:
      return {
        ...state,
        isCopied: false,
        isCopying: true,
        copyResults: undefined
      };
    case COPY_MACHINE_CONF_REQ_SUCCESS:
      return {
        ...state,
        isCopying: false,
        isCopied: false,
        copyResults: action.response
      };
    case COPY_MACHINE_CONF_REQ_FAILURE:
      return {
        ...state,
        isCopying: false,
        isCopied: false,
        copyResults: undefined
      };

    default:
      return state;
  }
};
