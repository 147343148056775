import React, { useState } from 'react';
import SearchDimr from 'domain/tool-families/components/view/editor/search-dimr';

type Props = {
  handleAddTool: (articleNo: string, articleName: string) => void,
  handleSearchTool: (articleNumber: string) => void,
  isSearching: boolean,
  isFetchingDimrTool: boolean,
  toolSearchList: Array<any>
};

const ToolSelectionAdd: React.FC<Props> = (props) => {
  const [articleNumber, setArticleNumber] = useState('');

  const handleArticleNumberInputChange = (value: string) => {
    setArticleNumber(value);
    props.handleSearchTool(value);
  };

  const handleDimrClicked = (articleNo: string, articleName: string) => {
    props.handleAddTool(articleNo, articleName);
    setArticleNumber('');
  };

  return <div className="list-footer">
    <div className="title">
      Add article
    </div>
    <SearchDimr
      isSearching={props.isSearching}
      pnc={articleNumber}
      onPncChanged={handleArticleNumberInputChange}
      onDimrClicked={handleDimrClicked}
      onDimrClickedText={props.isFetchingDimrTool ? 'Adding tool...' : undefined}
      dimrSearchList={props.toolSearchList}
      />
  </div>;
};

export default ToolSelectionAdd;
