import { CALL_API } from 'app/middleware/api';

import {
  GET_ALL_TOOLFAMILY_REQ,
  GET_ALL_TOOLFAMILY_REQ_SUCCESS,
  GET_ALL_TOOLFAMILY_REQ_FAILURE,
  GET_TOOLFAMILY_REQ,
  GET_TOOLFAMILY_REQ_SUCCESS,
  GET_TOOLFAMILY_REQ_FAILURE,
  SELECT_TOOLFAMILY,
  SEARCH_TOOL_REQ,
  SEARCH_TOOL_REQ_SUCCESS,
  SEARCH_TOOL_REQ_FAILURE,
  CLEAR_SEARCH_TOOL_RES,
  CREATE_TOOLFAMILY_REQ,
  CREATE_TOOLFAMILY_REQ_SUCCESS,
  CREATE_TOOLFAMILY_REQ_FAILURE,
  UPDATE_TOOLFAMILY_REQ,
  UPDATE_TOOLFAMILY_REQ_SUCCESS,
  UPDATE_TOOLFAMILY_REQ_FAILURE,
  LOOKUP_DIMR_TOOL_REQ,
  LOOKUP_DIMR_TOOL_REQ_SUCCESS,
  LOOKUP_DIMR_TOOL_REQ_FAILURE, DELETE_TOOLFAMILY_REQ, DELETE_TOOLFAMILY_REQ_SUCCESS, DELETE_TOOLFAMILY_REQ_FAILURE
} from 'reducers/action-constants';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { success } from 'app/general/messages/toast-util';

const createGetAllToolFamiliesAction = () => ({
  [CALL_API]: {
    types: [GET_ALL_TOOLFAMILY_REQ, GET_ALL_TOOLFAMILY_REQ_SUCCESS, GET_ALL_TOOLFAMILY_REQ_FAILURE],
    pathname: '/v1/surface-prep/admin/tool-families'
  }
});

const createGetToolFamilyAction = (id: string) => ({
  [CALL_API]: {
    types: [GET_TOOLFAMILY_REQ, GET_TOOLFAMILY_REQ_SUCCESS, GET_TOOLFAMILY_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/tool-families/${id}`
  }
});

const createDeleteToolFamily = (toolFamilyId: string) => ({
  [CALL_API]: {
    types: [DELETE_TOOLFAMILY_REQ, DELETE_TOOLFAMILY_REQ_SUCCESS, DELETE_TOOLFAMILY_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/tool-families/${toolFamilyId}`,
    context: toolFamilyId,
    options: {
      method: 'DELETE'
    }
  }
});

const createAddToolAction = (articleNumber: string, articleName: string, route: string) => ({
  [CALL_API]: {
    types: [LOOKUP_DIMR_TOOL_REQ, LOOKUP_DIMR_TOOL_REQ_SUCCESS, LOOKUP_DIMR_TOOL_REQ_FAILURE],
    pathname: `/v1/surface-prep/${route}/${articleNumber}`,
    context: {
      articleNumber, articleName
    },
    options: {
      method: 'POST',
      body: '{}'
    }
  }
});

const createSearchToolAction = (articleNumber: string) => ({
  [CALL_API]: {
    types: [SEARCH_TOOL_REQ, SEARCH_TOOL_REQ_SUCCESS, SEARCH_TOOL_REQ_FAILURE],
    pathname: `/v1/surface-prep/tools?pnc=${articleNumber}`,
    context: articleNumber
  }
});

const createUpdateToolFamilyAction = (toolFamilyId: string, toolFamily: any) => ({
  [CALL_API]: {
    types: [UPDATE_TOOLFAMILY_REQ, UPDATE_TOOLFAMILY_REQ_SUCCESS, UPDATE_TOOLFAMILY_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/tool-families/${toolFamilyId}`,
    context: {
      toolFamilyId,
      toolFamily
    },
    options: {
      method: 'PUT',
      body: JSON.stringify(toolFamily)
    }
  }
});

const createCreateNewToolFamilyAction = (toolFamily: any) => ({
  [CALL_API]: {
    types: [CREATE_TOOLFAMILY_REQ, CREATE_TOOLFAMILY_REQ_SUCCESS, CREATE_TOOLFAMILY_REQ_FAILURE],
    pathname: '/v1/surface-prep/admin/tool-families',
    context: toolFamily,
    options: {
      method: 'POST',
      body: JSON.stringify(toolFamily)
    }
  }
});

/**
 * Get all tool families
 */
export const getAllToolFamilies = () => (dispatch: (action: any) => void) => {
  return dispatch(createGetAllToolFamiliesAction());
};

/**
 * Get specific tool family.
 * @param id
 */
export const getToolFamily = (id: string) => (dispatch: (action: any) => void) => {
  return dispatch(createGetToolFamilyAction(id));
};

/**
 * Select a tool family in list.
 * @param id
 */
export const selectToolFamily = (id: string | undefined) => (dispatch: (action: any) => void) => {
  return dispatch({
    type: SELECT_TOOLFAMILY,
    toolFamilyId: id
  });
};

/**
 * Clear tool search result.
 */
export const clearSearchToolResult = () => (dispatch: (action: any) => void) => {
  return dispatch({
    type: CLEAR_SEARCH_TOOL_RES
  });
};

/**
 * Look up a DIMR tool.
 * @param articleNumber
 * @param articleName
 * @param route
 */
export const addTool = (articleNumber: string, articleName: string, route: string) => (dispatch: Function) => {
  return dispatch(createAddToolAction(articleNumber, articleName, route))
    .then((outcome: any) => {
      if (outcome.type === LOOKUP_DIMR_TOOL_REQ_SUCCESS) {
        dispatch(clearSearchToolResult());
      }
    });
};

/**
 * Get tool name from article number.
 * @param articleNumber
 */
export const searchTool = (articleNumber: string) => (dispatch: (action: any) => void) => {
  if (articleNumber === '') {
    return dispatch(clearSearchToolResult());
  }
  return dispatch(createSearchToolAction(articleNumber));
};

/**
 * Update tool family.
 */
export const updateToolFamily = (toolFamilyId: string, toolFamily: ToolFamilyModel) => (dispatch: Function) => {
  return dispatch(createUpdateToolFamilyAction(toolFamilyId, toolFamily))
    .then((outcome: any) => {
      if (outcome.type === UPDATE_TOOLFAMILY_REQ_SUCCESS) {
        success('Tool family saved');
        return dispatch(getAllToolFamilies());
      }
      return outcome;
    });
};

/**
 * Create a new tool family.
 * @param toolFamily
 */
export const createNewToolFamily = (toolFamily: ToolFamilyModel) => (dispatch: Function) => {
  return dispatch(createCreateNewToolFamilyAction(toolFamily))
    .then(async (outcome: any) => {
      const results = { ...outcome };
      if (outcome.type === CREATE_TOOLFAMILY_REQ_SUCCESS) {
        success('Tool family created successfully');
        results.isSuccess = true;
        await dispatch(getAllToolFamilies());
      }
      return results;
    });
};

/**
 * Delete a tool family.
 * @param toolFamilyId
 */
export const deleteToolFamily = (toolFamilyId: string) => (dispatch: Function) => {
  return dispatch(createDeleteToolFamily(toolFamilyId))
    .then((outcome: any) => {
      if (outcome.type === DELETE_TOOLFAMILY_REQ_SUCCESS) {
        success('Tool family was successfully removed');
        return dispatch(getAllToolFamilies());
      }
      return outcome;
    });
};
