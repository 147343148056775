import React, { MouseEvent } from 'react';
import Box from './box';
import './modal.scss';

interface Props {
  heading: string
  show: boolean
  onClose: (e: MouseEvent) => void
  onOk?: (e: MouseEvent) => void
  children: any
  hideOkButton?: boolean
  className?: string
}

const Modal: React.FC<Props> = ({
  heading,
  show,
  onClose,
  onOk,
  children,
  className,
  hideOkButton = false
}: Props) => {
  const clazz = `modal-c ${className}`;
  return <Box
    show={show}
    onClose={onClose}
    className={clazz}>
    <header>
      <h1>{heading}</h1>
      <span onClick={onClose} className="up-close" />
    </header>
    <section>
      {children}
    </section>
    <footer>
      { !hideOkButton && <button className="primary" onClick={(e) => !!onOk && onOk(e)} type="button">OK</button> }
      <button className="secondary" onClick={onClose} type="button">Cancel</button>
    </footer>
  </Box>;
};
export default Modal;
