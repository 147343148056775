import {
  USERS_REQ, USERS_REQ_SUCCESS, USERS_REQ_FAILURE,
  USER_DETAILS_REQ, USER_DETAILS_REQ_SUCCESS, USER_DETAILS_REQ_FAILURE,
  UPDATE_USER_REQ, UPDATE_USER_REQ_SUCCESS, UPDATE_USER_REQ_FAILURE
} from 'reducers/action-constants';
import { AnyAction } from 'redux';
import { UserApiModel } from 'domain/users/container/user-models';

export interface DetailedUserApiModel extends UserApiModel {
  email?: string
  sessions: Array<{id: string, client: string, createdAt: string}>
}

export interface UsersStore {
  items?: Array<UserApiModel>
  detailedItem?: DetailedUserApiModel
  isFetching: boolean
}

export interface GetUsersAction extends AnyAction {
  type: typeof USERS_REQ
}

export interface GetUsersSuccessAction extends AnyAction {
  type: typeof USERS_REQ_SUCCESS
  response: Array<UserApiModel>
}
export interface GetUsersSuccessFailure extends AnyAction {
  type: typeof USERS_REQ_FAILURE
}

export interface GetUserDetailsAction extends AnyAction {
  type: typeof USER_DETAILS_REQ
}

export interface GetUserDetailsSuccessAction extends AnyAction {
  type: typeof USER_DETAILS_REQ_SUCCESS
  response: DetailedUserApiModel
}
export interface GetUserDetailsFailureAction extends AnyAction {
  type: typeof USER_DETAILS_REQ_FAILURE
}

export interface UpdateUserDetailsAction extends AnyAction {
  type: typeof UPDATE_USER_REQ
}
export interface UpdateUserDetailsSuccessAction extends AnyAction {
  type: typeof UPDATE_USER_REQ_SUCCESS
  payload: any
}
export interface UpdateUserDetailsFailureAction extends AnyAction {
  type: typeof UPDATE_USER_REQ_FAILURE
}

export type UserDetailActionTypes = GetUserDetailsAction | GetUserDetailsSuccessAction | GetUserDetailsFailureAction;
export type GetUsersActionTypes = GetUsersAction | GetUsersSuccessAction | GetUsersSuccessFailure;
export type UpdateUsersActionTypes = UpdateUserDetailsAction | UpdateUserDetailsSuccessAction | UpdateUserDetailsFailureAction;

export type AdminUserActionTypes = UserDetailActionTypes | GetUsersActionTypes | UpdateUsersActionTypes;

const getInitialState = () => ({
  isFetching: false
});

export default (state = getInitialState(), action: AdminUserActionTypes): UsersStore => {
  switch (action.type) {
    case USERS_REQ:
      return {
        isFetching: true
      };
    case USERS_REQ_SUCCESS:
      return {
        isFetching: false,
        items: action.response
      };
    case USERS_REQ_FAILURE:
      return {
        isFetching: false
      };
    case USER_DETAILS_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailedItem: action.response
      };
    case USER_DETAILS_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        detailedItem: undefined
      };
    case UPDATE_USER_REQ_SUCCESS:
    case UPDATE_USER_REQ:
    case UPDATE_USER_REQ_FAILURE:
    case USER_DETAILS_REQ:
    default:
      return state;
  }
};
