import {
  RECIPES_REQ, RECIPES_REQ_SUCCESS, RECIPES_REQ_FAILURE
} from 'reducers/action-constants';

const getInitialState = () => {
  return {
    isFetched: false,
    isFetching: false
  };
};

export default (state = getInitialState(), action: any) => {
  switch (action.type) {
    case RECIPES_REQ:
      return {
        ...state,
        isFetching: true
      };
    case RECIPES_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.response
      };
    case RECIPES_REQ_FAILURE:
      return {
        ...state,
        ...getInitialState()
      };
    default:
      return state;
  }
};
