import * as React from 'react';
import './select.scss';

interface Props {
  onChange?: (event: any) => void
  onValueChanged?: (event: any) => void
  name: string
  white?: boolean
  tabIndex?: number
  value?: number | string
  disabled?: boolean
  blankOption?: boolean
  blankLabel?: string
  selectables: Array<{value: string, label: string}>
  styleOnWhite?: boolean
}

interface State {
  value?: any
}

class Select extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props);
    this.state = {
      value: props.value
    };
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps (nextProps: Props) {
    if (nextProps.value || nextProps.value === '' || nextProps.value === null) {
      this.setState({ value: nextProps.value });
    }
  }

  onChange (event: any) {
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  render () {
    const disabled = this.props.disabled === true;
    const classes = ['is'];
    if (disabled) {
      classes.push('disabled');
    }
    if (this.props.white) {
      classes.push('white');
    }
    if (this.props.styleOnWhite) {
      classes.push('on-white');
    }
    return (
      <div className={classes.join(' ')}>
        <select
          value={this.state.value}
          onChange={this.onChange}
          name={this.props.name}
          tabIndex={this.props.tabIndex}
          disabled={disabled}>
          { this.props.blankOption ? <option value="" key={-1}>{this.props.blankLabel}</option> : '' }
          { this.props.selectables.map((item) => {
            return <option value={item.value} key={item.value}>
              { item.label }
            </option>;
          })}
        </select>
      </div>
    );
  }
}

export default Select;
