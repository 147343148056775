import React, { FC, useCallback } from 'react';
import Modal from 'domain/recipe-designer/components/table/box/modal';
import SelectOne from 'domain/recipe-picker/components/select-one/select-one';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { ToolFamilyId } from 'domain/recipe-designer/container/recipe-config-reducer';
import { MarketId } from 'domain/markets/container/markets-reducer';

interface AddStepDialogProps {
  show: boolean
  filterByMarketId?: MarketId
  readonly toolFamiliesById: Map<string, ToolFamilyModel>
  onToolFamilyAdded: (newToolFamily: ToolFamilyModel) => void
  onCancel: () => void
}

const AddStepDialog: FC<AddStepDialogProps> = ({
  show,
  filterByMarketId,
  toolFamiliesById,
  onToolFamilyAdded,
  onCancel
}) => {
  let tfList: Array<ToolFamilyModel> = [];
  if (show) {
    tfList = Array.from(toolFamiliesById.values())
      .filter(tf => tf.detailedItem?.markets && tf.detailedItem?.markets.some(m => m === filterByMarketId));
    tfList.sort((tf1, tf2) => {
      if (!tf1.name) {
        if (!tf2.name) {
          return 0;
        }
        return -1;
      }
      if (!tf2.name) {
        return 1;
      }
      return tf1.name.localeCompare(tf2.name);
    });
  }

  const selectTf = useCallback((toolFamilyId: ToolFamilyId) => {
    const tf = toolFamiliesById.get(toolFamilyId);
    if (!tf) {
      throw new Error(`Expected tool family ${toolFamilyId} to be in map`);
    }
    onToolFamilyAdded(tf);
  }, [onToolFamilyAdded, toolFamiliesById]);

  const handleCancel = useCallback(() => onCancel(), [onCancel]);

  return <Modal
    show={show}
    heading="Add Step"
    onClose={handleCancel}
    hideOkButton
    onOk={() => {}}>
    <div>
      <SelectOne
        title="Select tool family"
        propName="newTf"
        onSelected={(name, item) => { selectTf(item.value); }}
        items={tfList}
        itemsListLabelPropName="name"
        itemsListValuePropName="id"
          />
    </div>
  </Modal>;
};

export default AddStepDialog;
