import * as React from 'react';
import BodyWithTopMenu from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import Wait from 'app/wait/wait';

const WaitPage: React.FC = () => {
  return <BodyWithTopMenu
    topbar={<Menu />}
    content={<Wait />}
    />;
};

export default WaitPage;
