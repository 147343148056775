export default (func: Function, wait: number, immediate?: boolean) => {
  let timeout: any;

  return (...args: any[]) => {
    // @ts-ignore
    const context = this;

    const later = function doit () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
