import * as React from 'react';
import { ToolSearchModel } from 'domain/models/tool-search-model';
import './search-results-list.scss';

interface Props {
  list?: Array<ToolSearchModel>
  onSelect: Function
}

const SearchResultsList: React.FC<Props> = props => {
  return (
    <div className="search-result-list">
      <ul>
        { props.list?.map((item) => {
          return <li key={item.id}><a role="button" onClick={() => props.onSelect(item.productNumber, item.name)}>{item.name}</a></li>;
        })}
      </ul>
    </div>
  );
};

export default SearchResultsList;
