import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import Input from 'app/form/labeled-input/input';
import TextArea from 'app/form/labeled-textarea/textarea';
import { FinishFormModel } from 'domain/content/components/finishes/finish-form/finish-form-model';
import './finish-form.scss';

export interface Props {
  formState: FinishFormModel;
  setFormState: Dispatch<SetStateAction<FinishFormModel>>;
  isNew: boolean
}

const FinishForm: React.FC<Props> = ({ formState, setFormState, isNew }) => {
  const onInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState(state => ({
        ...state,
        [name]: value,
      }));
    },
    [setFormState]
  );

  return (
    <div className="finish-form flex-col">
      <div className="name-and-subtitle">
        <Input
          name="name"
          label="Name"
          width="none"
          value={formState.name}
          onChange={onInputChange}
        />
        <Input
          name="subtitle"
          label="Sub-title"
          width="none"
          value={formState.subtitle}
          onChange={onInputChange}
        />
      </div>
      <div>
        <TextArea
          name="description"
          label="Description"
          width="none"
          value={formState.description || ''}
          onChange={onInputChange}
        />
      </div>
      {isNew &&
        <div className="ui-order">
          <Input
            name="uiOrder"
            onChange={onInputChange}
            value={formState.uiOrder || ''}
            label="Sort order"
            type="number"
            minValue={0}
          />
        </div>}
    </div>
  );
};

export default FinishForm;
