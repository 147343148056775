import React, { PureComponent } from 'react';
import LandingPage from 'domain/dashboard/components/page';

class Dashboard extends PureComponent {
  render () {
    return <LandingPage />;
  }
}

export default Dashboard;
