import React, { MouseEvent } from 'react';
import './box.scss';

const preventShutdown = (e: any) => e.stopPropagation();

interface Props {
  show: boolean
  onClose: (e: MouseEvent) => any
  children: any
  className?: string
}

const Box: React.FC<Props> = ({
  show,
  onClose,
  children,
  className
}: Props) => (<div className={`modal-box-c${show ? ' visible' : ''} ${className}`} onClick={onClose}>
  <div onClick={preventShutdown}>
    { children }
  </div>
</div>);

export default Box;
