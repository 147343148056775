import { Reducer } from 'redux';
import * as actions from 'reducers/action-constants';

interface StatisticsStore {
  ipsFetched: boolean;
  ips: { ipSource: string, id: string }[];
}

const getInitialState = () => ({
  ipsFetched: false,
  ips: []
});

const reducer: Reducer<StatisticsStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case actions.STAT_IP_REQ:
      return getInitialState();
    case actions.STAT_IP_REQ_SUCCESS:
      return {
        ipsFetched: true,
        ips: action.response
      };

    default:
      return state;
  }
};

export default reducer;
