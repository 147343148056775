import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './actions';

interface LogoutProps {
  config: any
  dispatchLogout: () => void
}
class Logout extends Component<LogoutProps> {

  constructor (props: any) {
    super(props);
    this.props.dispatchLogout();
  }

  render () {
    return <Redirect to={{
      pathname: '/login'
    }} />;
  }

}

// unsure about the code being send, it is not assigned anywhere in this class.
const mapDispatchToProps = (dispatch: any) => ({
  dispatchLogout: () => dispatch(logout())
});

const mapStateToProps = (state: any) => {
  const { login, config } = state;

  return {
    isAuthenticated: login.isAuthenticated,
    config: config.item
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
