import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Input from 'app/form/labeled-input/input';
import TextArea from 'app/form/labeled-textarea/textarea';
import Wait from 'app/wait/wait';
import { TypeModelWithTexts } from 'domain/models/type-model';
import { TypeFormModel } from 'domain/content/components/any-types/type-form-model';
import LanguageList from '../../language-list/language-list';
import {
  descriptionTextSelector,
  nameTextSelector,
} from '../utils';
import { initialFormState } from '../type-form-model';

interface Props extends RouteComponentProps<{ languageId: string }> {
  id: string;
  isLoadingType: boolean;
  isSavingTextItems: boolean;
  languageId: string;
  selectedId: string;
  loadedType: TypeModelWithTexts;
  formState: TypeFormModel;
  setFormState: Dispatch<SetStateAction<TypeFormModel>>;
  saveTextItems: Function
  handleLanguageClick(languageId: string): void;
}

const EditType: React.FC<Props> = (props) => {
  const { setFormState, formState, loadedType, match, saveTextItems, id } = props;

  // Map name and description when languageId or finish changes
  useEffect(() => {
    if (!match.params.languageId) {
      setFormState(initialFormState);
      return;
    }

    setFormState(state => ({
      ...state,
      name:
        nameTextSelector(loadedType, match.params.languageId) ||
        '',
      description:
        descriptionTextSelector(
          loadedType,
          match.params.languageId
        ) || '',
    }));
  }, [match.params.languageId, loadedType, setFormState]);

  const onInputChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState(state => ({
        ...state,
        [name]: value,
      }));
    },
    [setFormState]
  );

  const onSaveTexts = useCallback(() => {
    saveTextItems(id, match.params.languageId, [
      {
        id: loadedType.nameTextItemId,
        text: formState.name,
      },
      {
        id: loadedType.descriptionTextItemId,
        text: formState.description,
      },
    ]);
  }, [
    saveTextItems,
    match.params.languageId,
    loadedType,
    formState,
  ]);

  return (
    <div className="selected-type card flex flex-col">
      <div className="two-part">
        {props.isLoadingType ? (
          <Wait />
        ) : (
          <>
            <LanguageList
              path="content"
              selectedLanguageId={props.languageId}
              handleLanguageClick={props.handleLanguageClick}
            />
            <div className="flex-col">
              <Input
                name="name"
                label="Name"
                value={formState.name}
                onChange={onInputChange}
              />
              <TextArea
                name="description"
                label="Description"
                width="wide"
                value={formState.description}
                onChange={onInputChange}
              />
            </div>
          </>
        )}
      </div>
      <div className="button-wrapper">
        {!props.isLoadingType && props.selectedId && (
          <button
            type="button"
            className="large primary"
            onClick={onSaveTexts}
            disabled={props.isSavingTextItems}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default withRouter(EditType);
