import * as React from 'react';
import RecipeSelectables from 'domain/recipe-picker/components/recipe-selectables/selectables';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import './view.scss';

interface Props {
  materials?: Array<UIAvailableSelectableModel>
  finishes?: Array<UIAvailableSelectableModel>
  markets?: Array<UIAvailableSelectableModel>
  machines?: Array<UIAvailableSelectableModel>
  toolSystems?: Array<UIAvailableSelectableModel>
  selectedFilter: RecipeFiltersModel
  onCriteriaSelected: (name: string, value: UIAvailableSelectableModel) => void
}

const RecipeSelectablesPage: React.FC<Props> = props => {
  return <div className="rec-sel-view">
    <div className="crit-sel-view">
      <div>
        <div>
          <RecipeSelectables
            selectables={props.markets}
            allAvailible
            title="Markets"
            propName="marketId"
            onSelected={props.onCriteriaSelected}
            selectedFilter={props.selectedFilter} />
        </div>
        <div>
          <RecipeSelectables
            selectables={props.materials}
            title="Materials"
            propName="materialId"
            onSelected={props.onCriteriaSelected}
            selectedFilter={props.selectedFilter} />
        </div>
        <div>
          <RecipeSelectables
            selectables={props.finishes}
            title="Finishes"
            propName="finishId"
            onSelected={props.onCriteriaSelected}
            selectedFilter={props.selectedFilter} />
        </div>
        <div>
          <RecipeSelectables
            selectables={props.machines}
            title="Machines"
            propName="machineModelId"
            onSelected={props.onCriteriaSelected}
            selectedFilter={props.selectedFilter} />
        </div>
        <div>
          <RecipeSelectables
            selectables={props.toolSystems}
            title="ToolSystem"
            propName="toolSystemId"
            onSelected={props.onCriteriaSelected}
            selectedFilter={props.selectedFilter} />
        </div>
      </div>
    </div>
  </div>;
};

export default RecipeSelectablesPage;
