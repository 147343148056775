import {
  ADD_RECIPE_CONFIG, REMOVE_RECIPE_CONFIG, MACHINE_RECIPE_REQ_SUCCESS,
  SET_RECIPE_STEP_CONFIG_ONGOING, SAVE_RECIPE_CONFIG_REQ_SUCCESS
} from 'reducers/action-constants';
import { AnyAction } from 'redux';
import { RecipeId } from 'domain/models/recipe-model';
import { MachineModelId } from 'domain/models/machine-type-model';
import { RecipeSelection, RecipeStep } from 'domain/recipe-designer/container/machine-recipe-reducer';

export interface RecipeConfigStore {
  configByRecipe: Map<string, RecipeConfigModel>
  configurationStep?: RecipeStep
}

export type ToolFamilyId = string;

export interface RecipeConfigModel {
  recipeId: RecipeId
  machineModelId: MachineModelId
  stepConfigIsDirty: boolean,
  stepConfig: Map<ToolFamilyId, RecipeSelection>
}

export interface AddRecipeConfigAction {
  type: typeof ADD_RECIPE_CONFIG
  item: RecipeAddConfigPayload
}

export interface SetStepConfigurationOngoingAction {
  type: typeof SET_RECIPE_STEP_CONFIG_ONGOING
  item: RecipeStep | undefined
}
export interface RecipeAddConfigPayload {
  recipeId: string
  machineModelId: string
  stepDefaultSelection: RecipeSelection
}
export interface RemoveRecipeConfigAction {
  type: typeof REMOVE_RECIPE_CONFIG
  item: RecipeRemoveConfigPayload
}
export interface RecipeRemoveConfigPayload {
  recipeId: string
  machineModelId: string
  toolFamilyId: string
}
export interface MachineRecipeAction {
  type: typeof MACHINE_RECIPE_REQ_SUCCESS
}

export type RecipeConfigActionTypes = AddRecipeConfigAction | RemoveRecipeConfigAction | MachineRecipeAction | SetStepConfigurationOngoingAction | AnyAction;

export const recipeKey = (recipeId: RecipeId, machineModelId: MachineModelId): string => {
  return `${recipeId}:${machineModelId}`;
};

const keyFromAction = (response: { recipeId: string, machineModelId: string }): string => {
  return recipeKey(response.recipeId, response.machineModelId);
};

const loadFromExistingConf = (configByRecipe: Map<string, RecipeConfigModel>, action: any): Map<string, RecipeConfigModel> => {
  const results = new Map(configByRecipe);
  if (action.response.selections) {
    const recipe = {
      recipeId: action.context.recipeId,
      machineModelId: action.context.machineModelId,
      stepConfigIsDirty: false,
      stepConfig: new Map()
    };
    action.response.selections.forEach((element: any) => {
      recipe.stepConfig.set(element.toolFamilyId, element);
    });
    results.set(keyFromAction(action.context), recipe);

  }
  return results;
};

const addConf = (configByRecipe: Map<string, RecipeConfigModel>, payload: RecipeAddConfigPayload): Map<string, RecipeConfigModel> => {
  const results = new Map(configByRecipe);
  let recipe = results.get(keyFromAction(payload));
  if (!recipe) {
    recipe = {
      recipeId: payload.recipeId,
      machineModelId: payload.machineModelId,
      stepConfigIsDirty: true,
      stepConfig: new Map()
    };
    results.set(keyFromAction(payload), recipe);
  } else {
    recipe.stepConfigIsDirty = true;
  }
  const newDefaultConfig = {
    ...payload.stepDefaultSelection
  };
  recipe.stepConfig.set(payload.stepDefaultSelection.toolFamilyId, newDefaultConfig);
  return results;
};

const setNotDirty = (configByRecipe: Map<string, RecipeConfigModel>, context: { recipeId: string, machineModelId: string }): Map<string, RecipeConfigModel> => {
  const results = new Map(configByRecipe);
  const recipe = results.get(keyFromAction(context));
  if (recipe) {
    recipe.stepConfigIsDirty = false;
  }
  return results;
};

const removeConf = (configByRecipe: Map<string, RecipeConfigModel>, payload: RecipeRemoveConfigPayload): Map<string, RecipeConfigModel> => {
  const results = new Map(configByRecipe);
  const recipe = results.get(keyFromAction(payload));
  if (!recipe) {
    throw new Error(`No such recipe ${payload.recipeId}/${payload.machineModelId}`);
  }
  const newRecipe = {
    ...recipe,
    stepConfigIsDirty: true,
    stepConfig: new Map(recipe.stepConfig)
  };
  newRecipe.stepConfig.delete(payload.toolFamilyId);
  results.set(keyFromAction(payload), newRecipe);

  return results;
};

const getInitialState = (): RecipeConfigStore => ({
  configByRecipe: new Map()
});

export default (state = getInitialState(), action: AnyAction): RecipeConfigStore => {
  switch (action.type) {
    case ADD_RECIPE_CONFIG:
      return {
        configByRecipe: addConf(state.configByRecipe, action.item)
      };
    case REMOVE_RECIPE_CONFIG:
      return {
        configByRecipe: removeConf(state.configByRecipe, action.item)
      };
    case MACHINE_RECIPE_REQ_SUCCESS:
      return {
        configByRecipe: loadFromExistingConf(state.configByRecipe, action)
      };
    case SET_RECIPE_STEP_CONFIG_ONGOING:
      return {
        ...state,
        configurationStep: action.item
      };
    case SAVE_RECIPE_CONFIG_REQ_SUCCESS:
      return {
        configByRecipe: setNotDirty(state.configByRecipe, action.context)
      };
    default:
      return state;
  }
};
