import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as actions from 'domain/content/container/actions';
import {
  FinishModelWithTexts,
  FinishTypeModel,
} from 'domain/models/finish-type-model';
import { TosRootState } from 'reducers';
import AddNewFinish from 'domain/content/components/finishes/add-new-finish/add-new-finish';
import EditFinish from 'domain/content/components/finishes/edit-finish/edit-finish';
import './finishes.scss';
import { FinishFormModel } from './finish-form/finish-form-model';

interface Props
  extends RouteComponentProps<{
    finishTypeId: string;
    finishId: string;
    languageId: string;
  }> {
  createdFinish: any;
  finishes?: Array<FinishTypeModel>;
  loadedFinish: FinishModelWithTexts;
  isAddingNewFinish: boolean;
  isLoadingFinish: boolean;
  isSavingFinish: boolean;
  isSavingNewFinish: boolean;
  getFinish: typeof actions.getFinish;
  saveFinish: typeof actions.saveFinish;
  saveNewFinish: typeof actions.saveNewFinish;
  saveFinishImage: typeof actions.saveFinishImage;
  setIsAdding: Function;
  suggestedUIOrder?: string;
  isLoadingSuggestedUIOrder: boolean;
  getSuggestedFinishUIOrder: typeof actions.getSuggestedFinishUIOrder;
  resetSuggestedUIOrder: typeof actions.resetSuggestedUIOrder;
}

export const initialFormState: FinishFormModel = {
  finishTypeId: '',
  name: '',
  subtitle: '',
  description: '',
  uiOrder: ''
};

const Finishes: React.FC<Props> = (props) => {
  const [formState, setFormState] = useState<FinishFormModel>({
    ...initialFormState,
    finishTypeId: props.match.params.finishTypeId
  });

  const { getFinish, setIsAdding, resetSuggestedUIOrder } = props;

  const handleLanguageClick = useCallback(
    (languageId: string) => {
      props.history.push(
        `/content/finish-types/${props.match.params.finishTypeId}/finishes/${props.match.params.finishId}/${languageId}`
      );
    },
    [props.history, props.match.params]
  );

  useEffect(() => {
    if (!props.isAddingNewFinish && !props.match.params.languageId) {
      handleLanguageClick('en');
    }
  }, [props.match.params.languageId, handleLanguageClick, props.isAddingNewFinish]);

  // Fetch finish when top finishId changes
  useEffect(() => {
    if (props.match.params.finishId === 'new' && !props.isAddingNewFinish) {
      setIsAdding(true);
      return;
    }
    if (props.isSavingFinish || !props.match.params.finishId || props.match.params.finishId === 'new') {
      return;
    }
    getFinish(props.match.params.finishId);
  }, [getFinish, props.match.params.finishId, props.isSavingFinish, setIsAdding, props.isAddingNewFinish]);

  useEffect(() => {
    if (props.isAddingNewFinish) {
      setFormState(initialFormState);
      resetSuggestedUIOrder();
    }
  }, [props.isAddingNewFinish, resetSuggestedUIOrder]);

  useEffect(() => {
    if (props.createdFinish) {
      props.history.push(`/content/finish-types/${props.createdFinish.finishTypeId}/finishes/${props.createdFinish.id}/en`);
    }
  }, [props.createdFinish, props.history, props.match.params]);

  const handleUpload = useCallback(
    async (imageId: string, file: File) => {
      if (!props.loadedFinish?.id) {
        throw new Error('Illegal state, missing id of loadedFinish');
      }
      props.saveFinishImage(props.loadedFinish.id, imageId, file.name);
    },
    [props]
  );

  return (
    <div className="finishes-view">
      {props.isAddingNewFinish ? (
        <AddNewFinish
          formState={formState}
          setFormState={setFormState}
          finishTypes={props.finishes}
          saveNewFinish={props.saveNewFinish}
          isSavingNewFinish={props.isSavingNewFinish}
          setIsAdding={setIsAdding}
          getSuggestedUIOrder={props.getSuggestedFinishUIOrder}
          suggestedUIOrder={props.suggestedUIOrder}
          isLoadingSuggestedUIOrder={props.isLoadingSuggestedUIOrder}
          />
      ) : (
        props.match.params.finishId && (
          <EditFinish
            isLoadingFinish={props.isLoadingFinish}
            finishTypes={props.finishes}
            languageId={props.match.params.languageId}
            selectedId={props.match.params.finishId}
            selectedFinishTypeId={props.match.params.finishTypeId}
            formState={formState}
            onFileUploaded={handleUpload}
            setFormState={setFormState}
            handleLanguageClick={handleLanguageClick}
            loadedFinish={props.loadedFinish}
            saveFinish={props.saveFinish}
            isSavingFinish={props.isSavingFinish}
          />
        )
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getFinish: actions.getFinish,
  saveFinish: actions.saveFinish,
  saveNewFinish: actions.saveNewFinish,
  saveFinishImage: actions.saveFinishImage,
  getSuggestedFinishUIOrder: actions.getSuggestedFinishUIOrder,
  resetSuggestedUIOrder: actions.resetSuggestedUIOrder,
};

const mapStateToProps = ({ content: { finishes: contentFinishes }, finishes }: TosRootState) => ({
  finishes: finishes.items,
  createdFinish: contentFinishes.createdFinish,
  loadedFinish: contentFinishes.loadedFinish,
  isLoadingFinish: contentFinishes.isLoadingFinish,
  isSavingFinish: contentFinishes.isSavingFinish,
  isSavingNewFinish: contentFinishes.isSavingNewFinish,
  suggestedUIOrder: contentFinishes.suggestedUIOrder,
  isLoadingSuggestedUIOrder: contentFinishes.isLoadingSuggestedUIOrder
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withRouter(Finishes));
