import { Header, ListItem } from 'app/list/list';

export const languageHeader: Array<Header> = [
  {
    name: 'language',
    text: 'Input Language',
  },
];

interface LanguageLists {
  [key: string]: Array<ListItem>;
}

export const languageLists: LanguageLists = {
  language: [
    {
      id: 'en',
      name: 'English (Default)',
    },
    {
      id: 'de',
      name: 'German',
    },
    {
      id: 'sv',
      name: 'Swedish',
    },
  ],
};
