import {
  GET_TOOLSEL_CONFIG_REQ, GET_TOOLSEL_CONFIG_REQ_SUCCESS, GET_TOOLSEL_CONFIG_REQ_FAILURE,
  CLEAR_TOOLSEL_CONFIG
} from 'reducers/action-constants';

export interface MachineModelConfigurationStore {
  isFetching?: boolean,
  itemsById: Map<string, MachineModelConfigurationModel>
}
export interface MachineModelConfigurationModel {
  isFetching: boolean
  validConfigurations: Array<ConfigurationItemModel>
  availableConfigs: Array<ConfigurationItemModel>
  toolSelection?: ToolSelectionConfigurationModel
}
export interface ConfigurationItemModel {
  id: string
  connectorTypeId: string
  toolQuantity: string
  machineModelId: string
  diameter: string
}

export interface ToolSelectionConfigurationModel {
  id: string
  toolFamilyId: string
  connectorTypeId: string
  diameter: string
}

export const keyFrom = (context: { toolSelectionId: string, machineModelId: string }) => `${context.machineModelId}:${context.toolSelectionId}`;

const updateItem = (
  context: {toolSelectionId: string, machineModelId: string},
  configByName: Map<string, MachineModelConfigurationModel>,
  configItem?: MachineModelConfigurationModel): Map<string, MachineModelConfigurationModel> => {
  const results = configByName ? new Map(configByName) : new Map();

  if (configItem) {
    results.set(keyFrom(context), {
      ...configItem,
      isFetching: false
    });
  } else {
    results.set(keyFrom(context), {
      isFetching: true,
      validConfigurations: [],
      availableConfigs: []
    });
  }
  return results;
};

const initialState = { itemsById: new Map() };

export default (state: MachineModelConfigurationStore = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_TOOLSEL_CONFIG:
      return initialState;
    case GET_TOOLSEL_CONFIG_REQ:
      return {
        ...state,
        isFetching: true,
        // @ts-ignore
        itemsById: updateItem(action.context, state.itemsById, undefined)
      };
    case GET_TOOLSEL_CONFIG_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        // @ts-ignore
        itemsById: updateItem(action.context, state.itemsById, action.response)
      };
    case GET_TOOLSEL_CONFIG_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        itemsById: updateItem(
          action.context,
          state.itemsById,
          {
            isFetching: false,
            validConfigurations: [],
            availableConfigs: []
          }
        )
      };
    default:
      return state;
  }
};
