import React, { FC } from 'react';
import SelectOne from 'domain/recipe-picker/components/select-one/select-one';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import './copy-selections.scss';

interface Props {
  onMachineSelected: (machineModelId: string) => void
  onDismissCopy: () => void
  onCopyClicked: () => void
  selectedMachine?: string
  selectableMachines: Array<any>
  copyResults?: Array<any> | false
}

const CopyMachineSelection: FC<Props> = ({ onMachineSelected, onDismissCopy, onCopyClicked, selectedMachine, selectableMachines, copyResults }) => {
  return <div className="editor empty copy-view">
    <div>
      <p>You need to create configurations for this machine. Do you want to copy it?</p>
      {copyResults && <p><strong>Copy done, ${copyResults.length} copied configurations</strong></p>}
      <div>
        <SelectOne
          title="Select machine"
          items={selectableMachines}
          propName="copyFromMachine"
          itemsListLabelPropName="label"
          selectedValue={selectedMachine}
          itemsListValuePropName="value"
          onSelected={(name:any, item: UISelectableModel) => {
            onMachineSelected(item.value);
          }} />
      </div>
      <div className="actions">
        <button className="primary" disabled={!selectedMachine} onClick={onCopyClicked} type="button">Copy</button>
        <button className="secondary" onClick={onDismissCopy} type="button">Don&apos;t copy</button>
      </div>
    </div>
  </div>;
};

export default CopyMachineSelection;
