import { asSelectables } from 'app/form/utils';

const initialMarkets = [
  { id: 'AU', name: 'Australia Market' },
  { id: 'BRAZIL', name: 'Brazil Market' },
  { id: 'GLOBAL', name: 'Global Market' },
  { id: 'US', name: 'US Market' }
];

const getInitialState = () => ({
  items: initialMarkets,
  selectables: asSelectables(initialMarkets, 'name', 'id')
});

export type MarketId = string;
export default (state: any = getInitialState()) => state;
