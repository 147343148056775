import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Wait from 'app/wait/wait';
import { ToolFamilyModel } from 'domain/models/tool-family';
import ToolFamilyEditor from 'domain/tool-families/components/view/editor/tool-family-editor';
import { ToolSelectionModel, ToolSelectionId } from 'domain/models/tool-selection-model';
import { TosRootState } from 'reducers';
import * as actions from './actions';

interface Props extends RouteComponentProps {
  isFetching: boolean,
  isSaving: boolean,
  selectedToolFamilyId: string,
  selectedToolFamily?: ToolFamilyModel,
  markets: Array<any>,
  isToolSearchFetching: boolean,
  toolSearchList: Array<any>,
  getToolFamily: (id: string) => void,
  searchTool: (articleNumber: string) => void,
  updateToolFamily: (toolFamilyId: string, toolFamily: ToolFamilyModel) => void,
  createNewToolFamily: (toolFamily: ToolFamilyModel) => any,
  addTool: (articleNumber: string, toolName: string, route: string) => void,
  onCreate: () => void,
  isCreatingNew: boolean,
  isCreated?: boolean,
  selectedToolFamilyType: string,
  isFetchingDimrTool: boolean,
  dimrTool: any
}

const initialState: ToolFamilyModel = {
  id: '',
  name: '',
  gritSize: '',
  type: 'GRINDING',
  toolSelections: [],
  markets: []
};

const ToolFamilyContainer: React.FC<Props> = (props: Props) => {
  const [toolFamily, setToolFamily] = useState(initialState);

  const { isCreatingNew, isCreated, selectedToolFamilyId, getToolFamily, selectedToolFamily, isFetchingDimrTool, dimrTool, selectedToolFamilyType } = props;

  useEffect(() => {
    if (isCreated && selectedToolFamily?.id) {
      props.history.push(`/tool-families/${selectedToolFamily.type.toLowerCase()}/${selectedToolFamily.id}`);
    }
  }, [isCreated]);

  useEffect(() => {
    if (!isCreatingNew && selectedToolFamilyId) {
      getToolFamily(selectedToolFamilyId);
    }
  }, [selectedToolFamilyId]);

  useEffect(() => {
    if (isCreatingNew) {
      // eslint-disable-next-line no-console
      console.log('Setting tool family from new state');
      setToolFamily({
        ...initialState,
        type: selectedToolFamilyType,
        markets: selectedToolFamilyType === 'CHEMICAL' || selectedToolFamilyType === 'HOLDER' ? [] : props.markets.map(m => m.id)
      });
    } else if (selectedToolFamily) {
      // eslint-disable-next-line no-console
      console.log('Setting tool family from old state', JSON.stringify(selectedToolFamily));
      setToolFamily({ ...selectedToolFamily });
    }
  }, [isCreatingNew, selectedToolFamily]);

  useEffect(() => {
    if (!isFetchingDimrTool && dimrTool) {
      const { toolSelections } = toolFamily;
      toolSelections?.push({
        id: dimrTool.id,
        dimrToolId: dimrTool.id,
        name: dimrTool.name,
        productNumber: dimrTool.productNumber,
        status: 'ADD',
        isDeletable: true
      });
      setToolFamily({
        ...toolFamily,
        toolSelections
      });
    }
  }, [dimrTool]);

  const handleAddTool = (articleNumber: string, toolName: string) => {
    const isChem = toolFamily.type === 'CHEMICAL';
    const isTool = toolFamily.type === 'GRINDING' || toolFamily.type === 'POLISHING';
    const isHold = toolFamily.type === 'HOLDER';
    let route = '';
    if (isChem) {
      route = 'chem';
    }
    if (isTool) {
      route = 'tool';
    }
    if (isHold) {
      route = 'hold';
    }
    props.addTool(articleNumber, toolName, route);
  };

  const handleSearchTool = (articleNumber: string) => {
    props.searchTool(articleNumber);
  };

  const handleChangeName = (toolFamilyName: string) => {
    setToolFamily({
      ...toolFamily,
      name: toolFamilyName
    });
  };

  const handleChangeGritSize = (gritSize: string) => {
    setToolFamily({
      ...toolFamily,
      gritSize: gritSize.toString()
    });
  };

  const handleSave = async () => {
    if (props.isCreatingNew) {
      // eslint-disable-next-line no-console
      console.log('Create stuff');
      props.onCreate();
      props.createNewToolFamily(toolFamily);
    } else {
      props.updateToolFamily(toolFamily.id, toolFamily);
    }
  };

  const handleMarketAvailToggle = (marketId: string) => {
    const marketAvailSet = new Set(toolFamily.markets);
    if (marketAvailSet.has(marketId)) {
      marketAvailSet.delete(marketId);
    } else {
      if (toolFamily.type === 'CHEMICAL') {
        marketAvailSet.clear();
      }
      marketAvailSet.add(marketId);
    }
    setToolFamily({
      ...toolFamily,
      markets: Array.from(marketAvailSet)
    });
  };

  const handleViewToolInfo = (id: ToolSelectionId) => {
    props.history.push(`/tool/${id}`);
  };

  const handleDeleteToolSelection = (id: string) => {
    const newArray: Array<ToolSelectionModel> = [];
    toolFamily.toolSelections?.forEach((ts: ToolSelectionModel) => {
      if (ts.id === id) {
        if (ts.status !== 'ADD') {
          newArray.push({
            ...ts,
            status: 'DELETE'
          });
        }
      } else {
        newArray.push(ts);
      }
    });
    setToolFamily({
      ...toolFamily,
      toolSelections: newArray
    });
  };

  return <div className="tool-family-editor">
    { props.isFetching && <Wait /> }
    { (toolFamily && ((selectedToolFamilyId && props.selectedToolFamily) || props.isCreatingNew)) &&
      <ToolFamilyEditor
        toolFamily={toolFamily}
        markets={props.markets}
        handleAddTool={handleAddTool}
        handleSearchTool={handleSearchTool}
        handleDeleteToolSelection={handleDeleteToolSelection}
        onViewToolInfo={handleViewToolInfo}
        isFetching={props.isFetching}
        isFetchingDimrTool={props.isFetchingDimrTool}
        isSearching={props.isToolSearchFetching}
        isSaving={props.isSaving}
        toolSearchList={props.toolSearchList}
        handleSave={handleSave}
        handleMarketAvailToggle={handleMarketAvailToggle}
        handleChangeName={handleChangeName}
        handleChangeGritSize={handleChangeGritSize}
      />}
  </div>;
};

const mapStateToProps = (state: TosRootState) => {
  return {
    isFetching: state.toolFamily.isFetching,
    isSaving: state.toolFamily.isSaving,
    selectedToolFamilyId: state.allToolFamilies.selectedToolFamilyId,
    selectedToolFamily: state.toolFamily.item,
    markets: state.markets.items,
    isToolSearchFetching: state.toolSearch.isFetching,
    toolSearchList: state.toolSearch.items,
    isFetchingDimrTool: state.dimrTool.isFetching,
    dimrTool: state.dimrTool.item,
    isCreated: state.toolFamily.isCreated
  };
};

export default connect(mapStateToProps, actions)(withRouter(ToolFamilyContainer));
