import {
  CREATE_TOOLFAMILY_REQ, CREATE_TOOLFAMILY_REQ_SUCCESS, CREATE_TOOLFAMILY_REQ_FAILURE,
  GET_ALL_TOOLFAMILY_REQ, GET_ALL_TOOLFAMILY_REQ_SUCCESS, GET_ALL_TOOLFAMILY_REQ_FAILURE,
  SELECT_TOOLFAMILY, DELETE_TOOLFAMILY_REQ
} from 'reducers/action-constants';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { toolFamilyComparator } from 'domain/tool-families/container/tool-family-sort';

export interface AllToolFamiliesStore {
  isFetching: boolean,
  items?: Array<ToolFamilyModel>
  itemsById?: Map<string, ToolFamilyModel>,
  itemsByType?: Map<string, Array<ToolFamilyModel>>,
  newest?: any,
  sortName?: string,
  isAscSort?: boolean,
  selectedToolFamilyId?: string
}

const byId = (list: ToolFamilyModel[]) => {
  const results = new Map<string, ToolFamilyModel>();
  list.forEach(item => results.set(item.id, item));
  return results;
};

const byType = (list: ToolFamilyModel[]) => {
  const types = [...new Set(list?.map(item => item.type))];
  const results = new Map<string, Array<ToolFamilyModel>>();
  types.forEach(type => results.set(type, list.filter(item => item.type === type).sort((a, b) => toolFamilyComparator(a, b))));
  return results;
};

export default (state: AllToolFamiliesStore = { isFetching: false }, action: any) => {
  switch (action.type) {
    case GET_ALL_TOOLFAMILY_REQ:
    case CREATE_TOOLFAMILY_REQ:
      return {
        ...state,
        isFetching: true
      };
    case GET_ALL_TOOLFAMILY_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.response,
        itemsById: action.response && byId(action.response),
        itemsByType: action.response && byType(action.response)
      };
    case CREATE_TOOLFAMILY_REQ_SUCCESS: {
      const items = state.items?.concat([action.response]);
      const itemsById = items && byId(items);
      const itemsByType = items && byType(items);
      return {
        ...state,
        isFetching: false,
        // @ts-ignore
        items,
        itemsById,
        itemsByType,
        newest: action.response,
        selectedToolFamilyId: action.response.id
      };
    }
    case GET_ALL_TOOLFAMILY_REQ_FAILURE:
    case CREATE_TOOLFAMILY_REQ_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case SELECT_TOOLFAMILY:
      return {
        ...state,
        selectedToolFamilyId: action.toolFamilyId
      };
    case DELETE_TOOLFAMILY_REQ:
      return {
        ...state,
        selectedToolFamilyId: undefined
      };
    default:
      return state;
  }
};
