import React, { useEffect, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import List, { ListItem } from 'app/list/list';
import { MachineType } from 'domain/models/machine-type-model';
import { MaterialTypeModel } from 'domain/models/material-type-model';
import { FinishTypeModel } from 'domain/models/finish-type-model';
import { ToolSystemModel } from 'domain/models/tool-system-model';
import ListFooter from './content-list-footer';
import materialTypes from '../material-types/material-types';

const headers = [
  { name: 'material-types', text: 'Materials' },
  { name: 'finish-types', text: 'Finishes' },
  { name: 'machine-types', text: 'Machines' },
  { name: 'tool-systems', text: 'Tool Systems' },
];

export interface Props extends RouteComponentProps<{ type: string; id: string; param3?: string; }> {
  machineTypes: Array<MachineType> | undefined;
  materialTypes: Array<MaterialTypeModel> | undefined;
  finishTypes: Array<FinishTypeModel> | undefined;
  toolSystems: Array<ToolSystemModel> | undefined;
  onRowClick(id: string): void;
  onRemoveClick(id: string): void;
  onCreateClick(type: string): void;
}

const ContentList: React.FC<Props> = props => {
  const { params } = props.match;

  // Redirect user to first type in the list if user visits /content/ (without type)
  useEffect(() => {
    if (!params.type) {
      props.history.push(`/content/${headers[0].name}`);
    }
  }, [params.type, props.history]);

  const listsWithItems: {
    [key: string]: Array<ListItem> | undefined;
  } = useMemo(
    () => ({
      'material-types': props.materialTypes?.map(materialType => ({
        id: materialType.id,
        name: materialType.name,
        subListName: 'materials',
        subList: materialType.materials?.map(material => ({
          id: material.id,
          name: material.name,
          isDeletable: material.isDeletable
        })),
        isDeletable: materialType.materials?.length === 0,
      })),
      'finish-types': props.finishTypes?.map(finishType => ({
        id: finishType.id,
        name: finishType.name,
        subListName: 'finishes',
        subList: finishType?.finishes?.map(finish => ({
          id: finish.id,
          name: finish.name,
          isDeletable: finish.isDeletable
        })),
        isDeletable: materialTypes?.length === 0
      })),
      'machine-types': props.machineTypes?.map(machineType => ({
        id: machineType.id,
        name: machineType.name,
        subListName: 'machineModels',
        subList: machineType?.machineModels?.map(machineModel => ({
          id: machineModel.id,
          name: machineModel.name,
          isDeletable: machineModel.isDeletable
        })),
        isDeletable: materialTypes?.length === 0
      })),
      'tool-systems': props.toolSystems?.map(toolSystem => ({
        ...toolSystem,
        isDeletable: true
      }))
    }),
    [props.machineTypes, props.materialTypes, props.finishTypes, props.toolSystems]
  );

  return (
    <List
      path="content"
      headers={headers}
      lists={listsWithItems}
      selectedType={params.type}
      selectedId={params.param3 || params.id}
      onRowClick={props.onRowClick}
      onRemoveClick={props.onRemoveClick}
      listItemsUppercase
      footer={<ListFooter contentType={params.type} onCreateClick={props.onCreateClick} />}
    />
  );
};

export default withRouter(ContentList);
