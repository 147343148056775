import React, { FC } from 'react';
import * as actions from 'domain/recipe-designer/container/actions';
import PubSub from 'app/utils/pubsub';
import { recipeKey } from 'domain/recipe-designer/container/recipe-config-reducer';
import './design-footer.scss';
import { useSelector } from 'reducers/index';
import { useDispatch } from 'react-redux';
import { RecipeId } from 'domain/models/recipe-model';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';

interface Props {
  onPublishClicked: () => void
  stepClickedSubscription: PubSub
  userFilterSelection: RecipeFiltersModel
}

const RecipeDesignFooter: FC<Props> = ({ onPublishClicked, stepClickedSubscription, userFilterSelection }: Props) => {
  const dispatch = useDispatch();
  const stepsDirty = useSelector(state => state.machineRecipe.item && state.machineRecipe.item.stepsDirty
  );
  const recipeId: RecipeId | any =
    useSelector(state => state.recipesFilter.recipes &&
      state.recipesFilter.recipes.recipes &&
      state.recipesFilter.recipes.recipes.length === 1 &&
      state.recipesFilter.recipes.recipes[0]
    );
  const machineModelId = useSelector(state => state.recipesFilter.fetchedFilter?.machineModelId);

  const configDirty = useSelector(state => {
    let isDirty;
    if (machineModelId) {
      const r = state.recipeConfig.configByRecipe.get(recipeKey(recipeId, machineModelId));
      return r?.stepConfigIsDirty;
    }
    return isDirty;
  });

  const dispatchSave = async () => dispatch(actions.saveRecipeSteps(recipeId));

  return <div className="rd-row-cp rd-footer">
    <div className="steps">
      { (recipeId && userFilterSelection.finishId) &&
        <button className="secondary" onClick={() => { stepClickedSubscription.notifyAll('add-step-clicked'); }} type="button">Add step</button> }
      { stepsDirty &&
        <button className="primary" onClick={dispatchSave} type="button">Save</button> }
    </div>
    <div className="config">
      { (!stepsDirty && configDirty) && <button className="primary" onClick={onPublishClicked} type="button">Publish changes</button> }
    </div>
  </div>;
};

export default RecipeDesignFooter;
