import React from 'react';
import List from 'app/list/list';
import { languageHeader, languageLists } from './utils';

export interface Props {
  path: string;
  selectedLanguageId: string;
  handleLanguageClick(languageId: string): void;
}

const LanguageList: React.FC<Props> = props => {
  return (
    <List
      path={props.path}
      selectedType="language"
      selectedId={props.selectedLanguageId}
      onRowClick={props.handleLanguageClick}
      headers={languageHeader}
      lists={languageLists}
      isItemsDeletable={false}
    />
  );
};

export default LanguageList;
