import * as React from 'react';
import './input.scss';

interface InpProps {
  onChange?: (event: any) => void
  onValueChanged?: (event: any) => void
  name: string
  type?: string
  tabIndex?: number
  label?: string
  maxLength?: number
  minValue?: number
  placeholder?: string
  value?: number | string
  disabled?: boolean
  required?: boolean
  title?: string
  styleOnWhite?: boolean
}

class StatelessInput extends React.PureComponent<InpProps> {
  constructor (props: InpProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange (event: any) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  onBlur (event: any) {
    if (this.props.onValueChanged) {
      this.props.onValueChanged(event);
    }
  }

  render () {
    return (
      <div className={`ic${this.props.disabled ? ' disabled' : ''}${this.props.styleOnWhite ? ' on-white' : ''}`}>
        {this.props.label && (
          <label htmlFor={this.props.name}>
            { this.props.label }
          </label>
        )}
        <input
          type={this.props.type || 'text'}
          name={this.props.name}
          id={this.props.name}
          tabIndex={this.props.tabIndex}
          value={this.props.value}
          autoComplete="off"
          disabled={this.props.disabled || false}
          readOnly={this.props.disabled || false}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          // @ts-ignore
          // eslint-disable-next-line
          ref={element => (element || {}).onsearch = this.onChange}
          title={this.props.title}
          onChange={this.onChange}
          min={this.props.minValue}
          onBlur={this.onBlur} />
      </div>
    );
  }
}

export default StatelessInput;
