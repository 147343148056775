import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import { UISelectableModel } from 'domain/models/ui-selectable-model';

export const availableSelectables = (allSelectables: Array<UISelectableModel>, values: Array<string>):Array<UIAvailableSelectableModel> => {
  const set = new Set(values);

  return allSelectables?.map((selectable) => {
    return {
      value: selectable.value,
      label: selectable.label,
      isAvailable: set.has(selectable.value)
    };
  });
};

export const availableSelectableFromItems = (
  valueProp: string,
  labelProp: string,
  all: Array<any>,
  values: Array<string>
):Array<UIAvailableSelectableModel> => {
  const set = new Set(values);

  return all?.map((selectable) => {
    return {
      value: selectable[valueProp],
      label: selectable[labelProp],
      isAvailable: set.has(selectable[valueProp])
    };
  });
};
