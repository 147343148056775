import React from 'react';
import './stepdata.scss';

export interface Props {
  modelName?: string;
  discDiameter?: string | number;
  discs?: string | number;
}

const StepData: React.FC<Props> = ({ modelName, discDiameter, discs }) => {
  return (
    <div className="step-data card">
      <div className="title-wrapper">
        <div className="card-title">Step data</div>
        <span className="locked">Locked</span>
      </div>
      <div>
        <div className="values">
          <div>Model name</div>
          <div>{modelName}</div>
        </div>

        <div className="flex">
          <div className="values">
            <div>Disc diameter</div>
            <div>{discDiameter} mm</div>
          </div>
          <div className="values">
            <div>Discs</div>
            <div>{discs}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepData;
