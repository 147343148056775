import React, { FC, useEffect, useState } from 'react';
import StatelessInput from 'app/form/input/stateless-input';
import SearchResults from 'domain/tool-families/components/view/editor/tool-search/search-results-list';
import Wait from 'app/wait/wait';
import { ToolSearchModel } from 'domain/models/tool-search-model';
import debounce from 'app/utils/debounce';

interface Props {
  isSearching: boolean,
  pnc: string,
  onPncChanged: (event: any) => void,
  onDimrClicked: Function,
  onDimrClickedText?: string,
  dimrSearchList?: Array<ToolSearchModel>
}

const SearchDimr: React.FC<Props> = ({ isSearching, onPncChanged, onDimrClicked, onDimrClickedText, pnc, dimrSearchList }) => {
  const [inputPnc, setInputPnc] = useState<string>(pnc);
  useEffect(() => {
    setInputPnc(pnc);
  }, [pnc]);

  const searchImmediate = (event: any) => {
    setInputPnc(event.target.value);
    event.persist();
    search(event);
  };

  const search = debounce((event: any) => {
    onPncChanged(event.target.value);
  }, 500);

  return <>
    <StatelessInput
      type="text"
      name="find-pnc"
      label="Article number"
      styleOnWhite
      value={inputPnc}
      onChange={searchImmediate} />
    { onDimrClickedText && <div className="dimr-clicked"><Wait size="small" /> {onDimrClickedText}</div> }
    { isSearching && <Wait size="small" /> }
    <SearchResults
      list={dimrSearchList}
      onSelect={onDimrClicked}
    />
  </>;
};

export default SearchDimr;
