import React, { PureComponent } from 'react';
import BodyWithTopMenu from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import Overview from 'domain/dashboard/components/diagram/overview';
import './page.scss';

class LandingPage extends PureComponent {
  render () {
    return <BodyWithTopMenu
      content={<div>
        <Overview />
      </div>}
      contentClassName="landing-page-content"
      topbar={<Menu />}
    />;
  }
}

export default LandingPage;
