import React, { FC } from 'react';
import { RecipeUsage } from 'domain/tools/container/reducer';
import './usage-table.scss';
import { Link } from 'react-router-dom';

type Props = {
  recipes: Array<RecipeUsage>,
};

const RecipesUsageList: FC<Props> = ({ recipes }) => {

  return <div className="ruse">
    <div className="header">
      <div>Market</div>
      <div>Material</div>
      <div>Finish</div>
      <div>Machine Model</div>
    </div>
    { recipes.map(r => <div key={`${r.recipeId}_${r.machineModelId}`}>
      <div>{r.marketName}</div>
      <div>{r.materialName}</div>
      <div>{r.finishName}</div>
      <div>
        <Link
          to={`/recipes/market/${r.marketId}/material/${r.materialId}/finish/${r.finishId}/machine/${r.machineModelId}`}>
          {r.machineModelName}
        </Link>
      </div>
    </div>) }
  </div>;
};

export default RecipesUsageList;
