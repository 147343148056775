import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import Login from 'domain/login/container/login';
import AppMessages from 'app/general/messages/container/app-messages';
import ValidateAuthentication from 'domain/login/container/validate-authentication';
import PrivateRoute from './utils/private-route';
import TosApp from './tos-app';

class RoutingInternal extends PureComponent {
  render () {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route exact path="/validate" component={ValidateAuthentication} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/" component={TosApp} />
          </Switch>
          <AppMessages />
        </HashRouter>
      </>
    );
  }
}

export default RoutingInternal;
