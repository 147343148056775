import React, { PureComponent } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { error } from 'app/general/messages/toast-util';
import { ToastContainer } from 'react-toastify';
import {
  AUTHENTICATION_INVALID,
  SERVER_ERROR,
  GENERAL_ERROR,
  REQUEST_ERROR,
  UNAUTHORIZED,
  DB_TEMPORARILY_UNAVAILABLE,
  SERVER_UNDERLYING_SYSTEM_ERROR,
  NOT_FOUND,
  VALIDATION_VIOLATION
} from 'app/utils/error-constants';
import * as actions from 'app/general/messages/container/actions';
import './app-messages.scss';

interface AppMessagesProps {
  errors: any
  clearErrors: Function
}

interface AppMessagesState {
  errors: any,
  invalidLogin?: boolean,
}

const getHeading = (errorCode: any) => {
  switch (errorCode) {
    case AUTHENTICATION_INVALID:
      return 'Login error';
    case SERVER_ERROR:
      return 'Server failed';
    case UNAUTHORIZED:
      return 'Unauthorized';
    case SERVER_UNDERLYING_SYSTEM_ERROR:
      return 'An underlying system failed to respond';
    case REQUEST_ERROR:
      return 'Request rejected';
    case VALIDATION_VIOLATION:
      return 'Validation error';
    case NOT_FOUND:
      return 'Entity not found';
    case DB_TEMPORARILY_UNAVAILABLE:
      return 'Database pending (try again)';
    case GENERAL_ERROR:
      return 'Error';
    default:
      return 'Sorry, something went wrong';
  }
};

const getMessage = (err: any) => {
  return `${getHeading(err.code)}: ${err.title ? err.title : ''}${err.detail ? ` (${err.detail})` : ''}`;
};

class AppMessages extends PureComponent<AppMessagesProps, AppMessagesState> {
  static getDerivedStateFromProps (nextProps: AppMessagesProps, prevState: AppMessagesState) {
    if (nextProps.errors !== prevState.errors) {
      if (nextProps.errors) {
        error(getMessage(nextProps.errors));
        return {
          errors: nextProps.errors,
          invalidLogin: (nextProps.errors.code === AUTHENTICATION_INVALID)
        };
      }
    }
    return null;
  }

  constructor (props: AppMessagesProps) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  componentDidUpdate () {
    if (this.props.errors) {
      this.props.clearErrors();
    }
  }

  render () {
    return <div className="msg-c">
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnHover
          />
      {this.state.invalidLogin && <Redirect to="/login" />}
    </div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    errors: state.errors
  };
};

// @ts-ignore
export default connect(mapStateToProps, actions)(AppMessages);
