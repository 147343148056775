import {
  MachineModelId,
  MachineModelModel,
  ModelConfigUsageModel,
} from 'domain/models/machine-type-model';
import { Reducer } from 'redux';
import * as actionConstants from 'reducers/action-constants';

export interface MachineModelsStore {
  isSavingModelConfiguration: boolean;
  isFetchingDimrMachine: boolean;
  dimrMachine?: MachineModelModel;
  savedMachineModelId?: MachineModelId;
  machineModelConfig?: ModelConfigUsageModel[];
}

const getInitialState = () => ({
  isSavingModelConfiguration: false,
  isFetchingDimrMachine: false,
});

const reducer: Reducer<MachineModelsStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case actionConstants.SAVE_MACHINE_REQ:
    case actionConstants.SAVE_MODEL_CONFIGURATION_REQ:
      return {
        ...state,
        savedMachineModelId: undefined,
        isSavingModelConfiguration: true,
      };

    case actionConstants.SAVE_MACHINE_REQ_FAILURE:
    case actionConstants.SAVE_MODEL_CONFIGURATION_REQ_SUCCESS:
    case actionConstants.SAVE_MODEL_CONFIGURATION_REQ_FAILURE:
      return {
        ...state,
        isSavingModelConfiguration: false,
      };

    case actionConstants.SAVE_MACHINE_REQ_SUCCESS:
      return {
        ...state,
        isSavingModelConfiguration: false,
        savedMachineModelId: action.response?.[0]?.machineModelId,
      };

    case actionConstants.RESET_SAVED_MACHINE_MODEL_ID:
      return {
        ...state,
        savedMachineModelId: undefined,
      };

    case actionConstants.DIMR_MACHINE_REQ:
      return {
        ...state,
        isFetchingDimrMachine: true,
      };

    case actionConstants.DIMR_MACHINE_REQ_SUCCESS:
      return {
        ...state,
        isFetchingDimrMachine: false,
        dimrMachine: {
          ...action?.response?.[0],
          name: action?.response?.[0].model
        }
      };
    case actionConstants.DIMR_MACHINE_REQ_FAILURE:
      return {
        ...state,
        isFetchingDimrMachine: false,
      };

    case actionConstants.DELETE_MACHINE_MODEL_REQ:
      return {
        ...state,
        isFetchingDimrMachine: true,
      };

    case actionConstants.DELETE_MACHINE_MODEL_REQ_SUCCESS:
      return {
        ...state,
        isFetchingDimrMachine: false,
      };

    case actionConstants.DELETE_MACHINE_MODEL_REQ_FAILURE:
      return {
        ...state,
        isFetchingDimrMachine: false,
      };

    case actionConstants.MODELS_CONFIG_REQ_SUCCESS:
      return {
        ...state,
        machineModelConfig: action.response,
      };

    case actionConstants.MODELS_CONFIG_REQ:
    case actionConstants.MODELS_CONFIG_REQ_FAILURE:
    default:
      return state;
  }
};

export default reducer;
