import React, { FC } from 'react';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import { productNumber } from 'app/utils/format';
import SelectOne from 'domain/recipe-picker/components/select-one/select-one';
import './tool-selector.scss';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { MachineModelConfigurationModel, keyFrom } from 'domain/recipe-designer/container/machine-configurations-reducer';
import { MachineModelId } from 'domain/models/machine-type-model';

interface Props {
  readonly toolFamilyWithToolSelections?: ToolFamilyModel;
  readonly toolSelectionId?: string;
  readonly machineModelId: MachineModelId
  onToolSelected: (propName: string, item: UISelectableModel) => void
  configMap: Map<string, MachineModelConfigurationModel>
}

const findToolName = (ts: any) => {
  return `${ts.name} (${productNumber(ts.productNumber)})`;
};

const ToolSelector: FC<Props> = ({ toolFamilyWithToolSelections, onToolSelected, toolSelectionId, configMap, machineModelId }: Props) => {
  const tf = toolFamilyWithToolSelections;

  if (tf && tf.toolSelections && tf.toolSelections.length > 0) {
    return <div className="conf-sel-cmp">
      <div>
        <SelectOne
          title="Default Tool"
          items={tf.toolSelections}
          propName="defaultTool"
          itemsListLabelPropName={findToolName}
          selectedValue={toolSelectionId}
          itemsListValuePropName="id"
          isAvailable={(id) => {
            const c = configMap.get(keyFrom({ machineModelId, toolSelectionId: id }));
            return tf.type === 'CHEMICAL' || tf.type === 'HOLDER' ||
                !!(c && c.validConfigurations.length > 0);
          }}
          onSelected={onToolSelected} />
      </div>
    </div>;
  }
  if (tf && tf.toolSelections && tf.toolSelections.length === 0) {
    return <div className="conf-sel-cmp">
      { (tf.type === 'POLISHING' || tf.type === 'GRINDING') && <div>{`Tool family ${tf.name} has no grinding or polishing tools`}</div> }
      { tf.type === 'HOLDER' && <div>{`Tool family ${tf.name} has no tool holders`}</div> }
      { tf.type === 'CHEMICAL' && <div>{`Tool family ${tf.name} has no chemicals`}</div> }
    </div>;
  }
  if (!tf || !tf.toolSelections) {
    return <div className="conf-sel-cmp">
      <div>Tool family is incomplete</div>
    </div>;
  }
  return <div className="conf-sel-cmp">
    <div>Wait...</div>
  </div>;
};

export default ToolSelector;
