import {
  GET_CONF_REQ, GET_CONF_REQ_SUCCESS, GET_CONF_REQ_FAILURE
} from 'reducers/action-constants';

import Config from 'app/utils/config';

export interface ConfigStore {
  isFetching: boolean,
  isFetched: boolean,
  isLocal?: boolean,
  item?: ConfigItem
}
export interface ConfigItem {
  endpoint: URL
  apiKey: string
}

const getInitialState = () => {
  const overrideConfig = (Config.ENVIRONMENT === 'local');
  // eslint-disable-next-line no-console
  console.log(`Config for: ${Config.ENVIRONMENT} environment`);
  if (overrideConfig) {
    // eslint-disable-next-line no-console
    console.log('< -- Using config for development -- >');
    return {
      item: {
        endpoint: Config.backendEndpoint,
        apiKey: Config.backendApiKey
      },
      isFetched: false,
      isLocal: true
    };
  }

  return {
    isFetched: false,
    item: {
      // @ts-ignore
      endpoint: upConfig.api.host
    }
  };
};

// apiKey
export default (state: any = getInitialState(), action: any): ConfigStore => {
  switch (action.type) {
    case GET_CONF_REQ:
      return {
        ...state,
        isFetching: true
      };
    case GET_CONF_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        item: {
          auth: {
            ...action.response.auth
          },
          endpoint: state.item.endpoint || action.response.endpoint,
          apiKey: action.response.apiKey
        }
      };
    case GET_CONF_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: false
      };
    default:
      return state;
  }
};
