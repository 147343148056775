import {
  ApiMachineConfiguration,
  MachineModelId,
  EditableMachineModelModel
} from 'domain/models/machine-type-model';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CALL_API } from 'app/middleware/api';
import * as actionConstants from 'reducers/action-constants';
import * as toastUtils from 'app/general/messages/toast-util';

/**
 * Delete machine type
 */
const createDeleteMachineTypeAction = (machineTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_MACHINE_TYPE_REQ,
      actionConstants.DELETE_MACHINE_TYPE_REQ_SUCCESS,
      actionConstants.DELETE_MACHINE_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/machine-model-types/${machineTypeId}`,
    options: {
      method: 'DELETE',
    },
  },
});

export const deleteMachineType = (machineTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteMachineTypeAction(machineTypeId)).then(
    result => {
      if (result.type === actionConstants.DELETE_MACHINE_TYPE_REQ_SUCCESS) {
        toastUtils.success('Machine Type successfully deleted');
      }

      return dispatch(createDeleteMachineTypeAction(machineTypeId));
    }
  );
};

/**
 * Get all machine models
 */
const createGetMachineModelsAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.MODELS_REQ,
      actionConstants.MODELS_REQ_SUCCESS,
      actionConstants.MODELS_REQ_FAILURE,
    ],
    pathname: '/v2/surface-prep/machine-models',
  },
});

export const getMachineModels = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMachineModelsAction());
};

const createGetMachineModelConfigsAction = (id: MachineModelId) => ({
  [CALL_API]: {
    types: [
      actionConstants.MODELS_CONFIG_REQ,
      actionConstants.MODELS_CONFIG_REQ_SUCCESS,
      actionConstants.MODELS_CONFIG_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/machine-models/${id}/config`,
  },
});

/**
 * Get machine model config
 */
export const getMachineModelConfig = (id: MachineModelId) => (
  dispatch: (action: any) => Promise<any>
) => {
  return dispatch(createGetMachineModelConfigsAction(id));
};

/**
 * Update machine model configuration
 */
const createUpdateMachineModelConfigurationsAction = (
  modelId: string,
  machineConfigurations: Array<ApiMachineConfiguration>
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_MODEL_CONFIGURATION_REQ,
      actionConstants.SAVE_MODEL_CONFIGURATION_REQ_SUCCESS,
      actionConstants.SAVE_MODEL_CONFIGURATION_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/machine-models/${modelId}/config`,
    options: {
      method: 'PUT',
      body: JSON.stringify({
        machineConfigurations,
      }),
    },
  },
});

export const updateMachineModelConfigurations = (
  modelId: string,
  machineConfigurations: Array<ApiMachineConfiguration>
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createUpdateMachineModelConfigurationsAction(modelId, machineConfigurations)
  ).then(e => {
    if (e.type === actionConstants.SAVE_MODEL_CONFIGURATION_REQ_SUCCESS) {
      toastUtils.success('Configuration successfully saved');
    }
    return dispatch(createGetMachineModelsAction());
  });
};

/**
 * Get dimr machine
 */
const createGetDimrMachineAction = (pncNumber: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DIMR_MACHINE_REQ,
      actionConstants.DIMR_MACHINE_REQ_SUCCESS,
      actionConstants.DIMR_MACHINE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/machine-models/machines?pnc=${pncNumber}`,
    options: {
      method: 'GET',
    },
  },
});

export const getDimrMachine = (pncNumber: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetDimrMachineAction(pncNumber));
};

/**
 * Add machine model
 */
const createAddMachineAction = (
  pncNumber: string,
  machineConfigurations: Array<ApiMachineConfiguration>,
  model: EditableMachineModelModel
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_MACHINE_REQ,
      actionConstants.SAVE_MACHINE_REQ_SUCCESS,
      actionConstants.SAVE_MACHINE_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/machine-models/',
    options: {
      method: 'POST',
      body: JSON.stringify({
        productNumber: pncNumber,
        machineConfigurations,
        model
      }),
    },
  },
});

export const addMachine = (
  pncNumber: string,
  machineConfigurations: Array<ApiMachineConfiguration>,
  model: EditableMachineModelModel
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createAddMachineAction(pncNumber, machineConfigurations, model)
  ).then(e => {
    if (e.type === actionConstants.SAVE_MACHINE_REQ_SUCCESS) {
      toastUtils.success('Machine model successfully saved');
    }
    return dispatch(createResetSavedMachineModelId()).then(() => {
      return dispatch(createGetMachineModelsAction());
    });
  });
};

const createResetSavedMachineModelId = () => ({
  type: actionConstants.RESET_SAVED_MACHINE_MODEL_ID,
});

const createResetSuggestedUIOrderAction = () => ({
  type: actionConstants.RESET_SUGGESTED_UI_ORDER
});

export const resetSuggestedUIOrder = () => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(createResetSuggestedUIOrderAction());
};

/**
 * Delete machine model
 */
const createDeleteMachineModelAction = (modelId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_MACHINE_MODEL_REQ,
      actionConstants.DELETE_MACHINE_MODEL_REQ_SUCCESS,
      actionConstants.DELETE_MACHINE_MODEL_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/machine-models/${modelId}`,
    options: {
      method: 'DELETE',
    },
    context: {
      modelId,
    },
  },
});

export const deleteMachineModel = (modelId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteMachineModelAction(modelId)).then(e => {
    if (e.type === actionConstants.DELETE_MACHINE_MODEL_REQ_SUCCESS) {
      toastUtils.success('Machine model successfully deleted');
    }
    return dispatch(createGetMachineModelsAction());
  });
};

/**
 * Save machine image
 */
const createUpdateMachineModelaction = (
  id: MachineModelId,
  model: EditableMachineModelModel
): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.UPDATE_MACHINE_MODEL_REQ,
      actionConstants.UPDATE_MACHINE_MODEL_REQ_SUCCESS,
      actionConstants.UPDATE_MACHINE_MODEL_REQ_FAILURE,
    ],
    pathname: `v2/surface-prep/admin/machine-models/${id}`,
    options: {
      method: 'PATCH',
      body: JSON.stringify(model),
    },
  },
});

/**
 * Save image to a machine
 * @param id
 * @param imageId
 * @param fileName
 */
export const updateMachineModel = (
  id: MachineModelId,
  model: EditableMachineModelModel
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    const outcome = await dispatch(createUpdateMachineModelaction(id, model));
    if (outcome.type === actionConstants.UPDATE_MACHINE_MODEL_REQ_SUCCESS) {
      toastUtils.success(`Machine model saved${model.isPublic ? ' as public' : ''}`);
      return dispatch(createGetMachineModelsAction());
    }
    return outcome;
  };
};

/**
 * Get machine type
 */
const createGetMachineTypeActions = (machineTypeId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.MACHINE_TYPE_REQ,
      actionConstants.MACHINE_TYPE_REQ_SUCCESS,
      actionConstants.MACHINE_TYPE_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/machine-model-types/${machineTypeId}?languageCode=en`,
    options: {
      method: 'GET',
    },
  },
});

export const getMachineType = (machineTypeId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetMachineTypeActions(machineTypeId));
};

/**
 * Save new machine type
 */
const createSaveNewMachineTypeAction = (
  name: string,
  description: string,
  uiOrder: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_MACHINE_TYPE_REQ,
      actionConstants.SAVE_NEW_MACHINE_TYPE_REQ_SUCCESS,
      actionConstants.SAVE_NEW_MACHINE_TYPE_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/machine-model-types',
    options: {
      method: 'POST',
      body: JSON.stringify({
        machineModelTypeName: name,
        description,
        uiOrder,
      }),
    },
  },
});

export const saveNewMachineType = (
  name: string,
  description: string,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveNewMachineTypeAction(name, description, uiOrder)
  ).then(result => {
    if (result.type === actionConstants.SAVE_NEW_MACHINE_TYPE_REQ_SUCCESS) {
      toastUtils.success('New Machine Type successfully created');
    }

    return dispatch(createGetMachineModelsAction());
  });
};
