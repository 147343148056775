import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import * as actions from 'domain/recipe-designer/container/actions';
import PubSub from 'app/utils/pubsub';
import Wait from 'app/wait/wait';
import { RecipeId } from 'domain/models/recipe-model';
import RecipeDesignTable from 'domain/recipe-designer/components/table/recipe-design-table';
import CreateRecipe from 'domain/recipe-designer/components/editor/create-recipe';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { moveUp } from 'domain/recipe-designer/container/designer-utils';
import { recipeKey } from 'domain/recipe-designer/container/recipe-config-reducer';
import { RecipeStep } from 'domain/recipe-designer/container/machine-recipe-reducer';
import { TosRootState } from 'reducers';
import { MachineModelId } from 'domain/models/machine-type-model';
import './designer.scss';

type OwnProps = {
  userFilterSelection: RecipeFiltersModel
  stepClickedSubscription: PubSub
};

interface State {
  configRequest?: {
    machineModelId: MachineModelId,
    recipeId: RecipeId
  }
  toolFamilyDetailRequest?: any
}

const mapStateToProps = (state: TosRootState) => {
  const { recipesFilter } = state;
  const fetchedFilter = recipesFilter.fetchedFilter || {};
  const configMap = state.machineModelConfigurations.itemsById;
  const recipes = recipesFilter.recipes && recipesFilter.recipes.recipes;
  let recipeConfig;
  if (recipes && recipes.length > 0 && state.machineRecipe.params && state.machineRecipe.params.machineModelId) {
    const recipeConfigState = state.recipeConfig;
    recipeConfig = recipeConfigState.configByRecipe.get(recipeKey(recipes[0], state.machineRecipe.params.machineModelId));
  }
  const { configurationStep } = state.recipeConfig;
  const isFetching = recipesFilter.isFetching || state.machineRecipe.isFetching;
  const recipeId = (recipesFilter.recipes && recipesFilter.recipes.recipes && recipesFilter.recipes.recipes.length > 0) && recipesFilter.recipes.recipes[0];
  const noOfRecipes = (recipesFilter.recipes && recipesFilter.recipes.recipes) && recipesFilter.recipes.recipes.length;

  return {
    machineRecipe: state.machineRecipe,
    recipesFilter,
    toolFamiliesById: state.families.itemsById,
    isFetchedDetails: state.families.isFetchedDetails,
    isFetching,
    configMap,
    recipeConfig,
    recipeId,
    noOfRecipes,
    fetchedFilter,
    configurationStep
  };
};

const connector = connect(
  mapStateToProps,
  actions
);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

export class RecipeDesignerContainer extends PureComponent<Props, State> {
  constructor (props: Props) {
    super(props);
    this.state = {};
    this.handleAddStep = this.handleAddStep.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleConfigRemoved = this.handleConfigRemoved.bind(this);
    this.handleConfigureStep = this.handleConfigureStep.bind(this);
    this.handleCancelConfigureStep = this.handleCancelConfigureStep.bind(this);
    this.handleStepRemoved = this.handleStepRemoved.bind(this);
    this.handleAddStep = this.handleAddStep.bind(this);
    this.handleToolSelected = this.handleToolSelected.bind(this);
    this.handleStepMoveUp = this.handleStepMoveUp.bind(this);
    this.handleStepMoveDown = this.handleStepMoveDown.bind(this);
    this.handleCreateRecipe = this.handleCreateRecipe.bind(this);
  }

  componentDidMount () {
    this.props.getFamilies();
  }

  componentDidUpdate () {
    const {
      recipesFilter,
      toolFamiliesById,
      isFetchedDetails,
      machineRecipe,
      userFilterSelection,
      getConfigurations,
      getMachineRecipe,
      getAllFamilyDetails
    } = this.props;

    const recipes = recipesFilter.recipes && recipesFilter.recipes.recipes;
    const displaySteps = (recipesFilter.fetchedFilter && recipesFilter.fetchedFilter.finishId);
    if (toolFamiliesById && !isFetchedDetails) {
      getAllFamilyDetails();
    }
    if (recipes && recipes.length > 0) {
      if (displaySteps) {
        if (!machineRecipe.isFetching && userFilterSelection.finishId &&
          (!machineRecipe.params || machineRecipe.params.recipeId !== recipes[0] ||
          machineRecipe.params.machineModelId !== userFilterSelection.machineModelId ||
          machineRecipe.params.toolSystemId !== userFilterSelection.toolSystemId)) {
          getMachineRecipe(recipes[0], userFilterSelection);
        } else if (machineRecipe.params && machineRecipe.item) {
          const storeRequest = machineRecipe.params;
          const prevConfigRequest = this.state.configRequest;
          if (!prevConfigRequest || prevConfigRequest.recipeId !== storeRequest.recipeId || prevConfigRequest.machineModelId !== storeRequest.machineModelId) {

            if (machineRecipe.item.selections) {
              machineRecipe.item.selections.forEach(s => {
                getConfigurations(s.toolSelectionId, storeRequest.machineModelId);
              });
              // eslint-disable-next-line react/no-did-update-set-state
              this.setState({ configRequest: machineRecipe.params });
            }
          }

        }
      }

      if ((!machineRecipe.params || machineRecipe.params.recipeId !== recipes[0]) && userFilterSelection.machineModelId) {
        getMachineRecipe(recipes[0], userFilterSelection);
      }
    }
  }

  handleToolSelected (toolSelectionId: string, machineModelId?: string) {
    if (!machineModelId) {
      throw new Error('Invalid state, machine model must be selected prior to tool selection');
    }

    this.props.getConfigurations(toolSelectionId, machineModelId);
  }

  handleConfigSaved (config: any) {
    const recipes = this.props.recipesFilter.recipes && this.props.recipesFilter.recipes.recipes;
    const recipeId = (recipes && recipes[0]) || '';
    const machineModelId = (this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId) || '';

    const defaultConfig = {
      ...config,
      recipeId
    };

    this.props.setDefaultSelection(
      recipeId,
      machineModelId,
      defaultConfig
    );
  }

  handleAddStep (toolFamily: ToolFamilyModel) {
    const recipes = this.props.recipesFilter.recipes && this.props.recipesFilter.recipes.recipes;
    const recipeId = (recipes && recipes[0]) || '';
    const machineModelId = (this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId) || undefined;

    this.props.addMachineRecipeStep(recipeId, toolFamily, machineModelId);
  }

  handleStepRemoved (step: RecipeStep) {
    const recipes = this.props.recipesFilter.recipes && this.props.recipesFilter.recipes.recipes;
    const recipeId = (recipes && recipes[0]) || '';
    const machineModelId = (this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId) || '';
    const { toolFamilyId } = step;

    this.props.removeMachineRecipeStep(recipeId, toolFamilyId, machineModelId);
  }

  handleConfigRemoved (step: RecipeStep) {
    const recipes = this.props.recipesFilter.recipes && this.props.recipesFilter.recipes.recipes;
    const recipeId = (recipes && recipes[0]) || '';
    const machineModelId = (this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId) || '';
    const { toolFamilyId } = step;

    this.props.removeDefaultSelection(
      recipeId,
      machineModelId,
      toolFamilyId
    );
  }

  async handleCreateRecipe () {
    const f = this.props.recipesFilter.fetchedFilter;
    if (!f) {
      throw new Error('Illegal State: attempt to create recipe without selection');
    }
    const results = await this.props.createRecipe(f.marketId, f.materialId, f.finishId);
    if (results.isOk) {
      const { fetchedFilter } = this.props;
      if (fetchedFilter) {
        this.props.getRecipes(fetchedFilter);
      }
    }
  }

  handleStepMoveUp (step: RecipeStep) {
    if (this.props.machineRecipe.item) {
      const { steps, isChanged } = moveUp(this.props.machineRecipe.item.steps, step);
      if (isChanged) {
        this.props.rearrangeMachineRecipeSteps(steps, this.props.recipeId);
      }
    }
  }

  handleStepMoveDown (step: RecipeStep) {
    if (this.props.machineRecipe.item) {
      const { steps, isChanged } = moveUp(this.props.machineRecipe.item.steps, step, false);
      if (isChanged) {
        this.props.rearrangeMachineRecipeSteps(steps, this.props.recipeId);
      }
    }
  }

  async handleConfigureStep (toolFamily: ToolFamilyModel, step: RecipeStep) {
    if (!toolFamily.detailedItem) {
      throw new Error(`Detailed items for ${toolFamily.name}(${toolFamily.id}) not populated`);
    }
    const machineModelId = (this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId) || '';
    toolFamily.detailedItem.toolSelections?.map(ts => ts.id && this.props.getConfigurations(ts.id, machineModelId));
    this.props.setConfigurationOngoing(step);
  }

  async handleCancelConfigureStep () {
    this.props.setConfigurationOngoing(undefined);
  }

  render () {
    const machineModelId = this.props.machineRecipe.params && this.props.machineRecipe.params.machineModelId;
    const machineRecipe = this.props.machineRecipe.item;
    if (!this.props.recipeId && this.props.userFilterSelection.finishId) {
      return <CreateRecipe onCreateClicked={this.handleCreateRecipe} />;
    }
    if (machineRecipe && this.props.toolFamiliesById) {
      return <RecipeDesignTable
        recipe={machineRecipe}
        stepClickedSubscription={this.props.stepClickedSubscription}
        toolFamiliesById={this.props.toolFamiliesById}
        machineModelId={machineModelId}
        configMap={this.props.configMap}
        recipeConfig={this.props.recipeConfig}
        onSave={this.handleConfigSaved}
        onDeleteConfiguration={this.handleConfigRemoved}
        onConfigureStep={this.handleConfigureStep}
        onCancelConfigureStep={this.handleCancelConfigureStep}
        onDeleteStep={this.handleStepRemoved}
        onToolFamilyAdded={this.handleAddStep}
        onToolSelected={this.handleToolSelected}
        onStepMoveUp={this.handleStepMoveUp}
        ongoingConfigurationStep={this.props.configurationStep}
        onStepMoveDown={this.handleStepMoveDown} />;
    }
    if (this.props.isFetching) {
      return <Wait slowReveal />;
    }

    return null;
  }
}

export default connector(RecipeDesignerContainer);
