import React, { FC, useEffect, useState, useCallback } from 'react';
import { Prompt } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import Page from 'domain/recipe-picker/components/layout/with-menu/view';
import * as actions from 'domain/recipe-picker/container/actions';
import { RecipesFilterStateModel } from 'domain/recipe-picker/container/recipes-filter-state-model';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { recipeKey } from 'domain/recipe-designer/container/recipe-config-reducer';
import { TosRootState } from 'reducers';
import { MarketId } from 'domain/markets/container/markets-reducer';
import { MaterialId } from 'domain/models/material-type-model';
import { RecipeId } from 'domain/models/recipe-model';
import { FinishId } from 'domain/models/finish-type-model';
import { MachineModelId } from 'domain/models/machine-type-model';
import { ToolSystemId } from 'domain/models/tool-system-model';
import { availableSelectables } from './recipe-utils';

type ReduxProps = ConnectedProps<typeof connector>;

type PickedPathParams = {
  marketId: MarketId;
  materialId: MaterialId
  finishId: FinishId
  machineModelId: MachineModelId
  toolSystemId: ToolSystemId
};

type Props = ReduxProps & RouteComponentProps<PickedPathParams>;

const RecipeContainer: FC<Props> = (props) => {
  const response = props.recipesState.recipes;
  const [markets, setMarkets] = useState<UIAvailableSelectableModel[]>([]);
  const [userFilter, setUserFilter] = useState<any>({});

  const { marketId, materialId, finishId, machineModelId, toolSystemId } = props.match.params;

  const init = () => {
    props.clearRecipesFilter();
    // props.getRecipes();
    props.getMarketSelectables();
    // props.getMaterials();
  };
  useEffect(() => {
    init();

    return () => {
      // Cleanup function (will execute on component unload)
      props.clearRecipesFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserFilter({
      marketId,
      materialId,
      finishId,
      machineModelId,
      toolSystemId,
    });

    if (!marketId) {
      props.clearMaterialSelection();
    }

    if (!materialId) {
      props.clearFinishSelection();
    }

    if (!finishId) {
      props.clearMachineModelSelection();
    } else if (!props.toolSystems) {
      props.getToolSystems();
    }

    if (!machineModelId) {
      props.clearToolSystemSelection();
    }
  }, [props.match.params]);

  useEffect(() => {
    // needed to designer component
    if (userFilter.marketId && userFilter.materialId && userFilter.finishId && userFilter.machineModelId) {
      // eslint-disable-next-line no-console
      console.log('Fetch recipes (for designer)', userFilter);
      props.getRecipes(userFilter);
    }
  }, [userFilter.finishId, userFilter.machineModelId]);

  useEffect(() => {
    if (userFilter.marketId) {
      props.getMaterialSelectables(userFilter);
      props.getMaterials();
    }
    props.clearMachineRecipe();
  }, [userFilter.marketId]);

  useEffect(() => {
    if (userFilter.materialId) {
      props.getFinishSelectables(userFilter);
      props.getFinishes();
    }
    props.clearMachineRecipe();
  }, [userFilter.materialId]);

  useEffect(() => {
    if (userFilter.finishId) {
      props.getMachineModelSelectables(userFilter);
      props.getMachineModels();
    }
  }, [userFilter.finishId]);

  useEffect(() => {
    if (userFilter.machineModelId) {
      props.getToolSystemSelectables(userFilter);
      props.getToolSystems();
    }
  }, [userFilter.machineModelId]);

  useEffect(() => {
    if (props.materials) {
      props.getMaterialSelectables(userFilter);
    }
    props.clearMachineRecipe();
  }, [props.materials]);

  useEffect(() => {
    if (props.finishes) {
      props.getFinishSelectables(userFilter);
    }
    props.clearMachineRecipe();
  }, [props.finishes]);

  useEffect(() => {
    if (props.machineModels) {
      props.getMachineModelSelectables(userFilter);
    }
  }, [props.machineModels]);

  useEffect(() => {
    if (props.toolSystems) {
      props.getToolSystemSelectables(userFilter);
    }
  }, [props.toolSystems]);

  useEffect(() => {
    if (response && props.markets && !markets.length) {
      setMarkets(availableSelectables(props.markets, response.filters.markets));
    }
  }, [response, props.markets, markets]);

  const handlePublish = useCallback(
    () => {
      const recipeId = (props.recipesState.recipes && props.recipesState.recipes.recipes && props.recipesState.recipes.recipes[0]) || '';
      props.saveConfig(recipeId, userFilter.machineModelId || '', userFilter.toolSystemId);
    },
    [props]
  );

  const handleCriteriaSelected = (name: string, selectedItem: UIAvailableSelectableModel) => {
    const queryFilter = {
      ...userFilter,
      [name]: selectedItem.value
    };
    if (name === 'marketId') {
      props.history.push(`/recipes/market/${selectedItem.value}`);
    }

    if (name === 'materialId') {
      props.history.push(`/recipes/market/${marketId}/material/${selectedItem.value}`);
    }

    if (name === 'finishId') {
      props.history.push(`/recipes/market/${marketId}/material/${materialId}/finish/${selectedItem.value}`);
    }

    if (name === 'machineModelId') {
      props.history.push(
        `/recipes/market/${marketId
        }/material/${materialId
        }/finish/${finishId
        }/machine/${selectedItem.value}`
      );
    }
    if (name === 'toolSystemId') {
      props.history.push(
        `/recipes/market/${marketId
        }/material/${materialId
        }/finish/${finishId
        }/machine/${machineModelId
        }/tool-system/${selectedItem.value}`
      );
    }
    const filterIndex = ['marketId', 'materialId', 'finishId', 'machineModelId', 'toolSystemId'];
    const nameIndex = filterIndex.indexOf(name);
    filterIndex.slice(nameIndex, -1).forEach(key => {
      delete queryFilter[key];
    });
  };
  return <>
    <Prompt
      when={!!props.isDirty}
      message="You have unsaved changes,are you sure you want to leave?"
    />
    <Page
      onPublishClicked={handlePublish}
      markets={props.recipePicker.markets.selectables}
      materials={props.recipePicker.materials.selectables}
      finishes={props.recipePicker.finishes.selectables}
      machines={props.recipePicker.machines.selectables}
      toolSystems={props.recipePicker.toolSystems.selectables}
      selectedFilter={userFilter}
      onCriteriaSelected={handleCriteriaSelected}
      />
  </>;
};

const mapStateToProps = (state: TosRootState) => {
  const materials = state.materials.selectables;
  const finishes = state.finishes.selectables;
  const machineModels = state.machineModels.selectables;
  const toolSystems = state.toolSystems.selectables;
  const markets = state.markets.selectables;

  const recipesState: RecipesFilterStateModel = state.recipesFilter;

  let isDirty = state.machineRecipe.item && state.machineRecipe.item.stepsDirty;
  if (!isDirty) {
    const recipeId: RecipeId | any = state.recipesFilter.recipes &&
          state.recipesFilter.recipes.recipes &&
          state.recipesFilter.recipes.recipes.length > 0 &&
          state.recipesFilter.recipes.recipes[0];
    const machineModelId = state.recipesFilter.fetchedFilter?.machineModelId;

    if (machineModelId) {
      const r = state.recipeConfig.configByRecipe.get(recipeKey(recipeId, machineModelId));
      isDirty = r?.stepConfigIsDirty || false;
    }
  }

  return {
    recipePicker: state.recipePicker,
    materials,
    finishes,
    toolSystems,
    markets,
    machineModels,
    recipesState,
    isDirty
  };
};

const connector = connect(
  mapStateToProps,
  actions
);

export default withRouter(connector(RecipeContainer));
