import React, { useState } from 'react';
import Menu from 'app/top-menu/menu';
import RecipeSelectablesView from 'domain/recipe-picker/components/layout/recipe-selectables/view';
import PubSub from 'app/utils/pubsub';
import RecipeDesigner from 'domain/recipe-designer/container/designer';
import RecipeDesignerHeader from 'domain/recipe-designer/components/table/header/design-header';
import RecipeFooter from 'domain/recipe-designer/components/table/footer/design-footer';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';

import './view.scss';

interface Props {
  materials?: Array<UIAvailableSelectableModel>
  finishes?: Array<UIAvailableSelectableModel>
  markets?: Array<UIAvailableSelectableModel>
  machines?: Array<UIAvailableSelectableModel>
  toolSystems?: Array<UIAvailableSelectableModel>
  selectedFilter: RecipeFiltersModel
  onCriteriaSelected: (name: string, value: UIAvailableSelectableModel) => void
  onPublishClicked: () => void
}

const RecipeSelectionPage: React.FC<Props> = props => {
  const [actionSubscription] = useState({ subscription: new PubSub() });
  return (
    <div className="seg">
      <div className="segment menu">
        <Menu />
      </div>
      <div className="segment up3rd">
        <RecipeSelectablesView {...props} />
      </div>
      <div className="segment header">
        <RecipeDesignerHeader isDisabled={false} />
      </div>
      <div className="segment body">
        {props.selectedFilter.marketId
        && props.selectedFilter.materialId
        && props.selectedFilter.finishId
        && props.selectedFilter.machineModelId
        && <RecipeDesigner
          userFilterSelection={props.selectedFilter}
          stepClickedSubscription={actionSubscription.subscription} />}
      </div>
      <div className="segment footer">
        <RecipeFooter
          onPublishClicked={props.onPublishClicked}
          userFilterSelection={props.selectedFilter}
          stepClickedSubscription={actionSubscription.subscription} />
      </div>
    </div>
  );
};

export default RecipeSelectionPage;
