import localStorageUtil from 'app/utils/local-storage';

import {
  AUTENTICATED_USER_REQ, AUTENTICATED_USER_REQ_SUCCESS, AUTENTICATED_USER_REQ_FAILURE,
  AUTHORIZE_CODE_REQ, AUTHORIZE_CODE_REQ_SUCCESS, AUTHORIZE_CODE_REQ_FAILURE,
  LOGOUT
} from 'reducers/action-constants';
import { UserId, MarketApiModel } from 'domain/users/container/user-models';

export interface LoginStore {
  isFetched: boolean
  isFetching: boolean
  isFailed: boolean
  isAuthenticated: boolean
  sessionId?: string
  authenticatedUser?: AuthenticatedUser
}

export interface AuthenticatedUser {
  uid: UserId
  loggedInAt: string
  isRecipeAdmin: boolean
  isSuperAdmin: boolean
  salesCompanyCode: string
  market: MarketApiModel
}

const getInitialState = (ls: any = localStorageUtil): LoginStore => {
  const sessionId = ls.getSession();
  return {
    isFetched: false,
    isFetching: false,
    isFailed: false,
    isAuthenticated: !!sessionId,
    sessionId
  };
};

export default (state = getInitialState(), action: any, ls = localStorageUtil): LoginStore => {
  switch (action.type) {
    case AUTHORIZE_CODE_REQ:
      return {
        ...state,
        isAuthenticated: false,
        isFetched: false,
        isFetching: true
      };
    case AUTHORIZE_CODE_REQ_SUCCESS:
      ls.storeSession(action.response.sessionId);
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        sessionId: action.response.sessionId
      };
    case AUTHORIZE_CODE_REQ_FAILURE:
      return {
        ...state,
        isFetched: false,
        isFailed: true,
        isFetching: false,
        isAuthenticated: false,
        sessionId: undefined
      };
    case AUTENTICATED_USER_REQ_SUCCESS:
      return {
        ...state,
        authenticatedUser: action.response
      };
    case AUTENTICATED_USER_REQ_FAILURE:
      return {
        ...state,
        authenticatedUser: undefined
      };
    case LOGOUT:
      ls.storeSession(undefined);
      return {
        ...getInitialState(ls),
        isAuthenticated: false,
        sessionId: undefined
      };
    case AUTENTICATED_USER_REQ:
    default:
      return state;
  }
};
