import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import * as actions from 'app/config-actions';
import './login.scss';

type LoginProps = RouteComponentProps & {
  config: any
  location: any
  getConfig: () => void
};
class Login extends PureComponent<LoginProps> {
  componentDidMount () {
    this.props.getConfig();
  }

  login () {
    const redirectUri = encodeURIComponent(this.props.config.auth.redirectUris.WEB);
    const { from } = this.props.location.state || { from: undefined };
    const state = from ? encodeURIComponent(from.pahtname) : '858084797976';

    // eslint-disable-next-line max-len
    window.location.href = `${this.props.config.auth.authorizationEndpoint}?client_id=${this.props.config.auth.clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20fullname%20mail%20groups%20hvaaccountgrouplabel%20salescompanycode%20salescompanycodeandcustomernumber%20hvaofficecity&state=${state}`;
  }

  render () {

    const { from } = this.props.location.state || { from: undefined };

    return (
      <div>
        <div className="login-container">
          <div className="login-card-width">
            <div>
              <div className="login-header">
              </div>
              <div className="login-form">
                <h2>Log in</h2>
                {from ? <p><em>You need to log in before accessing the application</em></p> : ''}
                <p>You will be redirected to Husqvarna Login for authentication</p>
                <button className="button" onClick={this.login.bind(this)} type="button">LOG IN</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { login, config } = state;

  return {
    isAuthenticated: login.isAuthenticated,
    config: config.item
  };
};

export default connect(mapStateToProps, actions)(Login);
