import { TypeModelWithTexts } from 'domain/models/type-model';

export const nameTextSelector = (
  anyType: TypeModelWithTexts | undefined,
  languageId: string
) => {
  return anyType?.nameTexts?.find(f => f.languageCode === languageId)
    ?.translatedText;
};

export const descriptionTextSelector = (
  anyType: TypeModelWithTexts | undefined,
  languageId: string
) => {
  return anyType?.descriptionTexts?.find(f => f.languageCode === languageId)
    ?.translatedText;
};
