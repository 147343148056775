import { CALL_API } from 'app/middleware/api';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { success } from 'app/general/messages/toast-util';
import {
  USERS_REQ, USERS_REQ_SUCCESS, USERS_REQ_FAILURE,
  USER_DETAILS_REQ, USER_DETAILS_REQ_SUCCESS, USER_DETAILS_REQ_FAILURE,
  UPDATE_USER_REQ, UPDATE_USER_REQ_SUCCESS, UPDATE_USER_REQ_FAILURE
} from 'reducers/action-constants';
import { MarketId } from 'domain/markets/container/markets-reducer';
import { UserId, UserApiModel } from './user-models';

const createGetAllUsersAction = (): AnyAction => {
  return {
    type: 'thunk',
    [CALL_API]: {
      types: [USERS_REQ, USERS_REQ_SUCCESS, USERS_REQ_FAILURE],
      pathname: '/v1/users',
    }
  };
};

/**
 * Action to fetch all users
 */
export const getAllUsers = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetAllUsersAction());
  };
};

const createGetUserDetailsAction = (uid: UserId): AnyAction => {
  return {
    type: 'thunk',
    [CALL_API]: {
      types: [USER_DETAILS_REQ, USER_DETAILS_REQ_SUCCESS, USER_DETAILS_REQ_FAILURE],
      pathname: `/v1/users/${uid}`,
    }
  };
};

/**
 * Action to fetch a user by UID
 */
export const getUserDetails = (uid: UserId): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  // eslint-disable-next-line no-unused-vars
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetUserDetailsAction(uid));
  };
};

const createSaveUserDetailsAction = (uid: UserId, isRecipeAdmin: boolean, isSuperAdmin: boolean, marketId: MarketId): AnyAction => {
  return {
    type: 'thunk',
    [CALL_API]: {
      types: [UPDATE_USER_REQ, UPDATE_USER_REQ_SUCCESS, UPDATE_USER_REQ_FAILURE],
      pathname: `/v1/users/${uid}`,
      options: {
        method: 'PATCH',
        body: JSON.stringify({
          isRecipeAdmin,
          isSuperAdmin,
          marketId
        })
      }
    }
  };
};

/**
 * Action to fetch a user by UID
 */
export const saveUserDetails = (user: UserApiModel): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    const outcome = await dispatch(createSaveUserDetailsAction(user.uid, user.isRecipeAdmin, user.isSuperAdmin, user.marketId));
    if (outcome.type === UPDATE_USER_REQ_SUCCESS) {
      await dispatch(getAllUsers());
      success('User saved');
    }
  };
};
