import React, { PureComponent } from 'react';
import './overview.scss';

class Overview extends PureComponent {
  render () {
    return <div className="lpov">
      <div>
        <div>
          <ul>
            <li><div><i className="fas fa-user-friends" /></div> <span className="badge-label">40 users</span></li>
            <li><div><i className="fas fa-list-alt" /></div> <span className="badge-label">23 recipes</span></li>
            <li><div><i className="fa fa-layer-group" /></div> <span className="badge-label">18 tool families</span></li>
          </ul>
        </div>
        <div>
          Mocked info
        </div>
      </div>
    </div>;
  }
}

export default Overview;
