export const ERROR_RESET = 'ERROR_RESET';
export const SET_ERROR = 'SET_ERROR';

export const SET_FILTER = 'SET_FILTER';

export const GET_CONF_REQ = 'GET_CONF_REQ';
export const GET_CONF_REQ_SUCCESS = 'GET_CONF_REQ_SUCCESS';
export const GET_CONF_REQ_FAILURE = 'GET_CONF_REQ_FAILURE';

export const RECIPES_REQ = 'RECIPES_REQ';
export const RECIPES_REQ_SUCCESS = 'RECIPES_REQ_SUCCESS';
export const RECIPES_REQ_FAILURE = 'RECIPES_REQ_FAILURE';

export const TOOL_REQ = 'TOOL_REQ';
export const TOOL_REQ_SUCCESS = 'TOOL_REQ_SUCCESS';
export const TOOL_REQ_FAILURE = 'TOOL_REQ_FAILURE';

export const SET_RECIPE_STEP_CONFIG_ONGOING = 'SET_RECIPE_STEP_CONFIG_ONGOING';
export const ADD_RECIPE_CONFIG = 'ADD_RECIPE_CONFIG';
export const REMOVE_RECIPE_CONFIG = 'REMOVE_RECIPE_CONFIG';

export const SAVE_RECIPE_CONFIG_REQ = 'SAVE_RECIPE_CONFIG_REQ';
export const SAVE_RECIPE_CONFIG_REQ_SUCCESS = 'SAVE_RECIPE_CONFIG_REQ_SUCCESS';
export const SAVE_RECIPE_CONFIG_REQ_FAILURE = 'SAVE_RECIPE_CONFIG_REQ_FAILURE';

export const GET_RECIPE_REQ = 'GET_RECIPE_REQ';
export const GET_RECIPE_REQ_SUCCESS = 'GET_RECIPE_REQ_SUCCESS';
export const GET_RECIPE_REQ_FAILURE = 'GET_RECIPE_REQ_FAILURE';

export const UPDATE_RECIPE_REQ = 'UPDATE_RECIPE_REQ';
export const UPDATE_RECIPE_REQ_SUCCESS = 'UPDATE_RECIPE_REQ_SUCCESS';
export const UPDATE_RECIPE_REQ_FAILURE = 'UPDATE_RECIPE_REQ_FAILURE';

export const DELETE_RECIPE_REQ = 'DEL_RECIPE_REQ';
export const DELETE_RECIPE_REQ_SUCCESS = 'DEL_RECIPE_REQ_SUCCESS';
export const DELETE_RECIPE_REQ_FAILURE = 'DEL_RECIPE_REQ_FAILURE';

export const CREATE_RECIPE_REQ = 'CREATE_RECIPE_REQ';
export const CREATE_RECIPE_REQ_SUCCESS = 'CREATE_RECIPE_REQ_SUCCESS';
export const CREATE_RECIPE_REQ_FAILURE = 'CREATE_RECIPE_REQ_FAILURE';

export const UPDATE_RECIPE_STEPS_REQ = 'UPDATE_RECIPE_STEPS_REQ';
export const UPDATE_RECIPE_STEPS_REQ_SUCCESS = 'UPDATE_RECIPE_STEPS_REQ_SUCCESS';
export const UPDATE_RECIPE_STEPS_REQ_FAILURE = 'UPDATE_RECIPE_STEPS_REQ_FAILURE';

export const SELECT_TOOLFAMILY = 'SELECT_TOOLFAMILY';
export const GET_TOOLFAMILY_REQ = 'GET_TOOLFAMILY_REQ';
export const GET_TOOLFAMILY_REQ_SUCCESS = 'GET_TOOLFAMILY_REQ_SUCCESS';
export const GET_TOOLFAMILY_REQ_FAILURE = 'GET_TOOLFAMILY_REQ_FAILURE';

export const DELETE_TOOLFAMILY_REQ = 'DELETE_TOOLFAMILY_REQ';
export const DELETE_TOOLFAMILY_REQ_SUCCESS = 'DELETE_TOOLFAMILY_REQ_SUCCESS';
export const DELETE_TOOLFAMILY_REQ_FAILURE = 'DELETE_TOOLFAMILY_REQ_FAILURE';

export const UPDATE_TOOLFAMILY_REQ = 'UPDATE_TOOLFAMILY_REQ';
export const UPDATE_TOOLFAMILY_REQ_SUCCESS = 'UPDATE_TOOLFAMILY_REQ_SUCCESS';
export const UPDATE_TOOLFAMILY_REQ_FAILURE = 'UPDATE_TOOLFAMILY_REQ_FAILURE';

export const CREATE_TOOLFAMILY_REQ = 'CREATE_TOOLFAMILY_REQ';
export const CREATE_TOOLFAMILY_REQ_SUCCESS = 'CREATE_TOOLFAMILY_REQ_SUCCESS';
export const CREATE_TOOLFAMILY_REQ_FAILURE = 'CREATE_TOOLFAMILY_REQ_FAILURE';

export const SORT_TOOLFAMILIES = 'SORT_TOOLFAMILIES';
export const GET_ALL_TOOLFAMILY_REQ = 'GET_ALL_TOOLFAMILY_REQ_1';
export const GET_ALL_TOOLFAMILY_REQ_SUCCESS = 'GET_ALL_TOOLFAMILY_REQ_SUCCESS_1';
export const GET_ALL_TOOLFAMILY_REQ_FAILURE = 'GET_ALL_TOOLFAMILY_REQ_FAILURE_1';

export const GET_FAMILIES_REQ = 'G_FAMILIES_REQ';
export const GET_FAMILIES_REQ_SUCCESS = 'G_FAMILIES_REQ_SUCCESS';
export const GET_FAMILIES_REQ_FAILURE = 'G_FAMILIES_REQ_FAILURE';

export const QUERY_RECIPIES_REQ = 'QUERY_RECIPIES_REQ';
export const QUERY_RECIPIES_REQ_SUCCESS = 'QUERY_RECIPIES_REQ_SUCCESS';
export const QUERY_RECIPIES_REQ_FAILURE = 'QUERY_RECIPIES_REQ_FAILURE';
export const CLEAR_QUERY_RECIPIES_FILTER = 'CLEAR_QUERY_RECIPIES_FILTER';

export const Q_MARKETS_REQ = 'Q_MARKETS_REQ';
export const Q_MARKETS_REQ_SUCCESS = 'Q_MARKETS_REQ_SUCCESS';
export const Q_MARKETS_REQ_FAILURE = 'Q_MARKETS_REQ_FAILURE';
export const Q_MATERIAL_REQ = 'Q_MATERIAL_REQ';
export const Q_MATERIAL_REQ_SUCCESS = 'Q_MATERIAL_REQ_SUCCESS';
export const Q_MATERIAL_REQ_FAILURE = 'Q_MATERIAL_REQ_FAILURE';
export const MATERIAL_CLEAR = 'MATERIAL_CLEAR';

export const Q_FINISH_REQ = 'Q_FINISH_REQ';
export const Q_FINISH_REQ_SUCCESS = 'Q_FINISH_REQ_SUCCESS';
export const Q_FINISH_REQ_FAILURE = 'Q_FINISH_REQ_FAILURE';
export const FINISH_CLEAR = 'FINISH_CLEAR';

export const Q_MACHINE_REQ = 'Q_MACHINE_REQ';
export const Q_MACHINE_REQ_SUCCESS = 'Q_MACHINE_REQ_SUCCESS';
export const Q_MACHINE_REQ_FAILURE = 'Q_MACHINE_REQ_FAILURE';
export const MACHINE_CLEAR = 'MACHINE_CLEAR';

export const Q_TOOLSYSTEM_REQ = 'Q_TOOLSYSTEM_REQ';
export const Q_TOOLSYSTEM_REQ_SUCCESS = 'Q_TOOLSYSTEM_REQ_SUCCESS';
export const Q_TOOLSYSTEM_REQ_FAILURE = 'Q_TOOLSYSTEM_REQ_FAILURE';
export const TOOLSYSTEM_CLEAR = 'TOOLSYSTEM_CLEAR';

export const SELECT_RECIPIES_REQ = 'SEL_RECIPIES_REQ';
export const SELECT_RECIPIES_REQ_SUCCESS = 'SEL_RECIPIES_REQ_SUCCESS';
export const SELECT_RECIPIES_REQ_FAILURE = 'SEL_RECIPIES_REQ_FAILURE';

export const RECIPE_IDS_REQ = 'RECIPE_IDS_REQ';
export const RECIPE_IDS_REQ_SUCCESS = 'RECIPE_IDS_REQ_SUCCESS';
export const RECIPE_IDS_REQ_FAILURE = 'RECIPE_IDS_REQ_FAILURE';

export const COPY_MACHINE_CONF_REQ = 'COPY_MACHINE_CONF_REQ';
export const COPY_MACHINE_CONF_REQ_SUCCESS = 'COPY_MACHINE_CONF_REQ_SUCCESS';
export const COPY_MACHINE_CONF_REQ_FAILURE = 'COPY_MACHINE_CONF_REQ_FAILURE';

export const GET_FAMILY_DETAIL_REQ = 'GET_FAMILY_DETAIL_REQ';
export const GET_FAMILY_DETAIL_REQ_SUCCESS = 'GET_FAMILY_DETAIL_REQ_SUCCESS';
export const GET_FAMILY_DETAIL_REQ_FAILURE = 'GET_FAMILY_DETAIL_REQ_FAILURE';

export const CLEAR_TOOLSEL_CONFIG = 'CLEAR_TOOLSEL_CONF';
export const GET_TOOLSEL_CONFIG_REQ = 'GET_TOOLSEL_CONFIG_REQ';
export const GET_TOOLSEL_CONFIG_REQ_SUCCESS = 'GET_TOOLSEL_CONFIG_REQ_SUCCESS';
export const GET_TOOLSEL_CONFIG_REQ_FAILURE = 'GET_TOOLSEL_CONFIG_REQ_FAILURE';

export const RECIPE_ADD_STEP = 'RECIPE_ADD_STEP';
export const RECIPE_REMOVE_STEP = 'RECIPE_REMOVE_STEP';
export const RECIPE_SET_STEPS = 'RECIPE_SET_STEPS';

export const CLEAR_MACHINE_RECIPE = 'CLEAR_MACHINE_RECIPE';
export const MACHINE_RECIPE_REQ = 'MACHINE_RECIPE_REQ';
export const MACHINE_RECIPE_REQ_SUCCESS = 'MACHINE_RECIPE_REQ_SUCCESS';
export const MACHINE_RECIPE_REQ_FAILURE = 'MACHINE_RECIPE_REQ_FAILURE';

export const RECIPE_SAVE_STEPS_REQ = 'RECIPE_SAVE_STEPS_REQ_REQ';
export const RECIPE_SAVE_STEPS_REQ_SUCCESS = 'RECIPE_SAVE_STEPS_REQ_SUCCESS';
export const RECIPE_SAVE_STEPS_REQ_FAILURE = 'RECIPE_SAVE_STEPS_REQ_FAILURE';

export const MATERIALS_REQ = 'MATERIALS_REQ';
export const MATERIALS_REQ_SUCCESS = 'MATERIALS_REQ_SUCCESS';
export const MATERIALS_REQ_FAILURE = 'MATERIALS_REQ_FAILURE';

export const GET_MATERIAL_TYPES_REQ = 'GET_MATERIAL_TYPES_REQ';
export const GET_MATERIAL_TYPES_REQ_SUCCESS = 'GET_MATERIAL_TYPES_REQ_SUCCESS';
export const GET_MATERIAL_TYPES_REQ_FAILURE = 'GET_MATERIAL_TYPES_REQ_FAILURE';

export const GET_MATERIAL_TYPE_REQ = 'GET_MATERIAL_TYPE_REQ';
export const GET_MATERIAL_TYPE_REQ_SUCCESS = 'GET_MATERIAL_TYPE_REQ_SUCCESS';
export const GET_MATERIAL_TYPE_REQ_FAILURE = 'GET_MATERIAL_TYPE_REQ_FAILURE';

export const MATERIAL_REQ = 'MATERIAL_REQ';
export const MATERIAL_REQ_SUCCESS = 'MATERIAL_REQ_SUCCESS';
export const MATERIAL_REQ_FAILURE = 'MATERIAL_REQ_FAILURE';

export const SAVE_NEW_MATERIAL_REQ = 'SAVE_NEW_MATERIAL_REQ';
export const SAVE_NEW_MATERIAL_REQ_SUCCESS = 'SAVE_NEW_MATERIAL_REQ_SUCCESS';
export const SAVE_NEW_MATERIAL_REQ_FAILURE = 'SAVE_NEW_MATERIAL_REQ_FAILURE';
export const RESET_CREATED_MATERIAL = 'RESET_CREATED_MATERIAL';

export const SAVE_NEW_MATERIAL_TYPE_REQ = 'SAVE_NEW_MATERIAL_TYPE_REQ';
export const SAVE_NEW_MATERIAL_TYPE_REQ_SUCCESS = 'SAVE_NEW_MATERIAL_TYPE_REQ_SUCCESS';
export const SAVE_NEW_MATERIAL_TYPE_REQ_FAILURE = 'SAVE_NEW_MATERIAL_TYPE_REQ_FAILURE';

export const DELETE_MATERIAL_TYPE_REQ = 'DELETE_MATERIAL_TYPE_REQ';
export const DELETE_MATERIAL_TYPE_REQ_SUCCESS = 'DELETE_MATERIAL_TYPE_REQ_SUCCESS';
export const DELETE_MATERIAL_TYPE_REQ_FAILURE = 'DELETE_MATERIAL_TYPE_REQ_FAILURE';

export const GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ = 'GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ';
export const GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_SUCCESS = 'GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_SUCCESS';
export const GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_FAILURE = 'GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_FAILURE';

export const GET_SUGGESTED_MATERIAL_UI_ORDER_REQ = 'GET_SUGGESTED_MATERIAL_UI_ORDER_REQ';
export const GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_SUCCESS = 'GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_SUCCESS';
export const GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_FAILURE = 'GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_FAILURE';

export const SAVE_MATERIAL_TEXTS_REQ = 'SAVE_MATERIAL_TEXTS_REQ';
export const SAVE_MATERIAL_TEXTS_REQ_SUCCESS = 'SAVE_MATERIAL_TEXTS_REQ_SUCCESS';
export const SAVE_MATERIAL_TEXTS_REQ_FAILURE = 'SAVE_MATERIAL_TEXTS_REQ_FAILURE';

export const SAVE_TEXT_ITEMS_REQ = 'SAVE_TEXT_ITEMS_REQ';
export const SAVE_TEXT_ITEMS_REQ_SUCCESS = 'SAVE_TEXT_ITEMS_REQ_SUCCESS';
export const SAVE_TEXT_ITEMS_REQ_FAILURE = 'SAVE_TEXT_ITEMS_REQ_FAILURE';

export const DELETE_MATERIAL_REQ = 'DELETE_MATERIAL_REQ';
export const DELETE_MATERIAL_REQ_SUCCESS = 'DELETE_MATERIAL_REQ_SUCCESS';
export const DELETE_MATERIAL_REQ_FAILURE = 'DELETE_MATERIAL_REQ_FAILURE';

export const FINISHES_REQ = 'FINISHES_REQ';
export const FINISHES_REQ_SUCCESS = 'FINISHES_REQ_SUCCESS';
export const FINISHES_REQ_FAILURE = 'FINISHES_REQ_FAILURE';

export const FINISH_REQ = 'FINISH_REQ';
export const FINISH_REQ_SUCCESS = 'FINISH_REQ_SUCCESS';
export const FINISH_REQ_FAILURE = 'FINISH_REQ_FAILURE';

export const DELETE_FINISH_REQ = 'DELETE_FINISH_REQ';
export const DELETE_FINISH_REQ_SUCCESS = 'DELETE_FINISH_REQ_SUCCESS';
export const DELETE_FINISH_REQ_FAILURE = 'DELETE_FINISH_REQ_FAILURE';

export const SAVE_FINISH_REQ = 'SAVE_FINISH_REQ';
export const SAVE_FINISH_REQ_SUCCESS = 'SAVE_FINISH_REQ_SUCCESS';
export const SAVE_FINISH_REQ_FAILURE = 'SAVE_FINISH_REQ_FAILURE';
export const ATTACH_FINISH_IMAGE_REQ = 'ATTACH_FINISH_IMAGE_REQ';
export const ATTACH_FINISH_IMAGE_REQ_SUCCESS = 'ATTACH_FINISH_IMAGE_REQ_SUCCESS';
export const ATTACH_FINISH_IMAGE_REQ_FAILURE = 'ATTACH_FINISH_IMAGE_REQ_FAILURE';

export const TOOLSYSTEMS_REQ = 'TOOLSYSTEMS_REQ';
export const TOOLSYSTEMS_REQ_SUCCESS = 'TOOLSYSTEMS_REQ_SUCCESS';
export const TOOLSYSTEMS_REQ_FAILURE = 'TOOLSYSTEMS_REQ_FAILURE';

export const TOOLSYSTEM_REQ = 'TOOLSYSTEM_REQ';
export const TOOLSYSTEM_REQ_SUCCESS = 'TOOLSYSTEM_REQ_SUCCESS';
export const TOOLSYSTEM_REQ_FAILURE = 'TOOLSYSTEM_REQ_FAILURE';

export const DELETE_TOOLSYSTEM_REQ = 'DELETE_TOOLSYSTEM_REQ';
export const DELETE_TOOLSYSTEM_REQ_SUCCESS = 'DELETE_TOOLSYSTEM_REQ_SUCCESS';
export const DELETE_TOOLSYSTEM_REQ_FAILURE = 'DELETE_TOOLSYSTEM_REQ_FAILURE';

export const RESET_CREATED_TOOLSYSTEM = 'RESET_CREATED_TOOLSYSTEM';

export const SAVE_NEW_TOOLSYSTEM_REQ = 'SAVE_NEW_TOOLSYSTEM_REQ';
export const SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS = 'SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS';
export const SAVE_NEW_TOOLSYSTEM_REQ_FAILURE = 'SAVE_NEW_TOOLSYSTEM_REQ_FAILURE';

export const UPDATE_TOOLSYSTEM_REQ = 'UPDATE_TOOLSYSTEM_REQ';
export const UPDATE_TOOLSYSTEM_REQ_SUCCESS = 'UPDATE_TOOLSYSTEM_REQ_SUCCESS';
export const UPDATE_TOOLSYSTEM_REQ_FAILURE = 'UPDATE_TOOLSYSTEM_REQ_FAILURE';

export const ATTACH_MATERIAL_IMAGE_REQ = 'ATTACH_MATERIAL_IMAGE_REQ';
export const ATTACH_MATERIAL_IMAGE_REQ_SUCCESS = 'ATTACH_MATERIAL_IMAGE_REQ_SUCCESS';
export const ATTACH_MATERIAL_IMAGE_REQ_FAILURE = 'ATTACH_MATERIAL_IMAGE_REQ_FAILURE';

export const SAVE_NEW_FINISH_REQ = 'SAVE_NEW_FINISH_REQ';
export const SAVE_NEW_FINISH_REQ_SUCCESS = 'SAVE_NEW_FINISH_REQ_SUCCESS';
export const SAVE_NEW_FINISH_REQ_FAILURE = 'SAVE_NEW_FINISH_REQ_FAILURE';
export const RESET_CREATED_FINISH_ID = 'RESET_CREATED_FINISH_ID';

export const GET_SUGGESTED_FINISH_UI_ORDER_REQ = 'GET_SUGGESTED_FINISH_UI_ORDER_REQ';
export const GET_SUGGESTED_FINISH_UI_ORDER_REQ_SUCCESS = 'GET_SUGGESTED_FINISH_UI_ORDER_REQ_SUCCESS';
export const GET_SUGGESTED_FINISH_UI_ORDER_REQ_FAILURE = 'GET_SUGGESTED_FINISH_UI_ORDER_REQ_FAILURE';

export const FINISH_TYPES_REQ = 'FINISH_TYPES_REQ';
export const FINISH_TYPES_REQ_SUCCESS = 'FINISH_TYPES_REQ_SUCCESS';
export const FINISH_TYPES_REQ_FAILURE = 'FINISH_TYPES_REQ_FAILURE';

export const FINISH_TYPE_REQ = 'FINISH_TYPE_REQ';
export const FINISH_TYPE_REQ_SUCCESS = 'FINISH_TYPE_REQ_SUCCESS';
export const FINISH_TYPE_REQ_FAILURE = 'FINISH_TYPE_REQ_FAILURE';

export const SAVE_NEW_FINISH_TYPE_REQ = 'SAVE_NEW_FINISH_TYPE_REQ';
export const SAVE_NEW_FINISH_TYPE_REQ_SUCCESS = 'SAVE_NEW_FINISH_TYPE_REQ_SUCCESS';
export const SAVE_NEW_FINISH_TYPE_REQ_FAILURE = 'SAVE_NEW_FINISH_TYPE_REQ_FAILURE';

export const DELETE_FINISH_TYPE_REQ = 'DELETE_FINISH_TYPE_REQ';
export const DELETE_FINISH_TYPE_REQ_SUCCESS = 'DELETE_FINISH_TYPE_REQ_SUCCESS';
export const DELETE_FINISH_TYPE_REQ_FAILURE = 'DELETE_FINISH_TYPE_REQ_FAILURE';

export const GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ = 'GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ';
export const GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_SUCCESS = 'GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_SUCCESS';
export const GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_FAILURE = 'GET_SUGGESTED_FINISH_TYPE_UI_ORDER_REQ_FAILURE';

export const RESET_SUGGESTED_UI_ORDER = 'RESET_SUGGESTED_UI_ORDER';

export const MACHINES_REQ = 'MACHINES_REQ';
export const MACHINES_REQ_SUCCESS = 'MACHINES_REQ_SUCCESS';
export const MACHINES_REQ_FAILURE = 'MACHINES_REQ_FAILURE';

export const SAVE_MACHINE_REQ = 'SAVE_MACHINE_REQ';
export const SAVE_MACHINE_REQ_SUCCESS = 'SAVE_MACHINE_REQ_SUCCESS';
export const SAVE_MACHINE_REQ_FAILURE = 'SAVE_MACHINE_REQ_FAILURE';
export const RESET_SAVED_MACHINE_MODEL_ID = 'RESET_SAVED_MACHINE_MODEL_ID';

export const MACHINE_TYPE_REQ = 'MACHINE_TYPE_REQ';
export const MACHINE_TYPE_REQ_SUCCESS = 'MACHINE_TYPE_REQ_SUCCESS';
export const MACHINE_TYPE_REQ_FAILURE = 'MACHINE_TYPE_REQ_FAILURE';

export const SAVE_NEW_MACHINE_TYPE_REQ = 'SAVE_NEW_MACHINE_TYPE_REQ';
export const SAVE_NEW_MACHINE_TYPE_REQ_SUCCESS = 'SAVE_NEW_MACHINE_TYPE_REQ_SUCCESS';
export const SAVE_NEW_MACHINE_TYPE_REQ_FAILURE = 'SAVE_NEW_MACHINE_TYPE_REQ_FAILURE';

export const DELETE_MACHINE_TYPE_REQ = 'DELETE_MACHINE_TYPE_REQ';
export const DELETE_MACHINE_TYPE_REQ_SUCCESS = 'DELETE_MACHINE_TYPE_REQ_SUCCESS';
export const DELETE_MACHINE_TYPE_REQ_FAILURE = 'DELETE_MACHINE_TYPE_REQ_FAILURE';

export const MACHINE_TYPES_REQ = 'MACHINE_TYPES_REQ';
export const MACHINE_TYPES_REQ_SUCCESS = 'MACHINE_TYPES_REQ_SUCCESS';
export const MACHINE_TYPES_REQ_FAILURE = 'MACHINE_TYPES_REQ_FAILURE';

export const DELETE_MACHINE_MODEL_REQ = 'DELETE_MACHINE_MODEL_REQ';
export const DELETE_MACHINE_MODEL_REQ_SUCCESS = 'DELETE_MACHINE_MODEL_REQ_SUCCESS';
export const DELETE_MACHINE_MODEL_REQ_FAILURE = 'DELETE_MACHINE_MODEL_REQ_FAILURE';

export const UPDATE_MACHINE_MODEL_REQ = 'UPD_MACHINE_MODEL_REQ';
export const UPDATE_MACHINE_MODEL_REQ_SUCCESS = 'UPD_MACHINE_MODEL_REQ_SUCCESS';
export const UPDATE_MACHINE_MODEL_REQ_FAILURE = 'UPD_MACHINE_MODEL_REQ_FAILURE';

export const MODELS_REQ = 'MODELS_REQ';
export const MODELS_REQ_SUCCESS = 'MODELS_REQ_SUCCESS';
export const MODELS_REQ_FAILURE = 'MODELS_REQ_FAILURE';

export const MODELS_CONFIG_REQ = 'MODELS_CONFIG_REQ';
export const MODELS_CONFIG_REQ_SUCCESS = 'MODELS_CONFIG_REQ_SUCCESS';
export const MODELS_CONFIG_REQ_FAILURE = 'MODELS_CONFIG_REQ_FAILURE';

export const SAVE_MODEL_CONFIGURATION_REQ = 'SAVE_MODEL_CONFIGURATION_REQ';
export const SAVE_MODEL_CONFIGURATION_REQ_SUCCESS = 'SAVE_MODEL_CONFIGURATION_REQ_SUCCESS';
export const SAVE_MODEL_CONFIGURATION_REQ_FAILURE = 'SAVE_MODEL_CONFIGURATION_REQ_FAILURE';

export const SELECT_MODEL = 'SELECT_MODEL';
export const SELECT_MATERIAL = 'SELECT_MATERIAL';

export const DIMR_TOOL_REQ = 'DIMR_TOOL_REQ';
export const DIMR_TOOL_REQ_SUCCESS = 'DIMR_TOOL_REQ_SUCCESS';
export const DIMR_TOOL_REQ_FAILURE = 'DIMR_TOOL_REQ_FAILURE';

export const SELECT_MACHINE = 'SELECT_MACHINE';
export const DIMR_MACHINE_REQ = 'DIMR_MACHINE_REQ';
export const DIMR_MACHINE_REQ_SUCCESS = 'DIMR_MACHINE_REQ_SUCCESS';
export const DIMR_MACHINE_REQ_FAILURE = 'DIMR_MACHINE_REQ_FAILURE';

export const SEARCH_TOOL_REQ = 'SEARCH_TOOL_REQ';
export const SEARCH_TOOL_REQ_SUCCESS = 'SEARCH_TOOL_REQ_SUCCESS';
export const SEARCH_TOOL_REQ_FAILURE = 'SEARCH_TOOL_REQ_FAILURE';

export const CLEAR_SEARCH_TOOL_RES = 'CLEAR_SEARCH_TOOL_RES';

export const TOOL_LIFETIME_REQ = 'TOOL_LIFETIME_REQ';
export const TOOL_LIFETIME_REQ_SUCCESS = 'TOOL_LIFETIME_REQ_SUCCESS';
export const TOOL_LIFETIME_REQ_FAILURE = 'TOOL_LIFETIME_REQ_FAILURE';

export const PEEK_LIFETIMES_REQ = 'PEEK_LIFETIMES_REQ';
export const PEEK_LIFETIMES_REQ_SUCCESS = 'PEEK_LIFETIMES_REQ_SUCCESS';
export const PEEK_LIFETIMES_REQ_FAILURE = 'PEEK_LIFETIMES_REQ_FAILURE';

export const SAVE_TOOL_LIFETIME_REQ = 'SAVE_TOOL_LIFETIME_REQ';
export const SAVE_TOOL_LIFETIME_REQ_SUCCESS = 'SAVE_TOOL_LIFETIME_REQ_SUCCESS';
export const SAVE_TOOL_LIFETIME_REQ_FAILURE = 'SAVE_TOOL_LIFETIME_REQ_FAILURE';

export const SAVE_CHEM_LIFETIME_REQ = 'SAVE_CHEM_LIFETIME_REQ';
export const SAVE_CHEM_LIFETIME_REQ_SUCCESS = 'SAVE_CHEM_LIFETIME_REQ_SUCCESS';
export const SAVE_CHEM_LIFETIME_REQ_FAILURE = 'SAVE_CHEM_LIFETIME_REQ_FAILURE';

export const LOOKUP_DIMR_TOOL_REQ = 'LOOKUP_DIMR_TOOL_REQ';
export const LOOKUP_DIMR_TOOL_REQ_SUCCESS = 'LOOKUP_DIMR_TOOL_REQ_SUCCESS';
export const LOOKUP_DIMR_TOOL_REQ_FAILURE = 'LOOKUP_DIMR_TOOL_REQ_FAILURE';

export const LOOKUP_DIMR_CHEM_REQ = 'LOOKUP_DIMR_CHEM_REQ';
export const LOOKUP_DIMR_CHEM_REQ_SUCCESS = 'LOOKUP_DIMR_CHEM_REQ_SUCCESS';
export const LOOKUP_DIMR_CHEM_REQ_FAILURE = 'LOOKUP_DIMR_CHEM_REQ_FAILURE';

export const CHEM_LIFEAREA_ENTERED = 'CHEM_LIFEAREA_ENTERED';
export const TOOL_LIFETIME_ENTERED = 'TOOL_LIFETIME_ENTERED';
export const TOOL_RANK_ENTERED = 'TOOL_RANK_ENTERED';
export const TOOL_SELECTED = 'TOOL_SELECTED';
export const REMOVE_TOOL_SELECTION = 'REMOVE_TOOL_SELECTION';

export const AUTHORIZE_CODE_REQ = 'AUTH_CODE_REQ';
export const AUTHORIZE_CODE_REQ_SUCCESS = 'AUTH_CODE_REQ_SUCCESS';
export const AUTHORIZE_CODE_REQ_FAILURE = 'AUTH_CODE_REQ_FAILURE';
export const USERS_REQ = 'USERS_REQ';
export const USERS_REQ_SUCCESS = 'USERS_REQ_SUCCESS';
export const USERS_REQ_FAILURE = 'USERS_REQ_FAILURE';
export const USER_DETAILS_REQ = 'USER_DETAILS_REQ';
export const USER_DETAILS_REQ_SUCCESS = 'USER_DETAILS_REQ_SUCCESS';
export const USER_DETAILS_REQ_FAILURE = 'USER_DETAILS_REQ_FAILURE';
export const UPDATE_USER_REQ = 'UPDATE_USER_REQ';
export const UPDATE_USER_REQ_SUCCESS = 'UPDATE_USER_REQ_SUCCESS';
export const UPDATE_USER_REQ_FAILURE = 'UPDATE_USER_REQ_FAILURE';

export const AUTENTICATED_USER_REQ = 'AUTENTICATED_USER_REQ';
export const AUTENTICATED_USER_REQ_SUCCESS = 'AUTENTICATED_USER_REQ_SUCCESS';
export const AUTENTICATED_USER_REQ_FAILURE = 'AUTENTICATED_USER_REQ_FAILURE';

export const GET_UPLOADURL_REQ = 'GET_UPLOADURL_REQ';
export const GET_UPLOADURL_REQ_SUCCESS = 'GET_UPLOADURL_REQ_SUCCESS';
export const GET_UPLOADURL_REQ_FAILURE = 'GET_UPLOADURL_REQ_FAILURE';

export const STAT_IP_REQ = 'STAT_IP_REQ_REQ';
export const STAT_IP_REQ_SUCCESS = 'STAT_IP_REQ_REQ_SUCCESS';
export const STAT_IP_REQ_FAILURE = 'STAT_IP_REQ_REQ_FAILURE';

export const SAVE_GEO_REQ = 'SAVE_GEO_REQ';
export const SAVE_GEO_REQ_SUCCESS = 'SAVE_GEO_REQ_SUCCESS';
export const SAVE_GEO_REQ_FAILURE = 'SAVE_GEO_REQ_FAILURE';

export const LOGOUT = 'LOGOUT';
