import { toast } from 'react-toastify';

const successOpts = {
  autoClose: 6000,
  className: 'tos-success',
  bodyClassName: 'tos-message-body'
};

export const success = (message: string) => {
  toast.success(message, successOpts);
};

const errorOpts = {
  autoClose: 10000,
  className: 'tos-error',
  bodyClassName: 'tos-message-body'
};

export const error = (message: string) => {
  toast.error(message, errorOpts);
};

const infoOpts = {
  autoClose: 8000,
  className: 'tos-info',
  bodyClassName: 'tos-message-body'
};

export const info = (message: string) => {
  toast.error(message, infoOpts);
};
