import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import RoutingInternal from 'app/routing';
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import api from 'app/middleware/api';
import reducer from './reducers';
import * as serviceWorker from './serviceWorker';
import './style/base.scss';

const { createLogger } = require('redux-logger');

function configureStore () {
  if (process.env.NODE_ENV === 'development') {
    const composedEnhancers = composeWithDevTools(applyMiddleware(thunk, api, createLogger({ collapsed: true })));

    return createStore(reducer, {}, composedEnhancers);
  }

  return createStore(
    reducer,
    applyMiddleware(thunk, api, createLogger({ collapsed: true }))
  );
}
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <RoutingInternal />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
