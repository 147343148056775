import { Reducer } from 'redux';
import { asSelectables } from 'app/form/utils';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import * as actionConstants from 'reducers/action-constants';
import { TypeModelWithTexts } from 'domain/models/type-model';

interface ContentMaterialsStore {
  isLoadingMaterialTypes: boolean;
  isSavingNewMaterialType: boolean;
  selectables?: Array<UISelectableModel>;
  createdMaterialType?: any;
  suggestedUIOrder?: number;
  isLoadingSuggestedUIOrder: boolean;
  isLoadingMaterialType: boolean;
  loadedMaterialType?: TypeModelWithTexts;
  isSavingTextItems: boolean;
}

const getInitialState = () => ({
  isLoadingMaterialTypes: false,
  isSavingNewMaterialType: false,
  isLoadingMaterialType: false,
  isSavingTextItems: false,
  isLoadingSuggestedUIOrder: false
});

const reducer: Reducer<ContentMaterialsStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case actionConstants.GET_MATERIAL_TYPE_REQ:
      return {
        ...state,
        isLoadingMaterialType: true,
        loadedMaterialType: undefined,
      };

    case actionConstants.GET_MATERIAL_TYPE_REQ_SUCCESS:
      return {
        ...state,
        isLoadingMaterialType: false,
        loadedMaterialType: action.response,
      };

    case actionConstants.GET_MATERIAL_TYPE_REQ_FAILURE:
      return {
        ...state,
        isLoadingMaterialType: false,
      };

    case actionConstants.GET_MATERIAL_TYPES_REQ:
      return {
        ...state,
        isLoadingMaterialTypes: true,
      };

    case actionConstants.GET_MATERIAL_TYPES_REQ_SUCCESS:
      return {
        ...state,
        isLoadingMaterialTypes: false,
        selectables: asSelectables(action.response, 'translatedName', 'id'),
      };

    case actionConstants.GET_MATERIAL_TYPES_REQ_FAILURE:
      return {
        ...state,
        isLoadingMaterialTypes: false,
      };

    case actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ:
      return {
        ...state,
        isSavingNewMaterialType: true,
      };

    case actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ_SUCCESS:
      return {
        ...state,
        isSavingNewMaterialType: false,
        createdMaterialType: action.response,
      };

    case actionConstants.SAVE_NEW_MATERIAL_TYPE_REQ_FAILURE:
      return {
        ...state,
        isSavingNewMaterialType: false,
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ:
      return {
        ...state,
        isLoadingSuggestedUIOrder: true
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_SUCCESS:
      return {
        ...state,
        suggestedUIOrder: action.response?.suggestedUIOrder,
        isLoadingSuggestedUIOrder: false
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_TYPE_UI_ORDER_REQ_FAILURE:
      return {
        ...state,
        suggestedUIOrder: undefined,
        isLoadingSuggestedUIOrder: false
      };

    default:
      return state;
  }
};

export default reducer;
