import { CALL_API } from 'app/middleware/api';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ToolSelectionId } from 'domain/models/tool-selection-model';
import * as actionConstants from 'reducers/action-constants';

const createGetToolAction = (id: ToolSelectionId): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.TOOL_REQ,
      actionConstants.TOOL_REQ_SUCCESS,
      actionConstants.TOOL_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/tools/${id}`
  }
});

/**
 * Requests all tool families
 */
export const getTool = (id: ToolSelectionId): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(createGetToolAction(id));
  };
};
