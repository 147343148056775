import React, { FC } from 'react';
import './create-recipe.scss';

interface Props {
  onCreateClicked: () => void
}

const CreateRecipe: FC<Props> = ({ onCreateClicked }) => {
  return <div className="rd-create-cp">
    <div><button className="primary large" onClick={onCreateClicked} type="button">Create recipe</button></div>
  </div>;
};

export default CreateRecipe;
