import React, { FC, useCallback, useState, ChangeEvent } from 'react';
import Input from 'app/form/labeled-input/input';

import ModalSample from 'app/style/modal-sample';
import './style-thingies.scss';

const AddStepDialog: FC = () => {
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-console
    console.log(`Incoming: ${e.target.name}=${e.target.value}`);
  }, []);

  const [sampleValue, setSampleValue] = useState('Put it out');

  return <div className="style-c">
    <div className="page-title">Sample components</div>

    <div className="cols">
      <div>
        <div className="card-title">Inputs</div>
        <div>
          <Input
            tabIndex={-10}
            label="Small"
            name="sample1"
            width="short"
            onValueChanged={onChange} />
        </div>
        <div>
          <Input
            tabIndex={-9}
            label="No width"
            name="sample2"
            onValueChanged={onChange} />
        </div>
        <div>
          <Input
            tabIndex={-7}
            label="Small"
            name="sample6"
            width="short"
            onValueChanged={onChange} />
        </div>
        <div className="cols">
          <div>
            <Input
              tabIndex={-6}
              label="Where's the fire?"
              width="wide"
              value={sampleValue}
              name="sample3"
              onChange={(e) => setSampleValue(e.target.value)}
              onValueChanged={(e) => setSampleValue(e.target.value)} />

          </div>
          <div><a role="button" onClick={() => setSampleValue('')}>Clear<i className="fas fa-fire-extinguisher"></i></a></div>
        </div>
        <div>
          <Input
            tabIndex={-5}
            label="Number input"
            name="sample4number"
            type="number"
            width="short"
            onValueChanged={onChange} />
        </div>
        <div>
          <Input
            tabIndex={-4}
            label="Disabled"
            width="short"
            disabled
            value="Predefined"
            name="sample8"
            onValueChanged={onChange} />
        </div>
      </div>
      <div>
        <div className="card">
          <div className="card-title">Modal</div>
          <div>
            <ModalSample />
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default AddStepDialog;
