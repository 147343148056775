import { CALL_API } from 'app/middleware/api';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as action from 'reducers/action-constants';

const createGetSessionIpsAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.STAT_IP_REQ,
      action.STAT_IP_REQ_SUCCESS,
      action.STAT_IP_REQ_FAILURE
    ],
    pathname: '/v1/admin/ip'
  }
});

/**
 * Requests all tool families
 */
export const getSessionIps = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetSessionIpsAction());
  };
};

const createPostGeoLookupAction = (sessionIds: string[], lookups: any[]): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      action.SAVE_GEO_REQ,
      action.SAVE_GEO_REQ_SUCCESS,
      action.SAVE_GEO_REQ_FAILURE
    ],
    pathname: '/v1/admin/ip/geo-lookups',
    options: {
      method: 'POST',
      body: JSON.stringify({
        sessionIds,
        lookups
      }),
    },
  }
});

/**
 * Requests all tool families
 */
export const postGeoLookup = (sessionIds: string[], lookups: any[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createPostGeoLookupAction(sessionIds, lookups));
  };
};
