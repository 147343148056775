import React from 'react';
import cls from 'classnames';
import { EditingModelConfigModel } from 'domain/models/machine-type-model';

const headerMappings = [
  { name: 'pucks', text: 'Resinholder pucks' },
  { name: 'hiperflex', text: 'Resinholder hiperflex' },
  { name: 'redilock', text: 'Redilock' },
  { name: 'magnetic', text: 'Magnetic mount' },
  { name: 'ezchange', text: 'EZ change' },
  { name: 'ezchangeclick', text: 'EZ change click' },
];
const toolQuantities = ['1', '3', '4', '6', '8', '9', '10', '12', '15', '18', '24'];

export interface Props {
  configurations: Array<EditingModelConfigModel>;
  onClickHandler(connectorTypeId: string, toolQuantity: string): void;
}

const ConfigTable: React.FC<Props> = ({ configurations, onClickHandler }) => {
  return (
    <div className="config-table uppercase">
      {/* Header */}
      <span className="qty header-row">Qty</span>

      {headerMappings.map(header => (
        <span className="header-row" key={header.name}>
          {header.text}
        </span>
      ))}

      {/* Body */}
      {toolQuantities.map(quantity => (
        <React.Fragment key={quantity}>
          <span className="qty">{quantity}</span>

          {headerMappings.map(header => {
            const hasConnectorType = configurations
              .filter(s => s.toolQuantity === quantity)
              .find(s => s.connectorTypeId === header.name);

            return (
              <span
                className={cls('config', {
                  'has-recipe': hasConnectorType?.hasRecipe
                })}
                key={`${quantity}-${header.name}`}
                onClick={() => !hasConnectorType?.hasRecipe && onClickHandler(header.name, quantity)}

              >
                <div></div>
                <div>
                  {hasConnectorType && <i className="fas fa-check"></i>}
                </div>
                <div>
                  {hasConnectorType?.hasRecipe && <i className="fas fa-lock" />}
                </div>
              </span>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ConfigTable;
