import * as React from 'react';
import { useSelector } from 'reducers/index';
import { NavLink, Link } from 'react-router-dom';
import tosLogo from '../../style/images/diamond.svg';
import hqvLogo from '../../style/images/husqvarna_logo.svg';
import './menu.scss';

interface Props {
}

const Menu: React.FC<Props> = () => {
  const isUserAdmin = useSelector(state => {
    const u = state.login.authenticatedUser;
    return u && u.isSuperAdmin;
  });
  return (
    <div className="tmen">
      <div className="icon">
        <Link to="/"><img alt="Tool Selector" src={tosLogo} /></Link>
      </div>

      <div className="tos"><Link to="/">Tool Selector</Link></div>

      <nav>
        <NavLink to="/recipes" activeClassName="act">Recipes</NavLink>
        <NavLink to="/tool-families" activeClassName="act">Tool Families</NavLink>
        <NavLink to="/content" activeClassName="act">Content</NavLink>
        { isUserAdmin && <NavLink to="/users" activeClassName="act">Users</NavLink> }
        <NavLink to="/logout">Logout</NavLink>
      </nav>

      <div className="hqv-icon">
        <img alt="Husqvarna" src={hqvLogo} />
      </div>
    </div>
  );
};

export default Menu;
