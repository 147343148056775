import {
  MATERIALS_REQ, MATERIALS_REQ_SUCCESS, MATERIALS_REQ_FAILURE
} from 'reducers/action-constants';
import { MaterialTypeModel } from 'domain/models/material-type-model';
import { UISelectableModel } from 'domain/models/ui-selectable-model';

import { typesAsSelectables } from 'app/form/utils';

export interface MaterialsStore {
  isFetching: boolean
  isFetched: boolean
  items?: Array<MaterialTypeModel>
  selectables?: Array<UISelectableModel>
}

const getInitialState = () => {
  return {
    isFetched: false,
    isFetching: false
  };
};

export default (state = getInitialState(), action: any): MaterialsStore => {
  switch (action.type) {
    case MATERIALS_REQ:
      return {
        ...state,
        isFetching: true
      };
    case MATERIALS_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.response,
        selectables: typesAsSelectables('materials')(action.response)
      };
    case MATERIALS_REQ_FAILURE:
      return getInitialState();
    default:
      return state;
  }
};
