import * as React from 'react';
import SelectableItem from 'domain/recipe-picker/components/select-one/item/selectable-item';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import { asSelectables } from 'app/form/utils';
import './select-one.scss';

export interface SelectOneProps {
  title: string
  propName: string
  itemsListLabelPropName: any
  itemsListValuePropName: string
  onSelected: (propName: string, item: UISelectableModel) => void
  items?: Array<any>
  selectedValue?: any
  isAvailable?: (id: string) => boolean
}

const SelectOne: React.FC<SelectOneProps> = ({
  title,
  items,
  itemsListLabelPropName,
  itemsListValuePropName,
  propName,
  onSelected,
  selectedValue,
  isAvailable
}) => {
  const selectables = items ? asSelectables(items, itemsListLabelPropName, itemsListValuePropName) : [];
  return <div className="selectables-comp">
    <div>{title}</div>
    <div>
      <div className="list">
        { selectables && selectables.map(s => {
          const available = isAvailable ? isAvailable(s.value) : true;
          // @ts-ignore
          const isSelected = selectedValue === s.value;
          return <SelectableItem
            key={s.value}
            selectable={s}
            isSelected={isSelected}
            isAvailable={available}
            onClick={(selected: UISelectableModel) => { onSelected(propName, selected); }} />;
        })}
      </div>
    </div>
  </div>;
};
export default SelectOne;
