import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps } from 'react-router';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  isAuthenticated: any
}
type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

class PrivateRoute extends Route<PrivateRouteProps> {
  render () {
    const { component: Component, ...rest }: PrivateRouteProps = this.props;

    const renderComponent: RenderComponent = (props) => (
      this.props.isAuthenticated
        ? <Component {...props} />
        : <Redirect to="/login" />
    );

    return (
      <Route {...rest} render={renderComponent} />
    );
  }
}

const mapStateToProps = (state: any) => {
  const { isAuthenticated } = state.login;
  return {
    isAuthenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);
