import { ToolSystemId } from 'domain/models/tool-system-model';
import { AnyAction } from 'redux';
import { CALL_API } from 'app/middleware/api';
import * as actionConstants from 'reducers/action-constants';
import * as toastUtils from 'app/general/messages/toast-util';

/**
 * Get toolSystems
 */
export const createGetToolSystemsActions = () => ({
  [CALL_API]: {
    types: [
      actionConstants.TOOLSYSTEMS_REQ,
      actionConstants.TOOLSYSTEMS_REQ_SUCCESS,
      actionConstants.TOOLSYSTEMS_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/tool-systems',
    options: {
      method: 'GET',
    },
  },
});

export const getToolSystems = () => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetToolSystemsActions());
};

/**
 * Get toolSystem
 */
const createGetToolSystemActions = (toolSystemId: string | ToolSystemId): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.TOOLSYSTEM_REQ,
      actionConstants.TOOLSYSTEM_REQ_SUCCESS,
      actionConstants.TOOLSYSTEM_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/tool-systems/${toolSystemId}`,
    options: {
      method: 'GET',
    },
  },
});

export const getToolSystem = (toolSystemId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createGetToolSystemActions(toolSystemId));
};

/**
 * Save new toolSystem
 */
const createSaveNewToolSystemAction = (
  name: string,
  uiOrder: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_NEW_TOOLSYSTEM_REQ,
      actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS,
      actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_FAILURE,
    ],
    pathname: '/v1/surface-prep/admin/tool-systems',
    options: {
      method: 'POST',
      body: JSON.stringify({
        name,
        uiOrder,
      }),
    },
  },
});

export const saveNewToolSystem = (
  name: string,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createSaveNewToolSystemAction(name, uiOrder)
  ).then(resp => {
    if (resp.type === actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS) {
      toastUtils.success('New toolSystem successfully saved');
    }

    return dispatch(createResetCreatedToolSystem()).then(() => {
      return dispatch(createGetToolSystemsActions());
    });
  });
};

/**
 * Update edited toolSystem
 */
const createUpdateToolSystemAction = (
  toolSystemId: ToolSystemId,
  name: string,
  uiOrder: string
) => ({
  [CALL_API]: {
    types: [
      actionConstants.UPDATE_TOOLSYSTEM_REQ,
      actionConstants.UPDATE_TOOLSYSTEM_REQ_SUCCESS,
      actionConstants.UPDATE_TOOLSYSTEM_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/tool-systems/${toolSystemId}`,
    options: {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        uiOrder,
      }),
    },
  },
});

export const updateToolSystem = (
  toolSystemId: ToolSystemId,
  name: string,
  uiOrder: string
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(
    createUpdateToolSystemAction(toolSystemId, name, uiOrder)
  ).then(resp => {
    if (resp.type === actionConstants.SAVE_NEW_TOOLSYSTEM_REQ_SUCCESS) {
      toastUtils.success('Toolsystem successfully updated');
    }

    return dispatch(createGetToolSystemsActions());
  });
};

const createResetCreatedToolSystem = () => ({
  type: actionConstants.RESET_CREATED_TOOLSYSTEM,
});

/**
 * Delete toolSystem
 */
const createDeleteToolSystemAction = (toolSystemId: string) => ({
  [CALL_API]: {
    types: [
      actionConstants.DELETE_TOOLSYSTEM_REQ,
      actionConstants.DELETE_TOOLSYSTEM_REQ_SUCCESS,
      actionConstants.DELETE_TOOLSYSTEM_REQ_FAILURE,
    ],
    pathname: `/v1/surface-prep/admin/tool-systems/${toolSystemId}`,
    options: {
      method: 'DELETE',
    },
    context: {
      toolSystemId,
    },
  },
});

export const deleteToolSystem = (toolSystemId: string) => (
  dispatch: (action: any) => Promise<AnyAction>
) => {
  return dispatch(createDeleteToolSystemAction(toolSystemId)).then(e => {
    if (e.type === actionConstants.DELETE_TOOLSYSTEM_REQ_SUCCESS) {
      toastUtils.success('ToolSystem successfully deleted');
    }
    return dispatch(createGetToolSystemsActions());
  });
};
