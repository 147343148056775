import { CALL_API } from 'app/middleware/api';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import {
  GET_CONF_REQ, GET_CONF_REQ_SUCCESS, GET_CONF_REQ_FAILURE
} from 'reducers/action-constants';
import { TosRootState } from 'reducers';

const createGetConfAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [GET_CONF_REQ, GET_CONF_REQ_SUCCESS, GET_CONF_REQ_FAILURE],
    pathname: '/v1/config',
    options: {
      noSession: true
    }
  }
});

/**
 * Requests all tool families
 */
export const getConfig = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const state: TosRootState = getState();
    if (state.config.isFetched) {
      return undefined;
    }
    return dispatch(createGetConfAction());
  };
};
