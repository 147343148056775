import React, { useCallback, useEffect, useState } from 'react';
import Wait from 'app/wait/wait';
import {
  EditingModelConfigModel,
  MachineModelModel,
  EditableMachineModelModel,
  MachineType,
} from 'domain/models/machine-type-model';
import ModelInfoForm from 'domain/content/components/machines/form/model-info';
import { ToolSystemModel } from 'domain/models/tool-system-model';
import List, { Header } from 'app/list/list';
import ArticleNo from '../article-no/articleno';
import Configurations from '../configurations/configurations';
import StepData from '../step-data/stepdata';
import './machineinformation.scss';

const toolSystemHeaders: Array<Header> = [
  {
    text: 'Default Tool System',
    name: 'toolSystemId',
  },
];
const machineModelTypeHeaders: Array<Header> = [
  {
    text: 'Machine Type',
    name: 'machineModelTypeId',
  },
];
export interface Props {
  configs: Array<EditingModelConfigModel>;
  disableArticleNoInput: boolean;
  isFetchingDimrMachine: boolean;
  machineModel?: MachineModelModel;
  machineTypes?: MachineType[];
  machineModelTypeId?: string;
  toolSystems?: ToolSystemModel[];
  toolSystemId?: string;
  onArticleNoInput(articleNo: string): void;
  onEditableModelChanged(modelSubset: Partial<EditableMachineModelModel>): void;
  toggleConfig(connectorTypeId: string, toolQuantity: string): void;
}

const MachineInformation: React.FC<Props> = ({
  machineModel,
  configs,
  machineTypes,
  machineModelTypeId,
  toolSystems,
  toolSystemId,
  toggleConfig,
  disableArticleNoInput,
  isFetchingDimrMachine,
  onArticleNoInput,
  onEditableModelChanged,
}) => {
  const [articleNo, setArticleNo] = useState<string>(
    machineModel?.productNumber || ''
  );

  const handleArticleNo = useCallback(
    e => {
      setArticleNo(e.target.value);
      if (e.target.value.length === 9) {
        onArticleNoInput(e.target.value);
      }
    },
    [setArticleNo, onArticleNoInput]
  );

  // Update article number when switching machineModel
  useEffect(() => {
    setArticleNo(machineModel?.productNumber || '');
  }, [machineModel]);

  const toolSystemSelectables = useCallback(() => ({
    toolSystemId: toolSystems?.map(toolSystem => ({
      id: toolSystem.id,
      name: toolSystem.name,
    })),
  }), [toolSystems]);

  const machineModelTypeSelectables = useCallback(() => ({
    machineModelTypeId: machineTypes?.map(machineType => ({
      id: machineType.id,
      name: machineType.name,
    })),
  }), [machineTypes]);

  return (
    <div className="machineinformation">
      <div className="page-title">Machine information</div>
      <div className="machine-content-wrapper">
        <ArticleNo
          value={articleNo}
          onChange={handleArticleNo}
          disableInput={disableArticleNoInput}
        />

        {isFetchingDimrMachine ? (
          <Wait />
        ) : (
          machineModel && (
            <>
              <div className="cols">
                <div className="col">
                  <ModelInfoForm
                    onEditableModelChanged={onEditableModelChanged}
                    machineModel={machineModel}
                  />
                  <List
                    path="content"
                    selectedType="toolSystemId"
                    selectedId={toolSystemId}
                    headers={toolSystemHeaders}
                    lists={toolSystemSelectables()}
                    onRowClick={(id) => onEditableModelChanged({ toolSystemId: id })}
                    isItemsDeletable={false}
                    listItemsUppercase
                  />
                </div>
                <div className="col">
                  <List
                    path="content"
                    selectedType="machineModelTypeId"
                    selectedId={machineModelTypeId}
                    headers={machineModelTypeHeaders}
                    lists={machineModelTypeSelectables()}
                    onRowClick={(id) => onEditableModelChanged({ machineModelTypeId: id })}
                    isItemsDeletable={false}
                    listItemsUppercase
                  />
                  <StepData
                    modelName={machineModel.name}
                    discDiameter={machineModel.diameter}
                    discs={machineModel.discs}
                  />
                </div>
              </div>
              <div className="cols">
                <div className="col">
                  <Configurations
                    configurations={configs}
                    onConnectionTypeClick={toggleConfig}
                  />
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default MachineInformation;
