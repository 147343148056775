import React from 'react';
import Input from 'app/form/input/input';

type Props = {
  market: any,
  isChecked: boolean,
  handleToggle: (marketId: string) => void
};

const MarketAvailabilityRow: React.FC<Props> = (props: Props) => {
  const marketId = props.market.id;

  return <div className="list-row">
    <div className="name">{props.market.name}</div>
    <div className="action">
      <Input type="checkbox" name={marketId} checked={props.isChecked} onChange={() => props.handleToggle(marketId)} />
    </div>
  </div>;
};

export default MarketAvailabilityRow;
