import React, { FC, useCallback, useState, useEffect } from 'react';
import FileDropzone from 'app/file-upload/dropzone';
import { useSelector } from 'reducers/index';
import { useDispatch } from 'react-redux';
import { getUploadUrl } from 'domain/content/container/actions';
import { uploadFile } from 'app/file-upload/upload-util';
import './image-area.scss';

interface Props {
  imageUrl?: string
  imageSize?: number
  onFileUploaded: (imageId: string, file: File) => void
}

const FileArea: FC<Props> = ({
  onFileUploaded,
  imageUrl,
  imageSize
}) => {
  const dispatch = useDispatch();
  const [uploadState, setUploadState] = useState<{urlPending?: boolean, uploadInProgress?: number, file?: File, done?: boolean, submitting?: boolean}>({});

  const uploadUrl = useSelector(state => state.imageUrl.item?.url);
  const imageId = useSelector(state => state.imageUrl.item?.id);

  useEffect(() => {
    if (uploadState.urlPending && uploadUrl && !uploadState.uploadInProgress) {
      const uploadFunction = async (endpoint: string, file?: File) => {
        if (!file || !imageId) {
          throw new Error(`Illegal state, no file or image upload ref: ${imageId}`);
        }
        const results = await uploadFile(file, endpoint, file.type, () => {});
        setUploadState({
          ...uploadState,
          done: true,
          uploadInProgress: 100,
        });
        onFileUploaded(imageId, file);
        return results;
      };

      setUploadState({
        ...uploadState,
        urlPending: false,
        uploadInProgress: 1,
      });
      uploadFunction(uploadUrl, uploadState.file);
    }
  }, [uploadState, uploadUrl, onFileUploaded, imageId]);

  const handleFilesDropped = useCallback((files: File[]) => {
    if (files.length === 1) {
      dispatch(getUploadUrl());
      setUploadState({
        file: files[0],
        uploadInProgress: 0,
        urlPending: true
      });
    }

  }, [dispatch]);

  const sizeWarning = () => {
    if (imageSize && imageSize > 400000) {
      return <p>
        <i className="fas fa-skull-crossbones"></i>
        This image file is <strong>huge</strong>! Re-sample the image to something more portable, please.
      </p>;
    }
    if (imageSize && imageSize > 80000) {
      return <p><i className="fas fa-exclamation-triangle"></i> This image file is quite large. App loading times will suffer from heavy graphics.</p>;
    }
    if (imageUrl && imageSize && imageSize > 50000) {
      return <p>Image should be square and preferrably under 50 kB.</p>;
    }
    if (imageUrl) {
      return <p>Square images are best for the app.</p>;
    }
    if (!imageUrl) {
      return <p>Select a square image of type png or jpg, preferrably under 50 kB.</p>;
    }
    return null;
  };

  return <div className="img-comp">
    <FileDropzone onFilesDropped={handleFilesDropped} imageUrl={imageUrl} imageSize={imageSize} />
    {sizeWarning()}
  </div>;
};
export default FileArea;
