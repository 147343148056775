import React from 'react';
import { RouteComponentProps, Switch, Route, withRouter } from 'react-router-dom';
import { UserApiModel, UserId } from 'domain/users/container/user-models';
import EditUser from 'domain/users/components/user/edit/user';

import './view.scss';
import { DetailedUserApiModel } from 'domain/users/container/reducer';

interface ComponentProps {
  users: Array<UserApiModel>
  userDetails?: DetailedUserApiModel
  onUserChanged: (user: UserApiModel) => void
}

interface MatchParams {
  uid: UserId;
}

type Props = ComponentProps & RouteComponentProps<MatchParams>;

const UserView: React.FC<Props> = ({ users, userDetails, onUserChanged, ...props }) => {
  if (!users) {
    return null;
  }
  return <div className="user-view">
    <Switch>
      <Route exact path="/users/:uid">
        <EditUser
          users={users}
          userDetails={userDetails}
          onUserChanged={onUserChanged}
          userId={props.match.params.uid} />
      </Route>
    </Switch>
  </div>;
};

export default withRouter(UserView);
