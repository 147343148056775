import {
  TOOLSYSTEMS_REQ, TOOLSYSTEMS_REQ_SUCCESS, TOOLSYSTEMS_REQ_FAILURE
} from 'reducers/action-constants';
import { asSelectables } from 'app/form/utils';

import { ToolSystemModel } from 'domain/models/tool-system-model';
import { UISelectableModel } from 'domain/models/ui-selectable-model';

export interface ToolSystemsStore {
  isFetching: boolean
  isFetched: boolean
  items?: Array<ToolSystemModel>
  selectables?: Array<UISelectableModel>
}

const getInitialState = () => {
  return {
    isFetched: false,
    isFetching: false
  };
};

export default (state = getInitialState(), action: any): ToolSystemsStore => {
  switch (action.type) {
    case TOOLSYSTEMS_REQ:
      return {
        ...state,
        isFetching: true
      };
    case TOOLSYSTEMS_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.response,
        selectables: asSelectables(action.response, 'name', 'id')
      };
    case TOOLSYSTEMS_REQ_FAILURE:
      return getInitialState();
    default:
      return state;
  }
};
