/* eslint-disable no-alert */
import React, { FC, useState } from 'react';

import { RecipeStep, RecipeSelection, MachineRecipeModel } from 'domain/recipe-designer/container/machine-recipe-reducer';
import { ToolFamilyModel } from 'domain/models/tool-family';

import { UISelectableModel } from 'domain/models/ui-selectable-model';
import { MachineModelConfigurationModel, keyFrom } from 'domain/recipe-designer/container/machine-configurations-reducer';

import ConfigSelector from 'domain/recipe-designer/components/editor/form/config/config-selector';
import DefaultToolSelector from 'domain/recipe-designer/components/editor/form/default-tool/tool-selector';
import AreaInput from 'domain/recipe-designer/components/editor/form/lifetime/area-input';
import './editor.scss';
import { MachineModelId } from 'domain/models/machine-type-model';
import ActiveInput from './active/active-input';

interface Props {
  readonly recipe?: MachineRecipeModel
  readonly step: RecipeStep
  readonly stepConfig?: RecipeSelection
  readonly toolFamiliesById: Map<string, ToolFamilyModel>
  readonly onCancel: () => void
  readonly onSave: (config: any) => void
  readonly machineModelId: MachineModelId
  configMap: Map<string, MachineModelConfigurationModel>
}

type ConfigState = {
  toolSelectionId?: string
  configurationId?: string
  area?: string
  inactive?: boolean
};

const StepEditor: FC<Props> = ({
  step,
  toolFamiliesById,
  onCancel,
  onSave,
  configMap,
  machineModelId,
  stepConfig
}: Props) => {

  const confState = stepConfig ? {
    toolSelectionId: stepConfig.toolSelectionId,
    configurationId: stepConfig.configurationId,
    area: stepConfig.area,
    inactive: stepConfig.inactive
  } : {};

  const [defaultConfig, setDefaultConfig] = useState<ConfigState>(confState);

  const editing = (propName: string, value: string | boolean) => {
    const conf = {
      ...defaultConfig,
      [propName]: value
    };
    setDefaultConfig(conf);
  };

  let configItems;
  if (defaultConfig && defaultConfig.toolSelectionId) {
    configItems = configMap.get(keyFrom({ machineModelId, toolSelectionId: defaultConfig.toolSelectionId }));
  }

  const toolFamily = toolFamiliesById.get(step.toolFamilyId);
  if (!toolFamily) {
    throw new Error(`Cannot find tool family ${step.toolFamilyId} for step ${step.stepNo}`);
  }
  const isChemical = toolFamily.type === 'CHEMICAL';
  const isHolder = toolFamily.type === 'HOLDER';
  const isChemicalOrHolder = toolFamily.type === 'CHEMICAL' || toolFamily.type === 'HOLDER';
  const detailedFamily = toolFamily.detailedItem;

  const save = () => {
    if (!defaultConfig) {
      onCancel();
    } else if (!defaultConfig.toolSelectionId) {
      alert('Cannot save without a tool selection');
    } else if (!defaultConfig.configurationId && !isChemicalOrHolder) {
      alert('Cannot save without a configuration');
    } else if ((!defaultConfig.area || defaultConfig.area === '0') && !isHolder) {
      alert('Cannot save without a lifetime area');
    } else {
      onSave({
        ...defaultConfig,
        toolFamilyId: step.toolFamilyId
      });
    }
  };
  return <div className="step-edit-cmp">
    <div>
      <div>
        <div>
          <ActiveInput
            inactive={defaultConfig.inactive}
            onClick={inactive => editing('inactive', inactive)}
          />
        </div>
        <div>
          <DefaultToolSelector
            toolSelectionId={defaultConfig.toolSelectionId}
            machineModelId={machineModelId}
            configMap={configMap}
            toolFamilyWithToolSelections={detailedFamily}
            onToolSelected={(n: string, item: UISelectableModel) => {
              editing('toolSelectionId', item.value);
            }} />
        </div>
        <div>
          { isChemicalOrHolder && <p>N/A</p>}
          { (!isChemicalOrHolder && defaultConfig && defaultConfig.toolSelectionId && configItems) &&
          <ConfigSelector
            toolSelectionId={defaultConfig.toolSelectionId}
            configurationId={defaultConfig.configurationId}
            onSelection={(configId: string) => { editing('configurationId', configId); }}
            machineModelId={machineModelId}
            configMap={configMap}
              />}
        </div>
        <div>
          { isHolder && <p>N/A</p>}
          { (defaultConfig && defaultConfig.toolSelectionId && (defaultConfig.configurationId || isChemical)) &&
          <AreaInput
            area={defaultConfig.area}
            onSelection={(area) => {
              const areaNum = parseInt(area, 10);
              if (!Number.isNaN(areaNum)) {
                editing('area', areaNum.toString());
              } else {
                editing('area', '');
              }
            }} />}
        </div>
      </div>
      <div>
        <div className="actions">
          <button onClick={onCancel} className="secondary" type="button">Cancel</button>
          <button onClick={save} className="primary" type="button">Save</button>
        </div>
      </div>
    </div>
  </div>;
};

export default StepEditor;
