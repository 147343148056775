import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { TosRootState } from 'reducers';
import Wait from 'app/wait/wait';
import ToolPage from 'domain/tools/components/view/tool-page';
import * as actions from './actions';

type ReduxProps = ConnectedProps<typeof connector>;
export interface OwnProps extends RouteComponentProps<{ id: string; languageId: string }> {
}
type Props = ReduxProps & OwnProps;

const Tool: FC<Props> = ({ getTool, tool, ...props }) => {

  useEffect(() => {
    getTool(props.match.params.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tool ? <ToolPage tool={tool} /> : <Wait />;
};

const mapStateToProps = (state: TosRootState) => ({
  tool: state.tool.item
});

const connector = connect(
  mapStateToProps,
  actions
);

export default connector(withRouter(Tool));
