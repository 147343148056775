import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TosRootState } from 'reducers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MaterialTypeModel } from 'domain/models/material-type-model';
import * as actions from 'domain/content/container/actions';
import './material-types.scss';
import EditType from 'domain/content/components/any-types/edit-type/edit-type';
import AddNewType from 'domain/content/components/any-types/add-new-type/add-new-type';
import { TypeFormModel } from 'domain/content/components/any-types/type-form-model';

export const initialFormState: TypeFormModel = {
  name: '',
  description: '',
  uiOrder: '',
};

export interface Props extends RouteComponentProps<{
  materialTypeId: string;
  languageId: string;
}> {
  selectedId: string;
  materialTypes?: Array<MaterialTypeModel>;
  isAddingNewMaterialType: boolean;
  setIsAdding: Function;
}

type ReduxProps = ConnectedProps<typeof connector>;

const MaterialTypes: React.FC<Props & ReduxProps> = (props) => {
  const [formState, setFormState] = useState<TypeFormModel>({ ...initialFormState });

  const { getMaterialType, getSuggestedMaterialTypeUIOrder } = props;

  const handleLanguageClick = useCallback(
    (languageId: string) => {
      props.history.push(
        `/content/material-types/${props.match.params.materialTypeId}/${languageId}`
      );
    },
    [props.history, props.match]
  );

  useEffect(() => {
    if (!props.isAddingNewMaterialType && props.match.params.materialTypeId !== 'new' && !props.match.params.languageId) {
      handleLanguageClick('en');
    }
  }, [handleLanguageClick, props.isAddingNewMaterialType, props.match.params.materialTypeId, props.match.params.languageId]);

  // Fetch material type when top materialTypeId changes
  useEffect(() => {
    if (props.isSavingTextItems || !props.match.params.materialTypeId || props.match.params.materialTypeId === 'new') {
      return;
    }

    if (!props.isSavingTextItems) {
      getMaterialType(props.match.params.materialTypeId);
    }
  }, [getMaterialType, props.match.params.materialTypeId, props.isSavingTextItems]);

  useEffect(() => {
    if (props.isAddingNewMaterialType) {
      setFormState(initialFormState);
      getSuggestedMaterialTypeUIOrder();
    }
  }, [getSuggestedMaterialTypeUIOrder, props.isAddingNewMaterialType]);

  useEffect(() => {
    if (props.createdMaterialType) {
      props.history.push(`/content/material-types/${props.createdMaterialType.id}/en`);
    }
  }, [props.createdMaterialType, props.history]);

  return (
    <div className="material-types-view">
      {props.isAddingNewMaterialType ? (
        <AddNewType
          formState={formState}
          setFormState={setFormState}
          isSavingNewType={props.isSavingNewMaterialType}
          saveNewType={props.saveNewMaterialType}
          setIsAdding={props.setIsAdding}
          isLoadingSuggestedUIOrder={props.isLoadingSuggestedUIOrder}
          />
      ) : (
        (props.match.params.materialTypeId && props.loadedMaterialType) && (
          <EditType
            id="material-type"
            isLoadingType={props.isLoadingMaterialType}
            languageId={props.match.params.languageId}
            selectedId={props.selectedId}
            formState={formState}
            setFormState={setFormState}
            handleLanguageClick={handleLanguageClick}
            loadedType={props.loadedMaterialType}
            saveTextItems={props.saveTextItems}
            isSavingTextItems={props.isSavingTextItems}
          />
        )
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getMaterialType: actions.getMaterialType,
  saveTextItems: actions.saveTextItems,
  saveNewMaterialType: actions.saveNewMaterialType,
  getSuggestedMaterialTypeUIOrder: actions.getSuggestedMaterialTypeUIOrder,
};

const mapStateToProps = ({ content: { materialTypes } }: TosRootState) => ({
  isSavingNewMaterialType: materialTypes.isSavingNewMaterialType,
  suggestedUIOrder: materialTypes.suggestedUIOrder,
  createdMaterialType: materialTypes.createdMaterialType,
  loadedMaterialType: materialTypes.loadedMaterialType,
  isLoadingMaterialType: materialTypes.isLoadingMaterialType,
  isSavingTextItems: materialTypes.isSavingTextItems,
  isLoadingSuggestedUIOrder: materialTypes.isLoadingSuggestedUIOrder
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(MaterialTypes));
