import React from 'react';

interface Props {
  contentType: string;
  onCreateClick(type: string): void;
}

const ContentListFooter: React.FC<Props> = props => {
  if (props.contentType === 'material-types') {
    return <>
      <button
        className="large text"
        onClick={() => props.onCreateClick('material-type')}
        type="button">&nbsp;Material&nbsp;type</button>
      <button
        className="large text"
        onClick={() => props.onCreateClick('material')}
        type="button">&nbsp;Material</button>
    </>;
  }
  if (props.contentType === 'finish-types') {
    return <>
      <button
        className="large text"
        onClick={() => props.onCreateClick('finish-type')}
        type="button">&nbsp;Finish&nbsp;type</button>
      <button
        className="large text"
        onClick={() => props.onCreateClick('finish')}
        type="button">&nbsp;Finish</button>
    </>;
  }
  if (props.contentType === 'machine-types') {
    return <>
      <button
        className="large text"
        onClick={() => props.onCreateClick('machine-type')}
        type="button">&nbsp;Machine&nbsp;type</button>
      <button
        className="large text"
        onClick={() => props.onCreateClick('machine')}
        type="button">&nbsp;Machine</button>
    </>;
  }
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  return <button
    className="large"
    onClick={() => props.onCreateClick(props.contentType)}
    type="button" />;
};

export default ContentListFooter;
