import { combineReducers } from 'redux';
import machines from './content-machines-reducer';
import machineTypes from './content-machine-types-reducer';
import finishes from './content-finishes-reducer';
import finishTypes from './content-finish-types-reducer';
import materials from './content-materials-reducer';
import materialTypes from './content-material-types-reducer';
import toolSystems from './content-tool-systems-reducer';

export default combineReducers({
  machines,
  machineTypes,
  finishes,
  finishTypes,
  materials,
  materialTypes,
  toolSystems
});
