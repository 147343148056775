import React, { FC } from 'react';
import cls from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Wait from 'app/wait/wait';
import UserView from 'domain/users/components/user/view';
import { UserApiModel, UserId } from 'domain/users/container/user-models';
import './user-sections.scss';
import { DetailedUserApiModel } from 'domain/users/container/reducer';

interface ComponentProps {
  users: Array<UserApiModel>
  onUserSelected: (id: UserId) => void
  usersPending: boolean
  userDetails?: DetailedUserApiModel
  onUserChanged: (user: UserApiModel) => void
}
interface MatchParams {
  uid: UserId;
}

type Props = ComponentProps & RouteComponentProps<MatchParams>;

const UsersSections: FC<Props> = ({ users, usersPending, onUserSelected, userDetails, onUserChanged, ...props }) => {
  const selectedUid = props.match.params.uid;
  return <div className="users-sec-c">
    <div className="list">
      <div className="header li-head">Users</div>
      <div className="list">
        { usersPending && <Wait />}
        { !usersPending && users.map(u => <div
          role="button"
          key={u.uid}
          className={cls({
            selected: selectedUid === u.uid,
          })}
          onClick={() => { onUserSelected(u.uid); }}>
          <div>{u.uid}</div>
          <div>{u.marketId}</div>
          <div>{u.fullName}</div>
          <div>{u.isSuperAdmin && <i className="fas fa-users-cog"></i>}</div>
          <div>{u.isRecipeAdmin && <i className="fas fa-tools"></i>}</div>
        </div>)}
      </div>
    </div>
    <div className="user-item">
      { selectedUid && <UserView users={users} userDetails={userDetails} onUserChanged={onUserChanged} /> }
    </div>
  </div>;
};

export default withRouter(UsersSections);
