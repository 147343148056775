import { UISelectableModel } from 'domain/models/ui-selectable-model';

export const asSelectables = (items: Array<any>, labelPropName: any, valuePropName: string):Array<UISelectableModel> => items ? items.map(item => ({
  label: (typeof labelPropName === 'string') ? item[labelPropName] : labelPropName(item),
  value: item[valuePropName]
})) : [];

export const typesAsSelectables = (type: string) => (types: []) => {
  if (!types) {
    return [];
  }
  return types.reduce(
    (acc: UISelectableModel[], curr: any) => {
      if (curr[type]) {
        return acc.concat(
        // @ts-ignore
          asSelectables(curr[type], 'name', 'id')
            .map(m => ({ value: m.value, label: `${curr.name} (${m.label})` }))
        );
      }
      return acc;
    }, []);
};
