import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TosRootState } from 'reducers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FinishTypeModel } from 'domain/models/finish-type-model';
import * as actions from 'domain/content/container/actions';
import './finish-types.scss';
import { TypeFormModel } from 'domain/content/components/any-types/type-form-model';
import EditType from 'domain/content/components/any-types/edit-type/edit-type';
import AddNewType from 'domain/content/components/any-types/add-new-type/add-new-type';
import { initialFormState } from '../any-types/type-form-model';

export interface Props extends RouteComponentProps<{
  finishTypeId: string;
  languageId: string;
}> {
  selectedId: string;
  finishTypes?: Array<FinishTypeModel>;
  isAddingNewFinishType: boolean;
  setIsAdding: Function;
}

type ReduxProps = ConnectedProps<typeof connector>;

const FinishTypes: React.FC<Props & ReduxProps> = (props) => {
  const [formState, setFormState] = useState<TypeFormModel>({ ...initialFormState });

  const { getFinishType, getSuggestedFinishTypeUIOrder } = props;

  const handleLanguageClick = useCallback(
    (languageId: string) => {
      props.history.push(
        `/content/finish-types/${props.match.params.finishTypeId}/${languageId}`
      );
    },
    [props.history, props.match]
  );

  useEffect(() => {
    if (!props.isAddingNewFinishType && props.match.params.finishTypeId !== 'new' && !props.match.params.languageId) {
      handleLanguageClick('en');
    }
  }, [handleLanguageClick, props.isAddingNewFinishType, props.match.params.finishTypeId, props.match.params.languageId]);

  // Fetch finish type when top finishTypeId changes
  useEffect(() => {
    if (props.isSavingTextItems || !props.match.params.finishTypeId || props.match.params.finishTypeId === 'new') {
      return;
    }

    if (!props.isSavingTextItems) {
      getFinishType(props.match.params.finishTypeId);
    }
  }, [getFinishType, props.match.params.finishTypeId, props.isSavingTextItems]);

  useEffect(() => {
    if (props.isAddingNewFinishType) {
      setFormState(initialFormState);
      getSuggestedFinishTypeUIOrder();
    }
  }, [getSuggestedFinishTypeUIOrder, props.isAddingNewFinishType]);

  useEffect(() => {
    if (props.createdFinishType) {
      props.history.push(`/content/finish-types/${props.createdFinishType.id}/en`);
    }
  }, [props.createdFinishType, props.history]);

  return (
    <div className="finish-types-view">
      {props.isAddingNewFinishType ? (
        <AddNewType
          formState={formState}
          setFormState={setFormState}
          isSavingNewType={props.isSavingNewFinishType}
          saveNewType={props.saveNewFinishType}
          setIsAdding={props.setIsAdding}
          isLoadingSuggestedUIOrder={props.isLoadingSuggestedUIOrder}
          />
      ) : (
        (props.match.params.finishTypeId && props.loadedFinishType) && (
          <EditType
            id="finish-types"
            isLoadingType={props.isLoadingFinishType}
            languageId={props.match.params.languageId}
            selectedId={props.selectedId}
            formState={formState}
            setFormState={setFormState}
            handleLanguageClick={handleLanguageClick}
            loadedType={props.loadedFinishType}
            saveTextItems={props.saveTextItems}
            isSavingTextItems={props.isSavingTextItems}
          />
        )
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getFinishType: actions.getFinishType,
  saveTextItems: actions.saveTextItems,
  saveNewFinishType: actions.saveNewFinishType,
  getSuggestedFinishTypeUIOrder: actions.getSuggestedFinishTypeUIOrder,
};

const mapStateToProps = ({ content: { finishTypes } }: TosRootState) => ({
  isSavingNewFinishType: finishTypes.isSavingNewFinishType,
  suggestedUIOrder: finishTypes.suggestedUIOrder,
  createdFinishType: finishTypes.createdFinishType,
  loadedFinishType: finishTypes.loadedFinishType,
  isLoadingFinishType: finishTypes.isLoadingFinishType,
  isSavingTextItems: finishTypes.isSavingTextItems,
  isLoadingSuggestedUIOrder: finishTypes.isLoadingSuggestedUIOrder
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(FinishTypes));
