import {
  ERROR_RESET
} from 'reducers/action-constants';

/**
 * Request a recipe
 */
export const clearErrors = () => (dispatch: Function) => {
  return dispatch({ type: ERROR_RESET });
};
