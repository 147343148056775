import { AnyAction } from 'redux';
import { CALL_API } from 'app/middleware/api';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as actionConstants from 'reducers/action-constants';
import * as toastUtils from 'app/general/messages/toast-util';
import { createGetMaterialsActions } from './content-materials-actions';
import { createGetFinishesActions } from './content-finishes-actions';

const createGetUploadUrlAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [
      actionConstants.GET_UPLOADURL_REQ,
      actionConstants.GET_UPLOADURL_REQ_SUCCESS,
      actionConstants.GET_UPLOADURL_REQ_FAILURE,
    ],
    pathname: '/v1/images/url'
  },
});

export const getUploadUrl = (): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return dispatch(createGetUploadUrlAction());
  };
};

/**
 * Edit texts
 */
const createSaveTextItemsActions = (
  type: string,
  languageCode: string,
  items: Array<{
    id: string;
    text: string;
  }>
) => ({
  [CALL_API]: {
    types: [
      actionConstants.SAVE_TEXT_ITEMS_REQ,
      actionConstants.SAVE_TEXT_ITEMS_REQ_SUCCESS,
      actionConstants.SAVE_TEXT_ITEMS_REQ_FAILURE,
    ],
    pathname: `/v1/text-items/languages/${languageCode}`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        items,
      }),
    },
  },
});

export const saveTextItems = (
  type: string,
  languageCode: string,
  items: Array<{
    id: string;
    text: string;
  }>
) => (dispatch: (action: any) => Promise<AnyAction>) => {
  return dispatch(createSaveTextItemsActions(type, languageCode, items)).then(
    resp => {
      if (resp.type === actionConstants.SAVE_TEXT_ITEMS_REQ_SUCCESS) {
        toastUtils.success('Text successfully updated');
      }
      if (type === 'material' || type === 'material-type') {
        return dispatch(createGetMaterialsActions());
      }
      if (type === 'finish' || type === 'finish-type') {
        return dispatch(createGetFinishesActions());
      }
      return resp;
    }
  );
};
