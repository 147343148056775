import React, { ChangeEvent } from 'react';
import cls from 'classnames';
import './textarea.scss';

export interface Props {
  value: string;
  name: string;
  label: string;
  width?: string;
  disabled?: boolean;
  onChange(e: ChangeEvent<HTMLTextAreaElement>): void;
}

const TextArea: React.FC<Props> = props => {
  return (
    <div className={cls('txt-cmp', { disabled: props.disabled })}>
      <div className={props.width || 'none'}>
        <textarea
          required
          id={props.name}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
        />
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    </div>
  );
};

export default TextArea;
