import React from 'react';
import cls from 'classnames';
import { productNumber } from 'app/utils/format';
import { ToolSelectionId } from 'domain/models/tool-selection-model';

type Props = {
  id: string,
  name: string,
  productNumber: string,
  isDeletable: boolean,
  onDelete: (id: string) => void
  onViewToolInfo: (id: ToolSelectionId) => void
};

const ToolSelectionsRow: React.FC<Props> = (props: Props) => {
  return <div className="list-row">
    <div className="name">{props.name}</div>
    <div className="number">{productNumber(props.productNumber)}</div>
    <div
      role="button"
      className="action more"
      onClick={() => props.onViewToolInfo(props.id)}
    >
      <i className="fas fa-info-circle" />
    </div>
    <div
      role="button"
      className={cls('action delete', { disabled: !props.isDeletable })}
      title={props.isDeletable ? 'Delete' : 'This tool is used in recipes, and must be removed from them before deletion'}
      onClick={() => props.isDeletable && props.onDelete(props.id)}
    >
      <i className="far fa-trash-alt" />
    </div>
  </div>;
};

export default ToolSelectionsRow;
