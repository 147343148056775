import { CALL_API } from 'app/middleware/api';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import Config from 'app/utils/config';
import localStorageUtil from 'app/utils/local-storage';

import {
  AUTHORIZE_CODE_REQ, AUTHORIZE_CODE_REQ_SUCCESS, AUTHORIZE_CODE_REQ_FAILURE,
  AUTENTICATED_USER_REQ, AUTENTICATED_USER_REQ_SUCCESS, AUTENTICATED_USER_REQ_FAILURE
} from 'reducers/action-constants';
import { TosRootState } from 'reducers';

const createAuthorizeCodeAction = (code: any) => ({
  [CALL_API]: {
    types: [AUTHORIZE_CODE_REQ, AUTHORIZE_CODE_REQ_SUCCESS, AUTHORIZE_CODE_REQ_FAILURE],
    pathname: `/v1/session?client=${Config.auth.TOS_AUTH_CLIENT_TYPE}&code=${code}`
  }
});

export const authorizeCode = (code: any) => (dispatch: any) => dispatch(createAuthorizeCodeAction(code))
  .then((outcome: any) => {
    if (outcome.type === AUTHORIZE_CODE_REQ_SUCCESS) {
      localStorageUtil.storeSession(outcome.response.sessionId);
      dispatch(getAuthenticatedUser());
    }
  });

const createAuthenticatedUserAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [AUTENTICATED_USER_REQ, AUTENTICATED_USER_REQ_SUCCESS, AUTENTICATED_USER_REQ_FAILURE],
    pathname: '/v1/users/current'
  }
});

/**
 * Requests all tool families
 */
export const getAuthenticatedUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const s: TosRootState = getState();
    // @ts-ignore
    if (s.login.authenticatedUser) {
      return undefined;
    }
    return dispatch(createAuthenticatedUserAction());
  };
};
