import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ToolFamilyModel } from 'domain/models/tool-family';
import List from 'app/list/list';
import './list.scss';

type PathParamsType = {
  id: string,
  type: string,
};

type Props = RouteComponentProps<PathParamsType> & {
  grindingList?: Array<ToolFamilyModel>,
  polishingList?: Array<ToolFamilyModel>,
  chemicalList?: Array<ToolFamilyModel>,
  holderList?: Array<ToolFamilyModel>,
  selectedId: string,
  selectToolFamily: (id: string | undefined) => void,
  onCreateClicked: (isCreating: boolean, toolFamilyType?: string) => void,
  handleDelete: (id: string) => void
};

const headers = [
  { name: 'grinding', text: 'Grinding' },
  { name: 'polishing', text: 'Polishing' },
  { name: 'chemical', text: 'Chemicals' },
  { name: 'holder', text: 'Holders' },
];

const ToolFamiliesList: React.FC<Props> = (props: Props) => {
  const { grindingList, polishingList, chemicalList, holderList, selectedId, match, selectToolFamily } = props;

  useEffect(() => {
    if (match.params.type === undefined || match.params.type === '') {
      props.history.replace('/tool-families/grinding');
    }
    selectToolFamily(undefined);
  }, [match.params.type]);

  useEffect(() => {
    if (match.params.id) {
      selectToolFamily(match.params.id);
    }
  }, [match.params.id]);

  const listsWithItems = useMemo(
    () => ({
      grinding: grindingList,
      polishing: polishingList,
      chemical: chemicalList,
      holder: holderList
    }),
    [grindingList, polishingList, chemicalList, holderList]
  );

  const handleRowClick = (toolFamilyId: string) => {
    props.onCreateClicked(false);
    props.history.push(`/tool-families/${match.params.type}/${toolFamilyId}`);
  };

  const handleCreateClick = () => {
    props.onCreateClicked(true, match.params.type.toUpperCase());
    props.selectToolFamily('');
    props.history.replace(`/tool-families/${match.params.type}`);
  };

  const handleRemoveClick = (id: string) => {
    props.handleDelete(id);
    if (props.selectedId === id) {
      props.history.push(`/tool-families/${match.params.type}`);
    }
  };

  return <div className="tool-families-list">
    <List
      path="tool-families"
      selectedId={selectedId}
      onRowClick={handleRowClick}
      onRemoveClick={handleRemoveClick}
      selectedType={match.params.type}
      headers={headers}
      lists={listsWithItems}
      listItemsUppercase
      footer={
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button className="large" onClick={handleCreateClick} type="button"></button>
      }
    />
  </div>;
};

export default withRouter(ToolFamiliesList);
