import { Reducer } from 'redux';
import { FinishModelWithTexts } from 'domain/models/finish-type-model';
import * as actionConstants from 'reducers/action-constants';

export interface FinishesStore {
  isLoadingFinish: boolean;
  isSavingFinish: boolean;
  isSavingNewFinish: boolean;
  isLoadingSuggestedUIOrder: boolean;
  loadedFinish?: FinishModelWithTexts;
  createdFinish?: any;
  suggestedUIOrder?: string;
}

const getInitialState = (): FinishesStore => ({
  isLoadingFinish: false,
  isSavingFinish: false,
  isSavingNewFinish: false,
  isLoadingSuggestedUIOrder: false
});

const reducer: Reducer<FinishesStore> = (state = getInitialState(), action) => {
  switch (action.type) {
    case actionConstants.FINISH_REQ:
      return {
        ...state,
        isLoadingFinish: true,
      };

    case actionConstants.FINISH_REQ_SUCCESS:
      return {
        ...state,
        isLoadingFinish: false,
        loadedFinish: action.response,
      };

    case actionConstants.FINISH_REQ_FAILURE:
      return {
        ...state,
        isLoadingFinish: false,
      };

    case actionConstants.SAVE_FINISH_REQ:
      return {
        ...state,
        isSavingFinish: true,
      };

    case actionConstants.SAVE_FINISH_REQ_SUCCESS:
    case actionConstants.SAVE_FINISH_REQ_FAILURE:
      return {
        ...state,
        isSavingFinish: false,
      };

    case actionConstants.SAVE_NEW_FINISH_REQ:
      return {
        ...state,
        isSavingNewFinish: true,
        createdFinishId: undefined,
      };

    case actionConstants.SAVE_NEW_FINISH_REQ_SUCCESS:
      return {
        ...state,
        isSavingNewFinish: false,
        createdFinishId: action.response,
      };

    case actionConstants.SAVE_NEW_FINISH_REQ_FAILURE:
      return {
        ...state,
        isSavingNewFinish: false,
      };

    case actionConstants.RESET_CREATED_FINISH_ID:
      return {
        ...state,
        createdFinishId: undefined,
      };

    case actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ:
      return {
        ...state,
        isLoadingSuggestedUIOrder: true,
        suggestedUIOrder: undefined,
      };

    case actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ_SUCCESS:
      return {
        ...state,
        isLoadingSuggestedUIOrder: false,
        suggestedUIOrder: action.response.suggestedUIOrder,
      };

    case actionConstants.GET_SUGGESTED_FINISH_UI_ORDER_REQ_FAILURE:
      return {
        ...state,
        isLoadingSuggestedUIOrder: false,
        suggestedUIOrder: undefined,
      };

    default:
      return state;
  }
};

export default reducer;
