import React from 'react';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { MachineModelConfigurationModel, keyFrom } from 'domain/recipe-designer/container/machine-configurations-reducer';
import { productNumber } from 'app/utils/format';
import Input from 'app/form/input/input';
import { RecipeStep, RecipeSelection } from 'domain/recipe-designer/container/machine-recipe-reducer';
import './step-row.scss';

interface Props {
  readonly order: number
  readonly step: RecipeStep
  readonly stepConfig?: RecipeSelection
  readonly toolFamily?: ToolFamilyModel
  readonly isActive: boolean
  readonly isEditing: boolean
  readonly machineModelId: string
  readonly isEditingDisabled?: boolean
  configMap: Map<string, MachineModelConfigurationModel>
  readonly onConfigureStep: (x: RecipeStep) => void
  readonly onDeleteConfiguration: (deletable: RecipeStep) => void
  readonly onDeleteStep: (deletable: RecipeStep) => void
  readonly onStepMoveUp: (step: RecipeStep) => void
  readonly onStepMoveDown: (step: RecipeStep) => void
}

const RecipeDesignRow: React.FC<Props> = ({
  order,
  step,
  toolFamily,
  onConfigureStep,
  onDeleteConfiguration,
  onDeleteStep,
  isActive,
  isEditing,
  isEditingDisabled,
  stepConfig,
  configMap,
  machineModelId,
  onStepMoveUp,
  onStepMoveDown
}: Props) => {
  const getConfig = (): any => {
    let results;
    if (stepConfig && stepConfig.configurationId && stepConfig.toolSelectionId) {
      const configItems = configMap.get(keyFrom({ machineModelId, toolSelectionId: stepConfig.toolSelectionId }));

      if (configItems && configItems.validConfigurations) {
        const max = configItems.validConfigurations.reduce((maxQty, item) => {
          const currQty = parseInt(item.toolQuantity, 10);
          return (currQty > maxQty) ? currQty : maxQty;
        }, 0);
        const conf = configItems.validConfigurations.find(item => item.id === stepConfig.configurationId);
        if (conf) {
          results = {
            ...conf,
            connectorTypePresentation: conf.connectorTypeId,
            presentationName: `${conf.toolQuantity} / ${max}`
          };
          if (conf.connectorTypeId === 'redilock') {
            results.connectorTypePresentation = 'Redi Lock™';
          }
          if (conf.connectorTypeId === 'ezchange') {
            results.connectorTypePresentation = 'EZchange';
          }
          if (conf.connectorTypeId && conf.connectorTypeId === 'pucks') {
            results.connectorTypePresentation = 'Velcro Pads';
          }
          if (conf.connectorTypeId && conf.connectorTypeId === 'hiperflex') {
            results.connectorTypePresentation = 'Velcro HiperFlex';
          }
          if (conf.connectorTypeId && conf.connectorTypeId === 'magnetic') {
            results.connectorTypePresentation = 'Magnetic Mount';
          }
        }
      }
    }
    return results || {};
  };
  const getDefaultTool = (): any => {
    if (toolFamily && toolFamily.detailedItem && toolFamily.detailedItem.toolSelections && stepConfig) {
      return toolFamily.detailedItem.toolSelections.find(item => item.id === stepConfig.toolSelectionId) ||
        { name: `Could not find ${stepConfig.toolSelectionId}` };

    }
    return {};
  };

  const conf = getConfig();
  const tool = getDefaultTool();
  return <div className={`rd-row-cp ${isActive ? ' active' : ''} ${isEditing ? ' editing' : ''}`}>
    <div>{order + 1}</div>
    <div>{toolFamily && toolFamily.type}</div>
    <div>{toolFamily && toolFamily.name}</div>
    <div>
      <span className="actions">
        <i className="far fa-trash-alt" hidden onClick={() => { onDeleteStep(step); }} />
        <i className="fas fa-caret-square-up" onClick={() => { onStepMoveUp(step); }}></i>
        <i className="fas fa-caret-square-down" onClick={() => { onStepMoveDown(step); }}></i>
      </span>
    </div>
    {!isEditing && <>
      <div title={stepConfig?.inactive ? 'Inactive' : 'Active'}>
        <Input
          type="checkbox"
          name="isActive"
          disabled
          checked={!stepConfig?.inactive}
          styleOnWhite />
      </div>
      <div title={tool.name}>
        {tool.name}
        {(!stepConfig && !isEditingDisabled) &&
          <button onClick={() => onConfigureStep(step)} className="primary conf-btn" type="button">Configure step</button>}
      </div>
      <div>{productNumber(tool.productNumber)}</div>
      <div>{conf.presentationName}</div>
      <div>{conf.connectorTypePresentation}</div>
      <div className="conf">{stepConfig && <span>
        {stepConfig.area ? (`${stepConfig.area} m`) : ''} <sup>{stepConfig.area ? 2 : ''}</sup>
      </span>}
      </div>
      <div className="conf symbols">
        {(stepConfig && !isEditingDisabled) && <span className="actions">
          <i className="far fa-trash-alt" onClick={() => { onDeleteConfiguration(step); }} />
          <i className="fas fa-pen" onClick={() => { onConfigureStep(step); }} />
        </span>}
      </div>
      <div className="conf"></div>
    </>}
  </div>;
};

export default RecipeDesignRow;
