import React, { ChangeEvent } from 'react';
import Input from 'app/form/labeled-input/input';

export interface Props {
  value: string;
  disableInput: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

const ArticleNo: React.FC<Props> = ({ value, disableInput, onChange }) => {
  return (
    <div className="article-no-wrapper">
      <p>Add article no in order to fetch machine properties</p>
      <Input
        name="articleNo"
        type="text"
        label="Article No"
        disabled={disableInput}
        value={value}
        width="short"
        onChange={onChange}
        maxLength={9}
      />
    </div>
  );
};

export default ArticleNo;
