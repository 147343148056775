import { typesAsSelectables } from 'app/form/utils';
import { MachineType } from 'domain/models/machine-type-model';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import {
  DELETE_MACHINE_MODEL_REQ_SUCCESS,
  MODELS_REQ, MODELS_REQ_FAILURE, MODELS_REQ_SUCCESS,
} from 'reducers/action-constants';

export interface MachineModelsStore {
  isFetching: boolean;
  items?: Array<MachineType>;
  selectables?: Array<UISelectableModel>;
}

const getInitialState = (): MachineModelsStore => {
  return {
    isFetching: false,
  };
};

export default (state = getInitialState(), action: any): MachineModelsStore => {
  switch (action.type) {
    case MODELS_REQ:
      return {
        ...state,
        isFetching: true
      };
    case MODELS_REQ_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.response,
        selectables: typesAsSelectables('machineModels')(action.response)
      };
    }
    case DELETE_MACHINE_MODEL_REQ_SUCCESS:
      return {
        ...state,
        items: state.items?.filter(item => item.id !== action.context?.modelId)
      };

    case MODELS_REQ_FAILURE:
      return getInitialState();

    default:
      return state;
  }
};
