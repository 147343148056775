import React, { PureComponent, ChangeEvent } from 'react';
import './input.scss';

interface InpProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onValueChanged?: (event: ChangeEvent<HTMLInputElement>) => void
  name: string
  type?: string
  tabIndex?: number
  label: string
  width?: string
  maxLength?: number
  minValue?: number
  value?: number | string
  disabled?: boolean
  required?: boolean
  title?: string
  styleOnWhite?: boolean
}

class Input extends PureComponent<InpProps> {
  constructor (props: InpProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange (event: any) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  onBlur (event: any) {
    if (this.props.onValueChanged) {
      this.props.onValueChanged(event);
    }
  }

  render () {
    const width = this.props.width || 'none';
    return (
      <div className={`lic${this.props.disabled ? ' disabled' : ''}${this.props.styleOnWhite ? ' on-white' : ''}`}>
        <div className={width}>
          <input
            type={this.props.type || 'text'}
            name={this.props.name}
            id={this.props.name}
            tabIndex={this.props.tabIndex}
            value={this.props.value}
            autoComplete="off"
            disabled={this.props.disabled || false}
            readOnly={this.props.disabled || false}
            maxLength={this.props.maxLength}
            // @ts-ignore
            // eslint-disable-next-line
            ref={element => (element || {}).onsearch = this.onChange}
            title={this.props.title}
            onChange={this.onChange}
            min={this.props.minValue}
            required
            onBlur={this.onBlur} />
          <label htmlFor={this.props.name}>
            { this.props.label }
          </label>
        </div>
      </div>
    );
  }
}

export default Input;
