import React from 'react';
import { ToolFamilyModel } from 'domain/models/tool-family';
import ToolSelectionsEditor from 'domain/tool-families/components/view/editor/tool-selections-editor';
import MarketAvailability from 'domain/tool-families/components/view/editor/market-availability';
import Input from 'app/form/labeled-input/input';
import './tool-family-editor.scss';
import { ToolSelectionId } from 'domain/models/tool-selection-model';

type Props = {
  toolFamily: ToolFamilyModel,
  markets: Array<any>,
  handleAddTool: (articleNumber: string, toolName: string) => void,
  handleSearchTool: (articleNumber: string) => void,
  handleDeleteToolSelection: (id: string) => void,
  onViewToolInfo: (id: ToolSelectionId) => void,
  isFetching: boolean,
  isFetchingDimrTool: boolean,
  isSearching: boolean,
  isSaving: boolean,
  toolSearchList: Array<any>,
  handleSave: () => void,
  handleMarketAvailToggle: (marketId: string) => void,
  handleChangeName: (name: string) => void,
  handleChangeGritSize: (gritSize: string) => void
};

const isRequiredFilled = (toolFamily : ToolFamilyModel) => {
  const isNameOk = toolFamily.name && toolFamily.name.length;
  const isGritSizeOk = toolFamily.type === 'CHEMICAL' || toolFamily.type === 'HOLDER' ? true : (toolFamily.gritSize && toolFamily.gritSize.length);
  return isNameOk && isGritSizeOk;
};

const ToolFamilyEditor: React.FC<Props> = (props) => {
  return <>
    <div className="top">
      <Input
        name="name"
        label="Name"
        type="text"
        value={props.toolFamily.name}
        onChange={(e) => props.handleChangeName(e.target.value)} />
      {props.toolFamily.type !== 'CHEMICAL' && props.toolFamily.type !== 'HOLDER' &&
        <Input
          name="gritSize"
          label="Grit size"
          type="number"
          value={`${props.toolFamily.gritSize}`}
          onChange={(e) => props.handleChangeGritSize(e.target.value)} />}
    </div>
    <div className="main">
      <div className="left">
        <ToolSelectionsEditor
          toolSelections={props.toolFamily.toolSelections}
          handleAddTool={props.handleAddTool}
          handleSearchTool={props.handleSearchTool}
          handleDeleteToolSelection={props.handleDeleteToolSelection}
          onViewToolInfo={props.onViewToolInfo}
          isSearching={props.isSearching}
          isFetchingDimrTool={props.isFetchingDimrTool}
          toolSearchList={props.toolSearchList}
        />
      </div>
      <div className="right">
        { props.toolFamily.markets && <MarketAvailability
          markets={props.markets}
          availableOnMarkets={props.toolFamily.markets}
          handleToggle={props.handleMarketAvailToggle} /> }
      </div>
    </div>
    <div className="bottom">
      <button
        className="large primary"
        disabled={props.isSearching || props.isFetching || props.isSaving || !isRequiredFilled(props.toolFamily)}
        onClick={props.handleSave}
        type="button"
      >Save
      </button>
    </div>
  </>;
};

export default ToolFamilyEditor;
