import React from 'react';
import Body from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import StyleThingies from 'app/style/style-thingies';

const StylePage = () => {
  return <Body
    topbar={<Menu />}
    content={<StyleThingies />}
  />;
};

export default StylePage;
