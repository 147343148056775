import {
  GET_UPLOADURL_REQ, GET_UPLOADURL_REQ_SUCCESS, GET_UPLOADURL_REQ_FAILURE
} from 'reducers/action-constants';

export interface ImageUrlStore {
  isFetched: boolean
  item?: ImageUrlItem
}
export interface ImageUrlItem {
  id: string
  url: string
}

export default (state: ImageUrlStore = { isFetched: false }, action: any): ImageUrlStore => {
  switch (action.type) {
    case GET_UPLOADURL_REQ_FAILURE:
    case GET_UPLOADURL_REQ:
      return {
        isFetched: false
      };
    case GET_UPLOADURL_REQ_SUCCESS:
      return {
        isFetched: true,
        item: action.response
      };
    default:
      return state;
  }
};
