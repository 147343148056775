export const uploadFile = async (file: File, endpoint: string, fileType: string, onProgressHandler = () => {}) => {
  const xhr = new XMLHttpRequest();
  // eslint-disable-next-line
  xhr.upload.addEventListener('progress', e => onProgressHandler(), false);
  xhr.open('PUT', endpoint, true);
  xhr.setRequestHeader('Content-type', fileType);
  xhr.send(file);

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();
      } else {
        // eslint-disable-next-line no-console
        console.log('ERROR', xhr.status);
        reject(new Error(xhr.responseText));
      }
    };
  });
};
