import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import { getConfig } from 'app/config-actions';
import { getAuthenticatedUser } from 'domain/login/container/actions';
import WaitPage from 'app/wait/page';
import Dashboard from 'domain/dashboard/container/dashboard';
import Content from 'domain/content/container/content';
import Users from 'domain/users/container/users';
import RecipePicker from 'domain/recipe-picker/container/recipe-picker';
import Logout from 'domain/logout/container/logout';
import ToolFamilies from 'domain/tool-families/components/page';
import Tool from 'domain/tools/components/page';
import LoginsMap from 'domain/stats/container/logins-map';
import Style from 'app/style/style-page';
import './tos-app.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

class TosApp extends PureComponent<Props> {
  componentDidMount () {
    this.props.getConfig();
    this.props.getAuthenticatedUser();
  }

  render () {
    if (this.props.config.isFetched) {
      return (
        <HashRouter
          getUserConfirmation={(message, callback) => {
            // eslint-disable-next-line no-alert
            const allowTransition = window.confirm(`Confirm: ${message}`);
            callback(allowTransition);
          }}>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/content/:type?/:id?/:param2?/:param3?/:param4?" component={Content} />
            <Route exact path="/tool-families/:type?/:id?" component={ToolFamilies} />
            <Route exact path="/tool/:id?" component={Tool} />
            <Route exact path="/users/:uid?" component={Users} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/statistics/logins" component={LoginsMap} />
            <Route exact path="/style" component={Style} />
            <Route
              exact
              path="/recipes/(market)?/:marketId?/(material)?/:materialId?/(finish)?/:finishId?/(machine)?/:machineModelId?/(tool-system)?/:toolSystemId?"
              component={RecipePicker} />
            <Route
              path="/"
              render={() => (
                <Redirect to="/recipes" />
              )} />
          </Switch>
        </HashRouter>
      );
    }
    return <WaitPage />;
  }
}

const mapStateToProps = (state: any) => ({ config: state.config });

const mapDispatchToProps = {
  getConfig,
  getAuthenticatedUser
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(TosApp);
