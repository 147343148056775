import React from 'react';
import Body from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import Content from 'domain/tool-families/components/view/content';

const ToolFamiliesPage = () => {
  return <Body
    topbar={<Menu />}
    content={<Content />}
  />;
};

export default ToolFamiliesPage;
