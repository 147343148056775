import React, { FC, useEffect, useCallback } from 'react';
import * as actions from 'domain/users/container/actions';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { TosRootState } from 'reducers';
import UserPage from 'domain/users/components/page';
import { UserApiModel } from './user-models';

type UserStoreProps = ReturnType<typeof mapStateToProps>;
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & UserStoreProps & RouteComponentProps;

export const UsersContainer: FC<Props> = ({
  getAllUsers,
  isFetchingUsers,
  getUserDetails,
  users,
  userDetails,
  saveUserDetails,
  ...props
}) => {
  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const onUserSelected = useCallback((id: string) => {
    props.history.push(`/users/${id}`);
    getUserDetails(id);
  }, [props.history, getUserDetails]
  );
  const onUserChanged = useCallback((user: UserApiModel) => {
    saveUserDetails(user);
  }, [saveUserDetails]);

  return <UserPage
    usersPending={isFetchingUsers}
    onUserSelected={onUserSelected}
    onUserChanged={onUserChanged}
    userDetails={userDetails}
    users={users} />;
};

const mapStateToProps = (state: TosRootState) => {
  return {
    users: state.users.items || [],
    isFetchingUsers: state.users.isFetching,
    userDetails: state.users.detailedItem
  };
};

const connector = connect(
  mapStateToProps,
  actions
);

export default connector(UsersContainer);
