import React from 'react';
import MarketAvailabilityRow from 'domain/tool-families/components/view/editor/market-availability-row';

type Props = {
  markets: Array<any>,
  availableOnMarkets: Array<string>,
  handleToggle: (marketId: string) => void
};

const MarketAvailability: React.FC<Props> = (props: Props) => {
  return <div className="market-availability">
    <div className="card">
      <div className="tool-family-table markets">
        <div className="list-header">
          <div className="name">Market Availability</div>
          <div className="action" />
        </div>
        <div className="row-container">
          { props.markets.map(m => (
            <MarketAvailabilityRow
              market={m}
              isChecked={props.availableOnMarkets.some(avail => avail === m.id)}
              key={m.id}
              handleToggle={props.handleToggle}
            />)
          )}
        </div>
      </div>
    </div>
  </div>;
};

export default MarketAvailability;
