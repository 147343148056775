const SESSION_KEY = 'sid';
const CONFIG_KEY = 'conf';

const storeSession = (sessionId: any) => {
  if (localStorage) {
    if (!sessionId) {
      localStorage.removeItem(SESSION_KEY);
    } else {
      localStorage.setItem(SESSION_KEY, sessionId);
    }
  }
};

const getSession = () => {
  if (localStorage) {
    return localStorage.getItem(SESSION_KEY);
  }
  return undefined;
};

const storeConfig = (configObject: any) => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(configObject));
};

const getConfig = () => {
  if (localStorage) {
    return localStorage.getItem(JSON.parse(CONFIG_KEY));
  }
  return undefined;
};

const storeString = (key: any, stringValue: any) => {
  if (localStorage) {
    localStorage.setItem(key, stringValue);
  }
};

const getString = (key: any) => {
  if (localStorage) {
    return localStorage.getItem(key);
  }
  return undefined;
};

export default {
  storeSession,
  getSession,
  storeConfig,
  getConfig,
  getString,
  storeString
};
