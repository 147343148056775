export default {
  get backendEndpoint () {
    return process.env.TOS_BACKEND_URL;
  },

  get backendApiKey () {
    return process.env.TOS_BACKEND_API_KEY;
  },

  get ENVIRONMENT () {
    return process.env.TOS_ENV;
  },

  get imageCdnUrl () {
    return process.env.TOS_IMAGE_CDN_URL;
  },

  get auth () {
    return {
      TOS_AUTH_AUTHORIZE_URL: process.env.TOS_AUTH_AUTHORIZE_URL,
      TOS_AUTH_CLIENT_ID: process.env.TOS_AUTH_CLIENT_ID,
      TOS_AUTH_CLIENT_TYPE: process.env.TOS_AUTH_CLIENT_TYPE,
      TOS_AUTH_REDIRECT_URI: process.env.TOS_AUTH_REDIRECT_URI
    };
  }
};
