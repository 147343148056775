import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import Wait from 'app/wait/wait';
import { TosRootState } from 'reducers';
import { AuthenticatedUser } from 'domain/login/container/login-reducer';
import { getConfig } from 'app/config-actions';
import { authorizeCode } from './actions';
import './validate-authentication.scss';

type Props = RouteComponentProps & {
  location: any
  isFetched: any
  message: any
  errorMessage: any
  config: any
  errors: any
  isAuthenticated: boolean
  isLoginFailed: boolean
  dispatchAuthorizeCode: (code: any) => void
  getConfig: () => void
  user?: AuthenticatedUser
};

interface State {
  code: any
  redirectPath: any,
  isCodeMissing: boolean
}

const queryParam = (queryStr: string, param: any) => {
  const paramPattern = `${param}=`;
  const s = queryStr.indexOf(paramPattern);
  if (s >= 0) {
    let e = queryStr.indexOf('&', s);
    e = (e > 0) ? e : queryStr.length;
    return queryStr.substring(s + paramPattern.length, e);
  }
  throw new Error(`Missing param named ${param}`);
};

class ValidateAuthenticationInternal extends PureComponent<Props, State> {
  constructor (props: any) {
    super(props);

    this.state = {
      code: '',
      isCodeMissing: false,
      redirectPath: ''
    };
  }

  componentDidUpdate () {
    this.attemptValidate(this.state.code);
  }

  attemptValidate (code?: string) {
    if (this.props.config.isFetched && code) {
      this.props.dispatchAuthorizeCode(code);
      this.setState({
        code: undefined
      });
    }
  }

  componentDidMount () {
    this.props.getConfig();
    const code = queryParam(this.props.location.search, 'code');
    const state = queryParam(this.props.location.search, 'state');
    this.attemptValidate(code);
    const decodedState = state ? decodeURIComponent(decodeURIComponent(state)) : '';
    this.setState({
      code,
      isCodeMissing: !code,
      redirectPath: decodedState.startsWith('/') ? decodedState : '/'
    });
  }

  render () {
    if (this.props.isAuthenticated) {
      if (this.props.user?.isRecipeAdmin || this.props.user?.isSuperAdmin) {
        return <Redirect to={{ pathname: this.state.redirectPath }} />;
      }
      return <div className="validation-page">
        <h1>Authorization error</h1>
        <em>You are no authorized for this application. Please, apply for privileges for recipe administration, from DS Construction squad.</em>
      </div>;
    }
    if (this.state.isCodeMissing) {
      return <div className="validation-page">
        <h1>Authentication error</h1>
        <em>Tool selector could not find code from ISAM authentication. Did you complete the login?</em>
      </div>;
    }
    if (this.props.isLoginFailed) {
      return <div className="validation-page">
        <h1>Login failure</h1>
        <em>Tool selector could not validate login via ISAM. Did you complete the login?</em>
        {this.props.errors && <p>{JSON.stringify(this.props.errors)}</p>}
      </div>;
    }
    if (this.props.errors) {
      return <div className="validation-page">
        <h1>Error</h1><em>{JSON.stringify(this.props.errors)}</em>
      </div>;
    }
    return <Wait />;
  }
}

const mapStateToProps = (state: TosRootState) => {
  const { config } = state;
  return {
    user: state.login.authenticatedUser,
    errors: state.errors,
    config,
    isAuthenticated: state.login.isAuthenticated,
    isLoginFailed: state.login.isFailed
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatchAuthorizeCode: (code: any) => dispatch(authorizeCode(code)),
  getConfig: () => dispatch(getConfig())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValidateAuthenticationInternal));
