/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-console */
import extractError from 'app/utils/error-message';
// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'tos-api';

const combinedHeaders = (sessionId: string, apiKey: string, options: any) => {
  const includedHeaders = (options && options.headers) ? options.headers : {};
  const headers = {
    Accept: 'application/json',
    'x-api-key': apiKey,
    ...includedHeaders
  };

  if (!options || !options.noSession) {
    if (sessionId) {
      // ToDo, remove when properly authenticated
      headers['X-Session'] = sessionId;
    }
  }
  if (options && (options.method === 'POST' || options.method === 'PATCH')) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
};
// Fetches an API response.
const callApi = async (endpoint:string, options: any, sessionId: string, apiKey: string) => {
  const fetchOptions = {
    ...options,
    headers: combinedHeaders(sessionId, apiKey, options)
  };
  return fetch(endpoint, fetchOptions)
    .then(async (response) => {
      if (!response.ok) {
        throw response;
      }
      let json;
      try {
        json = await response.json();
      } catch (x) {
        console.log('INVALID JSON', endpoint);
        throw x;
      }
      return json;
    });
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store: any) => (next: Function) => async (action: any) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  // eslint-disable-next-line prefer-const
  let { pathname, endpoint, options } = callAPI;
  const { types } = callAPI;

  if (!pathname && !endpoint) {
    throw new Error('Please specify pathname');
  }
  const state = store.getState();
  if (pathname) {
    const b = state.config.item.endpoint;

    const slashChar = (b === '/' || pathname.indexOf('/') === 0) ? '' : '/';

    endpoint = `${b}${slashChar}${pathname}`;
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    console.log('error error error', callAPI);
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = (data: any) => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  const { context } = callAPI;

  next(actionWith({ type: requestType, context }));

  const sessionId = state.login && state.login.isAuthenticated ? state.login.sessionId : undefined;

  try {
    const results = await callApi(endpoint, options, sessionId, state.config.item.apiKey);
    return next(actionWith({
      response: results,
      context,
      type: successType
    }));
  } catch (err) {
    const error = await extractError(err);
    if (!options || !options.suppressFailure) {
      return next(actionWith({
        type: failureType,
        context,
        error
      }));
    }
    console.log(`Supressing incoming error from ${err.endpoint}`);
    console.log(error);

    return next(actionWith({
      type: failureType,
      supressedError: error
    }));
  }
};
