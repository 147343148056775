import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as actions from 'domain/content/container/actions';
import {
  MaterialModelWithTexts,
  MaterialTypeModel,
} from 'domain/models/material-type-model';
import { TosRootState } from 'reducers';
import AddNewMaterial from 'domain/content/components/materials/add-new-material/add-new-material';
import EditMaterial from 'domain/content/components/materials/edit-material/edit-material';
import './materials.scss';
import { MaterialFormModel } from './add-new-material/material-form-model';

interface Props
  extends RouteComponentProps<{
    materialTypeId: string;
    materialId: string;
    languageId: string;
  }> {
  selectedId: string;
  createdMaterial: any;
  materials?: Array<MaterialTypeModel>;
  loadedMaterial: MaterialModelWithTexts;
  isAddingNewMaterial: boolean;
  isLoadingMaterial: boolean;
  isSavingMaterialTexts: boolean;
  isSavingNewMaterial: boolean;
  getMaterial: typeof actions.getMaterial;
  saveTextItems: typeof actions.saveTextItems;
  saveNewMaterial: typeof actions.saveNewMaterial;
  saveMaterialImage: typeof actions.saveMaterialImage;
  setIsAdding: Function;
  suggestedUIOrder?: string;
  isLoadingSuggestedUIOrder: boolean;
  getSuggestedMaterialUIOrder: typeof actions.getSuggestedMaterialUIOrder;
  resetSuggestedUIOrder: typeof actions.resetSuggestedUIOrder;
}

export const initialFormState: MaterialFormModel = {
  materialTypeId: '',
  name: '',
  description: '',
  uiOrder: ''
};

const Materials: React.FC<Props> = (props) => {
  const [formState, setFormState] = useState<MaterialFormModel>({
    ...initialFormState,
    materialTypeId: props.match.params.materialTypeId
  });

  const { getMaterial, setIsAdding, resetSuggestedUIOrder } = props;

  const handleLanguageClick = useCallback(
    (languageId: string) => {
      props.history.push(
        `/content/material-types/${props.match.params.materialTypeId}/materials/${props.match.params.materialId}/${languageId}`
      );
    },
    [props.history, props.match.params]
  );

  useEffect(() => {
    if (!props.isAddingNewMaterial && !props.match.params.languageId) {
      handleLanguageClick('en');
    }
  }, [props.match.params.languageId, handleLanguageClick, props.isAddingNewMaterial]);

  // Fetch material when top materialId changes
  useEffect(() => {
    if (props.match.params.materialId === 'new' && !props.isAddingNewMaterial) {
      setIsAdding(true);
      return;
    }
    if (props.isSavingMaterialTexts || !props.match.params.materialId || props.match.params.materialId === 'new') {
      return;
    }
    getMaterial(props.match.params.materialId);
  }, [getMaterial, props.match.params.materialId, props.isSavingMaterialTexts, setIsAdding, props.isAddingNewMaterial]);

  useEffect(() => {
    if (props.isAddingNewMaterial) {
      setFormState(initialFormState);
      resetSuggestedUIOrder();
    }
  }, [props.isAddingNewMaterial, resetSuggestedUIOrder]);

  useEffect(() => {
    if (props.createdMaterial) {
      props.history.push(`/content/material-types/${props.createdMaterial.materialTypeId}/materials/${props.createdMaterial.id}/en`);
    }
  }, [props.createdMaterial, props.history, props.match.params]);

  const handleUpload = useCallback(
    async (imageId: string, file: File) => {
      if (!props.loadedMaterial?.id) {
        throw new Error('Illegal state, missing id of loadedMaterial');
      }
      props.saveMaterialImage(props.loadedMaterial.id, imageId, file.name);
    },
    [props]
  );

  return (
    <div className="materials-view">
      {props.isAddingNewMaterial ? (
        <AddNewMaterial
          formState={formState}
          setFormState={setFormState}
          materialTypes={props.materials}
          saveNewMaterial={props.saveNewMaterial}
          isSavingNewMaterial={props.isSavingNewMaterial}
          setIsAdding={setIsAdding}
          getSuggestedUIOrder={props.getSuggestedMaterialUIOrder}
          suggestedUIOrder={props.suggestedUIOrder}
          isLoadingSuggestedUIOrder={props.isLoadingSuggestedUIOrder}
        />
      ) : (
        props.selectedId && (
          <EditMaterial
            isLoadingMaterial={props.isLoadingMaterial}
            languageId={props.match.params.languageId}
            selectedId={props.selectedId}
            formState={formState}
            onFileUploaded={handleUpload}
            setFormState={setFormState}
            handleLanguageClick={handleLanguageClick}
            loadedMaterial={props.loadedMaterial}
            saveTextItems={props.saveTextItems}
            isSavingMaterialTexts={props.isSavingMaterialTexts}
          />
        )
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getMaterial: actions.getMaterial,
  saveTextItems: actions.saveTextItems,
  saveNewMaterial: actions.saveNewMaterial,
  saveMaterialImage: actions.saveMaterialImage,
  getSuggestedMaterialUIOrder: actions.getSuggestedMaterialUIOrder,
  resetSuggestedUIOrder: actions.resetSuggestedUIOrder,
};

const mapStateToProps = ({ content: { materials: contentMaterials }, materials }: TosRootState) => ({
  materials: materials.items,
  createdMaterial: contentMaterials.createdMaterial,
  loadedMaterial: contentMaterials.loadedMaterial,
  isLoadingMaterial: contentMaterials.isLoadingMaterial,
  isSavingMaterialTexts: contentMaterials.isSavingMaterialTexts,
  isSavingNewMaterial: contentMaterials.isSavingNewMaterial,
  suggestedUIOrder: contentMaterials.suggestedUIOrder,
  isLoadingSuggestedUIOrder: contentMaterials.isLoadingSuggestedUIOrder
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withRouter(Materials));
