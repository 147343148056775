import {
  CLEAR_SEARCH_TOOL_RES,
  SEARCH_TOOL_REQ,
  SEARCH_TOOL_REQ_FAILURE,
  SEARCH_TOOL_REQ_SUCCESS
} from 'reducers/action-constants';

const getInitialState = () => {
  return {
    isFetching: false
  };
};

export default (state: any = getInitialState(), action: any) => {
  switch (action.type) {
    case CLEAR_SEARCH_TOOL_RES:
      return getInitialState();
    case SEARCH_TOOL_REQ:
      return {
        ...state,
        isFetching: true
      };
    case SEARCH_TOOL_REQ_SUCCESS:
      return {
        isFetching: false,
        items: action.response
      };
    case SEARCH_TOOL_REQ_FAILURE:
      return getInitialState();
    default:
      return state;
  }
};
