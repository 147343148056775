import * as React from 'react';
import SelectableItem from 'domain/recipe-picker/components/recipe-selectables/item/selectable-item';
import { UIAvailableSelectableModel } from 'domain/models/ui-available-selectable-model';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import './selectables.scss';

interface Props {
  title: string
  selectables?: Array<UIAvailableSelectableModel>
  propName: string
  selectedFilter: RecipeFiltersModel
  allAvailible?: boolean
  onSelected: (props: string, selected: UIAvailableSelectableModel) => void
}

const RecipeSelectables: React.FC<Props> = ({ title, selectables, propName, onSelected, selectedFilter, allAvailible }: Props) => {
  return <div className="selectables-comp">
    <div>{title}</div>
    <div>
      <div className="list">
        { selectables && selectables.map(s => {
          // @ts-ignore
          const isSelected = selectedFilter[propName] === s.value;
          return <SelectableItem
            key={s.value}
            selectable={s}
            allAvailible={allAvailible}
            isSelected={isSelected}
            onClick={(selected: UIAvailableSelectableModel) => { onSelected(propName, selected); }} />;
        })}
      </div>
    </div>
  </div>;
};

export default RecipeSelectables;
