import ContentList from 'domain/content/components/content-list/content-list';
import { MaterialTypeModel } from 'domain/models/material-type-model';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import Body from 'app/body-with-left-pane/body-with-top-menu';
import Menu from 'app/top-menu/menu';
import { TosRootState } from 'reducers';
import { FinishTypeModel } from 'domain/models/finish-type-model';
import { MachineType } from 'domain/models/machine-type-model';
import { ToolSystemModel } from 'domain/models/tool-system-model';
import Materials from 'domain/content/components/materials/materials';
import Finishes from 'domain/content/components/finishes/finishes';
import Machines from 'domain/content/components/machines/machines';
import ToolSystems from 'domain/content/components/tool-systems/tool-systems';
import MaterialTypes from 'domain/content/components/material-types/material-types';
import MachineTypes from 'domain/content/components/machine-types/machine-types';
import FinishTypes from 'domain/content/components/finish-types/finish-types';
import * as actions from './actions';
import './content.scss';

interface Props extends RouteComponentProps<{ type: string; id: string, param3?: string }> {
  // machineModels: Array<MachineModelModel> | undefined;
  machineTypes: Array<MachineType> | undefined;
  materialTypes: Array<MaterialTypeModel> | undefined;
  finishTypes: Array<FinishTypeModel> | undefined;
  toolSystems: Array<ToolSystemModel> | undefined;
  getMachineModels: typeof actions.getMachineModels;
  getMaterials: typeof actions.getMaterials;
  getFinishes: typeof actions.getFinishes;
  deleteMachineModel: typeof actions.deleteMachineModel;
  deleteMachineType: typeof actions.deleteMachineType;
  deleteMaterial: typeof actions.deleteMaterial;
  deleteMaterialType: typeof actions.deleteMaterialType;
  deleteFinish: typeof actions.deleteFinish;
  deleteFinishType: typeof actions.deleteMachineType;
  getToolSystems: typeof actions.getToolSystems;
  deleteToolSystem: typeof actions.deleteToolSystem;
}

const Content: React.FC<Props> = (props) => {
  const [isAddingItem, setIsAddingItem] = useState<boolean>(false);

  const {
    getMachineModels,
    getMaterials,
    getFinishes,
    deleteMachineModel,
    deleteMachineType,
    deleteMaterial,
    deleteMaterialType,
    deleteFinish,
    deleteFinishType,
    getToolSystems,
    deleteToolSystem } = props;

  // Load machine models and materials on mount
  useEffect(() => {
    getMachineModels();
    getMaterials();
    getFinishes();
    getToolSystems();
  }, [getMachineModels, getMaterials, getFinishes, getToolSystems]);

  // Redirect user when user clicks on row in left most list
  const onRowClick = useCallback(
    (id: string, parentId?: string) => {
      setIsAddingItem(false);
      const parent = parentId ? `/${parentId}` : '';
      props.history.push(`/content/${props.match.params.type}${parent}/${id}`);
    },
    [props.history, props.match.params.type]
  );

  const onCreateClick = useCallback((type: string) => {
    setIsAddingItem(true);
    let pathSuffix = '';
    if (type === 'material') {
      pathSuffix = '/_/materials/new';
    } else if (type === 'finish') {
      pathSuffix = '/_/finishes/new';
    } else if (type === 'machine') {
      pathSuffix = '/_/machineModels/new';
    } else if (
      type === 'material-type'
      || type === 'finish-type'
      || type === 'tool-systems'
      || type === 'machine-type'
    ) {
      pathSuffix = '/new';
    }
    props.history.push(`/content/${props.match.params.type}${pathSuffix}`);
  }, [props.history, props.match.params.type]);

  const onRemoveClick = useCallback(
    id => {
      switch (props.match.params.type) {
        case 'machine-types': {
          if (props.machineTypes?.find(type => type.id === id) !== undefined) {
            deleteMachineType(id);
          } else {
            deleteMachineModel(id);
          }
          break;
        }
        case 'material-types': {
          if (props.materialTypes?.find(type => type.id === id) !== undefined) {
            deleteMaterialType(id);
          } else {
            deleteMaterial(id);
          }
          break;
        }
        case 'finish-types': {
          if (props.finishTypes?.find(type => type.id === id) !== undefined) {
            deleteFinishType(id);
          } else {
            deleteFinish(id);
          }
          break;
        }
        case 'tool-systems': {
          deleteToolSystem(id);
          break;
        }
        default:
          return;
      }

      if (props.match.params.id === id || props.match.params.param3 === id) {
        props.history.push(`/content/${props.match.params.type}`);
      }
    },
    [
      deleteMachineType,
      deleteMachineModel,
      deleteMaterial,
      deleteFinish,
      deleteMaterialType,
      deleteToolSystem,
      props.match.params,
      props.history,
      props.materialTypes,
      props.finishTypes
    ]
  );

  return (
    <Body
      topbar={<Menu />}
      content={
        <div className="content-view">
          <ContentList
            materialTypes={props.materialTypes}
            finishTypes={props.finishTypes}
            machineTypes={props.machineTypes}
            toolSystems={props.toolSystems}
            onRowClick={onRowClick}
            onRemoveClick={onRemoveClick}
            onCreateClick={onCreateClick}
          />
          <div className="content-view-content">
            <Switch>
              <Route exact path="/content/material-types/:materialTypeId/:languageId?">
                <MaterialTypes
                  isAddingNewMaterialType={isAddingItem}
                  selectedId={props.match.params.id}
                  setIsAdding={setIsAddingItem}
                />
              </Route>
              <Route exact path="/content/material-types/:materialTypeId/materials/:materialId/:languageId?">
                <Materials
                  isAddingNewMaterial={isAddingItem}
                  selectedId={props.match.params.id}
                  setIsAdding={setIsAddingItem}
                />
              </Route>
              <Route exact path="/content/finish-types/:finishTypeId/:languageId?">
                <FinishTypes
                  isAddingNewFinishType={isAddingItem}
                  selectedId={props.match.params.id}
                  setIsAdding={setIsAddingItem}
                  />
              </Route>
              <Route exact path="/content/finish-types/:finishTypeId/finishes/:finishId?/:languageId?">
                <Finishes
                  isAddingNewFinish={isAddingItem}
                  setIsAdding={setIsAddingItem}
                  />
              </Route>
              <Route exact path="/content/machine-types/:machineTypeId?/:languageId?">
                <MachineTypes
                  isAddingNewMachineType={isAddingItem}
                  selectedId={props.match.params.id}
                  setIsAdding={setIsAddingItem}
                />
              </Route>
              <Route exact path="/content/machine-types/:machineTypeId?/machineModels/:machineId?">
                <Machines
                  isAddingNewModel={isAddingItem}
                />
              </Route>
              <Route path="/content/tool-systems/:toolSystemId?/:languageId?">
                <ToolSystems
                  isAddingNewToolSystem={isAddingItem}
                />
              </Route>
            </Switch>
          </div>
        </div>
      }
    />
  );
};

const mapDispatchToProps = {
  getMachineModels: actions.getMachineModels,
  deleteMachineModel: actions.deleteMachineModel,
  deleteMachineType: actions.deleteMachineType,
  getFinishes: actions.getFinishes,
  deleteFinish: actions.deleteFinish,
  deleteFinishType: actions.deleteFinishType,
  getMaterials: actions.getMaterials,
  deleteMaterial: actions.deleteMaterial,
  deleteMaterialType: actions.deleteMaterialType,
  getToolSystems: actions.getToolSystems,
  deleteToolSystem: actions.deleteToolSystem,
};

const mapStateToProps = ({ machineModels, materials, finishes, toolSystems }: TosRootState) => ({
  machineTypes: machineModels.items,
  materialTypes: materials.items,
  finishTypes: finishes.items,
  toolSystems: toolSystems.items
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Content);
