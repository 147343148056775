import React, { FC, useState } from 'react';
import { useSelector } from 'reducers/index';
import { useDispatch } from 'react-redux';
import { MachineModelId } from 'domain/models/machine-type-model';
import * as actions from 'domain/recipe-designer/container/actions';
import CopySelections from 'domain/recipe-designer/components/editor/actions/copy/components/copy-selections';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import { RecipeId } from 'domain/models/recipe-model';

interface Props {
  onDismissCopy: () => void
}

const CopyMachineConfig: FC<Props> = ({ onDismissCopy }) => {
  const dispatch = useDispatch();
  const configuredMachineModels: Array<MachineModelId> =
    useSelector(state => state.recipesFilter.recipes &&
      state.recipesFilter.recipes.filters &&
      state.recipesFilter.recipes.filters.machines
    );
  const copyResults = useSelector(state => state.recipe.isCopied && state.recipe.copyResults?.results);

  const recipeId: RecipeId | any =
    useSelector(state => state.recipesFilter.recipes &&
      state.recipesFilter.recipes.recipes &&
      state.recipesFilter.recipes.recipes.length === 1 &&
      state.recipesFilter.recipes.recipes[0]
    );
  const machines: Array<UISelectableModel> = useSelector(state => state.machineModels.selectables || []);

  const currentMachineModelId: MachineModelId = useSelector(state => state.recipesFilter.fetchedFilter?.machineModelId || '');
  const [selectedMachine, setSelectedMachine] = useState<string>('');

  const handleCopy = () => {
    dispatch(actions.copyMachineConfig(recipeId, currentMachineModelId, selectedMachine));
  };

  const selectableMachines = machines.filter(m => configuredMachineModels.some(id => id === m.value));

  return <CopySelections
    copyResults={copyResults}
    onCopyClicked={handleCopy}
    onDismissCopy={onDismissCopy}
    onMachineSelected={setSelectedMachine}
    selectedMachine={selectedMachine}
    selectableMachines={selectableMachines}
    />;
};

export default CopyMachineConfig;
