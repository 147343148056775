import React, { FC } from 'react';
import { UISelectableModel } from 'domain/models/ui-selectable-model';
import { MachineModelConfigurationModel, keyFrom } from 'domain/recipe-designer/container/machine-configurations-reducer';

import SelectOne from 'domain/recipe-picker/components/select-one/select-one';
import './config-selector.scss';

interface Props {
  readonly toolSelectionId: string
  readonly configurationId?: string
  readonly onSelection: (configurationId: string) => void
  readonly machineModelId: string
  configMap: Map<string, MachineModelConfigurationModel>
}

const ConfigSelector: FC<Props> = ({ toolSelectionId, configurationId, onSelection, configMap, machineModelId }: Props) => {
  const configItems = configMap.get(keyFrom({ machineModelId, toolSelectionId }));
  if (configItems && configItems.isFetching) {
    return <div className="conf-sel-cmp empty">Wait...</div>;
  }
  if (!configItems || !configItems.validConfigurations.length) {
    return <div className="conf-sel-cmp empty">No valid config</div>;
  }
  const configs = [...configItems.validConfigurations];
  configs.sort((i1, i2) => (parseInt(i1.toolQuantity, 10) - parseInt(i2.toolQuantity, 10)));

  return <div className="conf-sel-cmp">
    <div>
      <SelectOne
        title="Default Configuration"
        items={configs}
        propName="defaultConf"
        itemsListLabelPropName={(cfg: any) => `${cfg.connectorTypeId} ${cfg.toolQuantity}`}
        selectedValue={configurationId}
        itemsListValuePropName="id"
        onSelected={(name:any, item: UISelectableModel) => onSelection(item.value)} />
    </div>
  </div>;
};

export default ConfigSelector;
