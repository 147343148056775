import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { FinishFormModel } from 'domain/content/components/finishes/finish-form/finish-form-model';
import FinishForm from 'domain/content/components/finishes/finish-form/finish-form';
import { FinishTypeModel } from 'domain/models/finish-type-model';
import List, { Header } from 'app/list/list';

const headers: Array<Header> = [
  {
    text: 'Finish Type',
    name: 'finidhTypeId',
  },
];
export interface Props {
  formState: FinishFormModel;
  setFormState: Dispatch<SetStateAction<FinishFormModel>>;
  finishTypes?: Array<FinishTypeModel>
  isSavingNewFinish?: boolean;
  saveNewFinish?: any;
  isLoadingSuggestedUIOrder: boolean;
  suggestedUIOrder?: string;
  setIsAdding: Function;
  getSuggestedUIOrder: Function;
}

const isFormSubmittable = (formState: FinishFormModel) => formState.name && formState.subtitle && formState.uiOrder;

const AddNewFinish: React.FC<Props> = (props) => {
  const { setFormState, saveNewFinish, setIsAdding, getSuggestedUIOrder } = props;

  const onSaveNewFinish = useCallback(() => {
    saveNewFinish(
      props.formState.finishTypeId,
      props.formState.name,
      props.formState.subtitle,
      props.formState.description,
      props.formState.uiOrder
    );
    setIsAdding(false);
  }, [saveNewFinish, props.formState, setIsAdding]);

  const onRowClick = useCallback(
    (id: string) => {
      setFormState(state => ({
        ...state,
        finishTypeId: id,
      }));
      getSuggestedUIOrder(id);
    },
    [setFormState, getSuggestedUIOrder]
  );

  const selectableValues = {
    finishTypeId: props.finishTypes?.map(finishType => ({
      id: finishType.id,
      name: finishType.name,
    })),
  };

  return (
    <div className="add-new-finish">
      <div className="two-part">
        <List
          path="content"
          selectedType="finishTypeId"
          selectedId={props.formState.finishTypeId}
          headers={headers}
          lists={selectableValues}
          onRowClick={onRowClick}
          isItemsDeletable={false}
          listItemsUppercase
        />
        <div className="flex flex-col">
          <FinishForm
            formState={props.formState}
            setFormState={setFormState}
            isNew
          />
          <div className="button-wrapper">
            <button
              className="large primary"
              onClick={onSaveNewFinish}
              disabled={props.isSavingNewFinish || !isFormSubmittable(props.formState)}
              type="button">
              Save
            </button>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default AddNewFinish;
