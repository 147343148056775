import React from 'react';
import { EditingModelConfigModel } from 'domain/models/machine-type-model';
import ConfigTable from './config-table';
import './configurations.scss';

export interface Props {
  configurations: Array<EditingModelConfigModel>;
  onConnectionTypeClick(connectorTypeId: string, toolQuantity: string): void;
}

const Configurations: React.FC<Props> = ({
  configurations,
  onConnectionTypeClick,
}) => {
  return (
    <div className="configurations card">
      <div className="card-title">Configurations</div>
      <span className="subtitle">
        Enter the possible tool configurations for the machine, depending on tool
        type
      </span>
      <ConfigTable
        configurations={configurations}
        onClickHandler={onConnectionTypeClick}
      />
      <span className="subtitle">
        <p><i className="fas fa-lock" /> Configuration appears in one or more recipes</p>
      </span>

    </div>
  );
};

export default Configurations;
