import { combineReducers } from 'redux';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import content from 'domain/content/container/reducer';
import recipes from 'domain/all-recipes/container/recipes-reducer';
import recipe from 'reducers/recipe-reducer';
import materials from 'domain/all-recipes/container/materials-reducer';
import finishes from 'domain/all-recipes/container/finishes-reducer';
import toolSystems from 'domain/all-recipes/container/tool-systems-reducer';
import families from 'domain/recipe-designer/container/families-reducer';
import markets from 'domain/markets/container/markets-reducer';
import recipesFilter from 'domain/recipe-picker/container/recipes-filter-reducer';
import recipePicker from 'domain/recipe-picker/container/recipe-picker-reducer';
import machineRecipe from 'domain/recipe-designer/container/machine-recipe-reducer';
import toolSearch from 'domain/tool-families/container/tool-search-reducer';
import tool from 'domain/tools/container/reducer';
import machineModels from 'reducers/machineModels-reducer';
import allToolFamilies from 'domain/tool-families/container/all-tool-families-reducer';
import machineModelConfigurations from 'domain/recipe-designer/container/machine-configurations-reducer';
import recipeConfig from 'domain/recipe-designer/container/recipe-config-reducer';
import dimrTool from 'domain/tool-families/container/dimr-tool-reducer';
import login from 'domain/login/container/login-reducer';
import users from 'domain/users/container/reducer';
import imageUrl from 'reducers/image-url-reducer';
import toolFamily from 'domain/tool-families/container/tool-family-reducer';
import stat from 'domain/stats/container/stat-reducer';
import config from './config-reducer';
import errors from './error-reducer';

const tosApp = combineReducers({
  config,
  content,
  errors,
  finishes,
  materials,
  toolSystems,
  markets,
  machineModels,
  recipes,
  recipe,
  recipesFilter,
  recipePicker,
  machineRecipe,
  machineModelConfigurations,
  families,
  toolSearch,
  allToolFamilies,
  dimrTool,
  recipeConfig,
  login,
  toolFamily,
  users,
  imageUrl,
  stat,
  tool
});

export default tosApp;

export type TosRootState = ReturnType<typeof tosApp>;
export const useSelector: TypedUseSelectorHook<TosRootState> = useReduxSelector;
