import React, { FC } from 'react';
import './area-input.scss';

interface Props {
  readonly area?: string
  readonly onSelection: (area: string) => void
}

const AreaInput: FC<Props> = ({ area, onSelection }: Props) => {
  return <div className="area-cmp">
    <div>
      Lifetime
    </div>
    <div>
      Enter the expected lifetime for this configuration
    </div>
    <div>
      <input
        type="text"
        name="area"
        value={area || ''}
        autoComplete="off"
        placeholder="m2"
        title="Area"
        onChange={(e) => onSelection(e.target.value)} />
    </div>
  </div>;
};

export default AreaInput;
