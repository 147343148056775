import { CALL_API } from 'app/middleware/api';
import { AnyAction } from 'redux';
import { success } from 'app/general/messages/toast-util';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RecipeFiltersModel } from 'domain/models/recipe-filters-model';
import { RecipeSelection, RecipeStep } from 'domain/recipe-designer/container/machine-recipe-reducer';
import { keyFrom } from 'domain/recipe-designer/container/machine-configurations-reducer';
import { RecipeConfigActionTypes, ToolFamilyId } from 'domain/recipe-designer/container/recipe-config-reducer';

import {
  RECIPE_SAVE_STEPS_REQ, RECIPE_SAVE_STEPS_REQ_SUCCESS, RECIPE_SAVE_STEPS_REQ_FAILURE,
  GET_RECIPE_REQ, GET_RECIPE_REQ_SUCCESS, GET_RECIPE_REQ_FAILURE,
  GET_FAMILY_DETAIL_REQ, GET_FAMILY_DETAIL_REQ_SUCCESS, GET_FAMILY_DETAIL_REQ_FAILURE,
  RECIPE_IDS_REQ, RECIPE_IDS_REQ_SUCCESS, RECIPE_IDS_REQ_FAILURE,
  GET_FAMILIES_REQ, GET_FAMILIES_REQ_SUCCESS, GET_FAMILIES_REQ_FAILURE,
  MACHINE_RECIPE_REQ, MACHINE_RECIPE_REQ_SUCCESS, MACHINE_RECIPE_REQ_FAILURE,
  GET_TOOLSEL_CONFIG_REQ, GET_TOOLSEL_CONFIG_REQ_SUCCESS, GET_TOOLSEL_CONFIG_REQ_FAILURE,
  ADD_RECIPE_CONFIG, REMOVE_RECIPE_CONFIG, RECIPE_ADD_STEP, RECIPE_REMOVE_STEP, RECIPE_SET_STEPS,
  CREATE_RECIPE_REQ, CREATE_RECIPE_REQ_SUCCESS, CREATE_RECIPE_REQ_FAILURE,
  COPY_MACHINE_CONF_REQ, COPY_MACHINE_CONF_REQ_SUCCESS, COPY_MACHINE_CONF_REQ_FAILURE,
  SET_RECIPE_STEP_CONFIG_ONGOING, CLEAR_TOOLSEL_CONFIG
} from 'reducers/action-constants';
import { RecipeId } from 'domain/models/recipe-model';
import { MachineModelId } from 'domain/models/machine-type-model';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { TosRootState } from 'reducers';
import { FinishId } from 'domain/models/finish-type-model';
import { MarketId } from 'domain/markets/container/markets-reducer';
import { MaterialId } from 'domain/models/material-type-model';
import { getRecipes as externalGetRecipes } from 'domain/recipe-picker/container/actions';

export const getRecipes = externalGetRecipes;

const createCreateRecipeAction = (marketId?: MarketId, materialId?: MaterialId, finishId?: FinishId):AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [CREATE_RECIPE_REQ, CREATE_RECIPE_REQ_SUCCESS, CREATE_RECIPE_REQ_FAILURE],
    pathname: '/v1/surface-prep/admin/recipes/',
    options: {
      method: 'POST',
      body: JSON.stringify({ marketId, materialId, finishId })
    }
  }
});

export const createRecipe = (marketId?: MarketId, materialId?: MaterialId, finishId?: FinishId): ThunkAction<Promise<{isOk: boolean}>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    const results = await dispatch(createCreateRecipeAction(marketId, materialId, finishId));
    return {
      isOk: results.type === 'CREATE_RECIPE_REQ_SUCCESS'
    };
  };
};

const createGetRecipeAction = (id: string):AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [GET_RECIPE_REQ, GET_RECIPE_REQ_SUCCESS, GET_RECIPE_REQ_FAILURE],
    pathname: `/v1/surface-prep/recipes/${id}`,
    context: {
      pathname: `/v1/surface-prep/recipes/${id}`
    }
  }
});

export const getRecipe = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(createGetRecipeAction(id));
  };
};

const createFindRecipesAction = (filter: RecipeFiltersModel) => {
  const { machineModelId, toolSystemId } = filter;
  const queryParams = [];
  if (machineModelId) {
    queryParams.push(`machineModelId=${machineModelId}`);
  }
  if (toolSystemId) {
    queryParams.push(`toolSystemId=${toolSystemId}`);
  }

  const pathname = `/v1/surface-prep/admin/recipes?${queryParams.join('&')}`;

  return {
    [CALL_API]: {
      types: [RECIPE_IDS_REQ, RECIPE_IDS_REQ_SUCCESS, RECIPE_IDS_REQ_FAILURE],
      pathname,
      context: {
        filter
      }
    }
  };
};

/**
 * Requests all recipes
 */
export const findRecipes = (filter: RecipeFiltersModel) => (dispatch: Function) => dispatch(createFindRecipesAction(filter));

const createCopyAction = (recipeId: RecipeId, targetMachineModel: MachineModelId, sourceMachineModel: MachineModelId): AnyAction => {
  return {
    type: 'thunk',
    [CALL_API]: {
      types: [COPY_MACHINE_CONF_REQ, COPY_MACHINE_CONF_REQ_SUCCESS, COPY_MACHINE_CONF_REQ_FAILURE],
      pathname: `/v1/surface-prep/admin/recipes/${recipeId}/copy`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          sourceMachineModel,
          targetMachineModel
        })
      }
    }
  };
};

/**
 * Saves the steps of a recipe (as populated in machineRecipe store)
 */
export const copyMachineConfig = (
  recipeId: RecipeId,
  targetMachine: MachineModelId,
  sourceMachine: MachineModelId
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any): Promise<any> => {
    const outcome = await dispatch(createCopyAction(recipeId, targetMachine, sourceMachine));
    if (outcome.type === COPY_MACHINE_CONF_REQ_SUCCESS) {
      const store: TosRootState = getState();
      setTimeout(() => {
        dispatch(getMachineRecipe(recipeId, { machineModelId: targetMachine }, true));
      }, 1000);
      dispatch(getRecipes(store.recipesFilter.fetchedFilter || {}));
      if (store.recipe.copyResults) {
        store.recipe.copyResults.results.forEach(item => {
          dispatch(getConfigurations(item.toolSelectionId, item.machineModelId));
        });
      }
    }
  };
};

const createMachineRecipeAction = (recipeId: string, filter: RecipeFiltersModel): AnyAction => {
  const { machineModelId, toolSystemId } = filter;
  const queryParams = [];
  if (machineModelId) {
    queryParams.push(`machineModelId=${machineModelId}`);
  }
  if (toolSystemId) {
    queryParams.push(`toolSystemId=${toolSystemId}`);
  }
  const pathname = `/v1/surface-prep/admin/recipes/${recipeId}/steps?${queryParams.join('&')}`;

  return {
    type: 'thunk',
    [CALL_API]: {
      types: [MACHINE_RECIPE_REQ, MACHINE_RECIPE_REQ_SUCCESS, MACHINE_RECIPE_REQ_FAILURE],
      pathname,
      context: {
        recipeId,
        machineModelId,
        toolSystemId
      }
    }
  };
};

export const getMachineRecipe = (
  recipeId: string,
  filter: RecipeFiltersModel,
  ignoreCache = false
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const state: TosRootState = getState();
    const { params } = state.machineRecipe;

    if (ignoreCache || !params
      || params.machineModelId !== filter.machineModelId
      || params.toolSystemId !== filter.toolSystemId
      || params.recipeId !== recipeId) {
      return dispatch(createMachineRecipeAction(recipeId, filter));
    }
    return undefined;
  };
};

const createSaveRecipeStepsAction = (recipeId: string, toolFamilyIds: Array<{toolFamilyId: ToolFamilyId}>, machineModelId?: string): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [RECIPE_SAVE_STEPS_REQ, RECIPE_SAVE_STEPS_REQ_SUCCESS, RECIPE_SAVE_STEPS_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/recipes/${recipeId}/steps`,
    options: {
      method: 'PUT',
      body: JSON.stringify(toolFamilyIds)
    },
    context: {
      recipeId,
      machineModelId
    }
  }
});

/**
 * Saves the steps of a recipe (as populated in machineRecipe store)
 */
export const saveRecipeSteps = (recipeId: RecipeId, machineModelId?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any): Promise<any> => {
    const store: TosRootState = getState();
    if (!store.machineRecipe.item) {
      throw new Error('Illegal state, machineRecipe is not populated');
    }
    const toolFamilyIds = store.machineRecipe.item.steps.map(s => ({ toolFamilyId: s.toolFamilyId }));
    const outcome = await dispatch(createSaveRecipeStepsAction(recipeId, toolFamilyIds, machineModelId));
    if (outcome.type === RECIPE_SAVE_STEPS_REQ_SUCCESS) {
      success('Recipe steps saved successfully');
    }
    return outcome;
  };
};

const createFamiliesAction = (): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [GET_FAMILIES_REQ, GET_FAMILIES_REQ_SUCCESS, GET_FAMILIES_REQ_FAILURE],
    pathname: '/v1/surface-prep/admin/tool-families/'
  }
});

/**
 * Requests all tool families
 */
export const getFamilies = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(createFamiliesAction());
  };
};

const createGetFamilyAction = (toolFamilyId: string): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [GET_FAMILY_DETAIL_REQ, GET_FAMILY_DETAIL_REQ_SUCCESS, GET_FAMILY_DETAIL_REQ_FAILURE],
    pathname: `/v1/surface-prep/admin/tool-families/${toolFamilyId}`,
    context: { toolFamilyId }
  }
});

/**
 * Requests all tool families
 */
export const getFamily = (toolFamilyId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const tf = getState().families.itemsById.get(toolFamilyId);
    if (tf && tf.detailedItem) {
      return undefined;
    }
    return dispatch(createGetFamilyAction(toolFamilyId));
  };
};

/**
 * Requests all tool families
 */
export const getFamiliesDetails = (toolFamilyIds: Array<string>): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    if (toolFamilyIds) {
      return Promise.all(toolFamilyIds.map(tf => dispatch(getFamily(tf))));
    }
    return undefined;
  };
};

/**
 * Requests all tool families
 */
export const getAllFamilyDetails = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const store: TosRootState = getState();
    if (store.families.items && !store.families.isFetching) {
      const tfIds: Array<string> = store.families.items.map((tf: any) => tf.id);
      const batches = chunk(tfIds, 6);
      for (let i = 0; i < batches.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(getFamiliesDetails(batches[i]));
      }
    }
  };
};

const chunk = (arr: Array<any>, chunkSize: number) => {
  const batches = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    batches.push(arr.slice(i, i + chunkSize));
  }
  return batches;
};

const createGetConfigurationsAction = (toolSelectionId: string, machineModelId: string): AnyAction => ({
  type: 'thunk',
  [CALL_API]: {
    types: [GET_TOOLSEL_CONFIG_REQ, GET_TOOLSEL_CONFIG_REQ_SUCCESS, GET_TOOLSEL_CONFIG_REQ_FAILURE],
    pathname: `/v1/surface-prep/configurations?machineModelId=${machineModelId}&toolSelectionId=${toolSelectionId}`,
    context: {
      toolSelectionId,
      machineModelId
    }
  }
});

/**
 * Requests configuration per tool selection and machine model
 */
export const getConfigurations = (toolSelectionId: string, machineModelId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: Function): Promise<any> => {
    const existingConfig = getState().machineModelConfigurations.itemsById.get(keyFrom({ toolSelectionId, machineModelId }));
    if (!existingConfig) {
      return dispatch(createGetConfigurationsAction(toolSelectionId, machineModelId));
    }
    return undefined;
  };
};

/**
 * Adds a new step, i.e. a new tool family, for a recipe (with or without machineModelId), xxxxxxxx
 */
export const clearMachineConfigurations = (): AnyAction => {
  return {
    type: CLEAR_TOOLSEL_CONFIG
  };
};

/**
 * Sets tool selection for a particilar step in a recipe
 */
export const setDefaultSelection = (recipeId: string, machineModelId: string, stepDefaultSelection: RecipeSelection): RecipeConfigActionTypes => {
  return {
    type: ADD_RECIPE_CONFIG,
    item: {
      recipeId,
      machineModelId,
      stepDefaultSelection
    }
  };
};

/**
 * Sets tool selection for a particilar step in a recipe
 */
export const setConfigurationOngoing = (step: RecipeStep | undefined): RecipeConfigActionTypes => {
  return {
    type: SET_RECIPE_STEP_CONFIG_ONGOING,
    item: step
  };
};

/**
 * Sets tool selection for a particilar step in a recipe
 */
export const removeDefaultSelection = (recipeId: RecipeId, machineModelId: MachineModelId, toolFamilyId: ToolFamilyId): RecipeConfigActionTypes => {
  return {
    type: REMOVE_RECIPE_CONFIG,
    item: {
      recipeId,
      machineModelId,
      toolFamilyId
    }
  };
};

/**
 * Adds a new step, i.e. a new tool family, for a recipe (with or without machineModelId)
 * @param recipeId
 * @param machineModelId
 * @param toolFamilyId
 */
export const addMachineRecipeStep = (recipeId: RecipeId, toolFamily: ToolFamilyModel, machineModelId?: MachineModelId): AnyAction => {
  return {
    type: RECIPE_ADD_STEP,
    item: {
      recipeId,
      toolFamily,
      machineModelId
    }
  };
};

/**
 * Moves a step, i.e. a tool family, in order
 * @param recipeId
 * @param machineModelId
 * @param toolFamilyId
 */
export const rearrangeMachineRecipeSteps = (steps: Array<RecipeStep>, recipeId?: any): AnyAction => {
  return {
    type: RECIPE_SET_STEPS,
    recipeId,
    steps
  };
};

/**
 * Adds a new step, i.e. a new tool family, for a recipe (with or without machineModelId)
 * @param recipeId
 * @param machineModelId
 * @param toolFamilyId
 */
export const removeMachineRecipeStep = (recipeId: RecipeId, toolFamilyId: ToolFamilyId, machineModelId?: MachineModelId): AnyAction => {
  return {
    type: RECIPE_REMOVE_STEP,
    item: {
      recipeId,
      toolFamilyId,
      machineModelId
    }
  };
};
