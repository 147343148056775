import { Reducer } from 'redux';
import { MaterialModelWithTexts } from 'domain/models/material-type-model';
import * as actionConstants from 'reducers/action-constants';

interface ContentMaterialsStore {
  isLoadingMaterial: boolean;
  isSavingMaterialTexts: boolean;
  isSavingNewMaterial: boolean;
  loadedMaterial?: MaterialModelWithTexts;
  createdMaterial?: object;
  suggestedUIOrder?: string;
  isLoadingSuggestedUIOrder: boolean;
}

const getInitialState = () => ({
  isLoadingMaterial: false,
  isSavingMaterialTexts: false,
  isSavingNewMaterial: false,
  isLoadingSuggestedUIOrder: false
});

const reducer: Reducer<ContentMaterialsStore> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case actionConstants.MATERIAL_REQ:
      return {
        ...state,
        isLoadingMaterial: true,
        loadedMaterial: undefined,
      };

    case actionConstants.MATERIAL_REQ_SUCCESS:
      return {
        ...state,
        isLoadingMaterial: false,
        loadedMaterial: action.response,
      };

    case actionConstants.MATERIAL_REQ_FAILURE:
      return {
        ...state,
        isLoadingMaterial: false,
      };

    case actionConstants.SAVE_MATERIAL_TEXTS_REQ:
      return {
        ...state,
        isSavingMaterialTexts: true,
      };

    case actionConstants.SAVE_MATERIAL_TEXTS_REQ_SUCCESS:
    case actionConstants.SAVE_MATERIAL_TEXTS_REQ_FAILURE:
      return {
        ...state,
        isSavingMaterialTexts: false,
      };

    case actionConstants.SAVE_NEW_MATERIAL_REQ:
      return {
        ...state,
        isSavingNewMaterial: true,
      };

    case actionConstants.SAVE_NEW_MATERIAL_REQ_SUCCESS:
      return {
        ...state,
        isSavingNewMaterial: false,
        createdMaterial: action?.response,
      };

    case actionConstants.SAVE_NEW_MATERIAL_REQ_FAILURE:
      return {
        ...state,
        isSavingNewMaterial: false,
      };

    case actionConstants.RESET_CREATED_MATERIAL:
      return {
        ...state,
        createdMaterial: undefined
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ:
      return {
        ...state,
        isLoadingSuggestedUIOrder: true
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_SUCCESS:
      return {
        ...state,
        suggestedUIOrder: action.response?.suggestedUIOrder,
        isLoadingSuggestedUIOrder: false
      };

    case actionConstants.GET_SUGGESTED_MATERIAL_UI_ORDER_REQ_FAILURE:
      return {
        ...state,
        suggestedUIOrder: undefined,
        isLoadingSuggestedUIOrder: false
      };

    case actionConstants.RESET_SUGGESTED_UI_ORDER:
      return {
        ...state,
        suggestedUIOrder: undefined
      };

    default:
      return state;
  }
};

export default reducer;
