import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import WaitPage from 'app/wait/page';
import * as actions from 'domain/stats/container/actions';
import { TosRootState } from 'reducers';

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & RouteComponentProps;

const LoginsMapContainer: FC<Props> = (props) => {
  // const [geoLookups, setGeoLookups] = useState<any[]>([]);
  const [isGeoLookupIssued, setIsGeoLookupIssued] = useState(false);

  const init = () => {
    props.getSessionIps();
  };
  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performGeoLookup = async () => {
    // const geoLookupsResults = await Promise.all(props.ipArray.map(async (item) => {
    //   const results = await fetch(`http://free.ipwhois.io/json/${item.ipSource}`);
    //   if (results.ok) {
    //     const response = await results.json();
    //     setGeoLookups(geoLookups.concat([response]));
    //   }
    // }));
    // eslint-disable-next-line max-len
    // {"ip":"78.69.215.143","success":true,"type":"IPv4","continent":"Europe","continent_code":"EU","country":"Sweden","country_code":"SE","country_flag":"https:\/\/cdn.ipwhois.io\/flags\/se.svg","country_capital":"Stockholm","country_phone":"+46","country_neighbours":"NO,FI","region":"V\u00e4stra G\u00f6taland County","city":"Tidaholm","latitude":"58.1817692","longitude":"13.9594737","asn":"AS3301","org":"Telia Company AB","isp":"Telia Company AB","timezone":"Europe\/Stockholm","timezone_name":"Central European Standard Time","timezone_dstOffset":"0","timezone_gmtOffset":"3600","timezone_gmt":"GMT +1:00","currency":"Swedish Krona","currency_code":"SEK","currency_symbol":"kr","currency_rates":"9.631715","currency_plural":"Swedish kronor","completed_requests":2}
  };

  useEffect(() => {
    if (props.ipsFetched && props.ipArray.length && !isGeoLookupIssued) {
      setIsGeoLookupIssued(true);
      performGeoLookup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ipArray]);

  return !props.ipsFetched ? <WaitPage /> : <div>{ props.ipArray.map(ip => <div>{ip.ipSource}</div>) }</div>;
};

const mapStateToProps = (state: TosRootState) => {
  return {
    ipsFetched: state.stat.ipsFetched,
    ipArray: state.stat.ips
  };
};

const connector = connect(
  mapStateToProps,
  actions
);

export default connector(LoginsMapContainer);
