import React, { FC, useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './dropzone.scss';

interface Props {
  title?: string
  imageUrl?: string
  imageSize?: number
  accept?: string
  acceptMoreThanOneFile?: boolean
  onFilesDropped: (files: File[]) => void
}

const FileDropzone: FC<Props> = ({
  title = 'Upload new picture? Click or drop files here.',
  accept = 'image/*',
  acceptMoreThanOneFile = false,
  onFilesDropped,
  imageUrl,
  imageSize
}) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setFiles([]); // If there is a new imageUrl the local ones should no longer be rendered.
  }, [imageUrl]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    let droppedFiles = acceptedFiles;
    if (!acceptMoreThanOneFile && acceptedFiles.length > 1) {
      setMessage('Only one file accepted');
      droppedFiles = [droppedFiles.shift()];
    } else if (rejectedFiles.length) {
      droppedFiles = [];
      setMessage('File not accepted');
    } else {
      setMessage('');
    }
    onFilesDropped(droppedFiles);
    setFiles(droppedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getZoneLabel = (isDragActive: boolean) => {
    if (files.length) {
      return files.map((file: any) => (
        <div className={`thumb${isDragActive ? ' drag' : ''}`} key={file.name}>
          <div className="thumbInner">
            <div style={{ backgroundImage: `url(${file.preview})` }} title={`Image size: ${imageSize}`}></div>
            <div>
              <div>
                { title }
              </div>
              <div><i className="fas fa-file-upload"></i></div>
            </div>
          </div>
        </div>
      ));
    }

    const imgStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};
    return <div className={`thumb${isDragActive ? ' drag' : ''}`}>
      <div className="thumbInner">
        { imageUrl && <div style={imgStyle} title={`Image size: ${imageSize}`}></div> }
        <div>
          <div>
            { title }
          </div>
          <div><i className="fas fa-file-upload"></i></div>
        </div>
      </div>
    </div>;

  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop
  });

  return <div className="dropz-comp">
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
         getZoneLabel(isDragActive)
      }
    </div>
    { /* <aside style={thumbsContainer}>
        {thumbs}
    </aside> */ }
    {message}
  </div>;
};
export default FileDropzone;
