import {
  MACHINE_RECIPE_REQ, MACHINE_RECIPE_REQ_SUCCESS, MACHINE_RECIPE_REQ_FAILURE,
  RECIPE_ADD_STEP, RECIPE_REMOVE_STEP, COPY_MACHINE_CONF_REQ, RECIPE_SAVE_STEPS_REQ_SUCCESS,
  CLEAR_MACHINE_RECIPE, RECIPE_SET_STEPS
} from 'reducers/action-constants';

export interface MachineRecipeStore {
  isFetching: boolean
  isFetched?: boolean
  params?: {
    machineModelId: string,
    toolSystemId: string,
    recipeId: string
  }
  item?: MachineRecipeItem
}

export interface MachineRecipeItem {
  steps: Array<RecipeStep>
  stepsDirty?: boolean
  selections: Array<RecipeSelection>
}
export interface MachineRecipeModel {
  steps: Array<RecipeStep>
  selections?: Array<RecipeSelection>
}

export interface RecipeStep {
  stepNo: number
  toolFamilyId: string
  toolFamilyName: string
  toolFamilyGritSize?: string
  toolFamilyType: SVGAnimatedString
}

export interface RecipeSelection {
  id?: string
  recipeId: string
  toolFamilyId: string
  configurationId: string
  toolSelectionId: string
  area: string
  inactive?: boolean
}

const getInitialState = () => ({
  isFetching: false
});

const appendStep = (action: any, storeItem?: MachineRecipeItem): MachineRecipeItem => {
  const results = {
    ...storeItem || { steps: [], selections: [] }
  };

  const { toolFamily } = action.item;
  const step: RecipeStep = {
    stepNo: 33,
    toolFamilyId: toolFamily.id,
    toolFamilyName: toolFamily.name,
    toolFamilyGritSize: toolFamily.gritSize,
    toolFamilyType: toolFamily.type
  };
  results.steps.push(step);
  results.stepsDirty = true;

  return results;
};

const removeStep = (action: any, storeItem?: MachineRecipeItem): MachineRecipeItem => {
  const results = {
    ...storeItem || { steps: [], selections: [] }
  };

  const { toolFamilyId } = action.item;
  results.steps = results.steps.filter(s => s.toolFamilyId !== toolFamilyId);
  results.stepsDirty = true;

  return results;
};

const setSteps = (action: any, storeItem?: MachineRecipeItem): MachineRecipeItem => {
  const results = {
    selections: [],
    ...storeItem,
    steps: action.steps,
    stepsDirty: true
  };
  return results;
};

export default (state: MachineRecipeStore = getInitialState(), action: any): MachineRecipeStore => {
  switch (action.type) {
    case MACHINE_RECIPE_REQ:
      return {
        ...state,
        isFetching: true,
        params: action.context
      };
    case MACHINE_RECIPE_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        item: action.response,
        params: action.context
      };
    case MACHINE_RECIPE_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetched: false
      };
    case CLEAR_MACHINE_RECIPE:
    case COPY_MACHINE_CONF_REQ:
      return getInitialState();
    case RECIPE_ADD_STEP:
      return {
        ...state,
        item: appendStep(action, state.item)
      };
    case RECIPE_SAVE_STEPS_REQ_SUCCESS:
      if (!state.item) {
        return {
          ...state
        };
      }
      return {
        ...state,
        item: {
          ...state.item,
          stepsDirty: false
        }
      };
    case RECIPE_REMOVE_STEP:
      return {
        ...state,
        item: removeStep(action, state.item)
      };
    case RECIPE_SET_STEPS:
      return {
        ...state,
        item: setSteps(action, state.item)
      };
    default:
      return state;
  }
};
