import React, { FC, useState } from 'react';
import Input from 'app/form/labeled-input/input';
import RecipeUsages from 'domain/tools/components/view/usage/usage-table';
import { productNumber } from 'app/utils/format';
import { ToolApiModel } from 'domain/tools/container/reducer';
import './tool-page.scss';

export interface Props {
  tool: ToolApiModel;
}

const ToolPage: FC<Props> = ({ tool }) => {
  const [displayMore, setDisplayMore] = useState(false);
  const uniqueRecipeIds = tool.recipes.reduce((uniques, curr) => {
    uniques.add(curr.recipeId);
    return uniques;
  }, new Set());

  return <div className="tool-cmp">

    <div className="card-title">Tool</div>
    <div className="double-cols">
      <div>
        <Input label="Name" value={tool.name} name="n" disabled />
        <Input label="Model" value={tool.model} name="md" disabled width="normal" />
        <Input label="Product Number" value={productNumber(tool.productNumber)} name="pn" disabled width="normal" />
        <Input label="Connector" value={tool.connectorTypeId} name="c" disabled width="normal" />
        <Input label="Diameter" value={tool.diameter} name="d" disabled width="short" />
      </div>
      <div><img src={tool.imageURL} alt={tool.imageId} /></div>
    </div>

    <div className="card usage">
      <div className="card-title">Usage</div>
      { (!tool.recipes || !tool.recipes.length) && <p>This tool is not used in any recipes.</p>}
      { (tool.recipes && !!tool.recipes.length) && <div>
        <p>This tool is used in {uniqueRecipeIds.size} recipe{uniqueRecipeIds.size > 1 && 's'}
          ({tool.recipes.length} machine model combination{tool.recipes.length > 1 && 's'}).</p>
        <RecipeUsages recipes={tool.recipes} />
      </div> }
    </div>

    <div className="more-action" role="button" onClick={() => { setDisplayMore(!displayMore); }}>
      Details { displayMore ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
    </div>

    { displayMore && <div className="card">
      <div className="card-title">Details</div>
      <div className="values">
        <div>Tool selection Id</div>
        <div className="value">{tool.id}</div>
      </div>
      <div className="values">
        <div>Dimr tool Id</div>
        <div className="value">{tool.dimrToolId}</div>
      </div>
      <div className="values">
        <div>Updated</div>
        <div className="value">{tool.updatedAt}</div>
      </div>
    </div>}
    <div></div>
  </div>;
};

export default ToolPage;
