import React, { FC, useState, useEffect, useCallback } from 'react';
import './recipe-design-table.scss';
import PubSub from 'app/utils/pubsub';
import StepRow from 'domain/recipe-designer/components/table/row/step-row';
import StepEditor from 'domain/recipe-designer/components/editor/form/editor';
import { useSelector } from 'reducers/index';
import { MachineRecipeModel, RecipeStep } from 'domain/recipe-designer/container/machine-recipe-reducer';
import { ToolFamilyModel } from 'domain/models/tool-family';
import { RecipeConfigModel } from 'domain/recipe-designer/container/recipe-config-reducer';

import { MachineModelConfigurationModel } from 'domain/recipe-designer/container/machine-configurations-reducer';
import AddStepDialog from 'domain/recipe-designer/components/editor/actions/add-step';
import CopyMachineConfig from 'domain/recipe-designer/components/editor/actions/copy/container/copy';

interface Props {
  readonly recipe: MachineRecipeModel
  readonly machineModelId?: string
  readonly toolFamiliesById: Map<string, ToolFamilyModel>
  onSave: (config: any) => void
  onDeleteConfiguration: (step: RecipeStep) => void
  onDeleteStep: (step: RecipeStep) => void
  onStepMoveUp: (step: RecipeStep) => void
  onStepMoveDown: (step: RecipeStep) => void
  onToolSelected: (toolSelectionId: string, machineModelId?: string) => void
  onToolFamilyAdded: (newToolFamily: ToolFamilyModel) => void
  onConfigureStep: (newToolFamily: ToolFamilyModel, step: RecipeStep) => void
  onCancelConfigureStep: () => void
  configMap: Map<string, MachineModelConfigurationModel>
  recipeConfig?: RecipeConfigModel
  stepClickedSubscription: PubSub
  ongoingConfigurationStep?: RecipeStep
}

const RecipeDesignTable: FC<Props> = ({
  recipe,
  machineModelId,
  toolFamiliesById,
  configMap,
  recipeConfig,
  onToolFamilyAdded,
  onSave,
  onDeleteConfiguration,
  onDeleteStep,
  onStepMoveUp,
  onConfigureStep,
  onCancelConfigureStep,
  onStepMoveDown,
  stepClickedSubscription,
  ongoingConfigurationStep
}: Props) => {
  const [addingStep, setAddingStep] = useState(false);
  const [copyDismissed, setCopyDismissed] = useState(false);

  const onAddStep = useCallback((payload) => {
    if (payload === 'add-step-clicked') {
      setAddingStep(true);
    }
  }, [setAddingStep]);

  useEffect(() => {
    stepClickedSubscription.subscribe(onAddStep);
    return () => {
      stepClickedSubscription.unsubscribe(onAddStep);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepsDirty = useSelector(state => state.machineRecipe.item && state.machineRecipe.item.stepsDirty
  );

  const availableCopyMachines = useSelector(state => state.recipesFilter.recipes?.filters.machines.length
  );

  const marketId = useSelector(state => state.recipesFilter.fetchedFilter?.marketId
  );

  const save = (config: any) => {
    onSave(config);
  };

  const onSaveAddStep = (newToolFamily: ToolFamilyModel) => {
    setAddingStep(false);
    onToolFamilyAdded(newToolFamily);
  };
  useEffect(() => {
    setCopyDismissed(false);
  }, [machineModelId]);

  const showCopy = recipeConfig && recipeConfig.recipeId && recipeConfig.machineModelId && recipeConfig.stepConfig.size === 0 && availableCopyMachines;
  const showEditor = !!(machineModelId && ongoingConfigurationStep);

  return <div className="rd-tbl-cp">
    <div className={`body${showEditor ? ' show-editor' : ''}`}>
      <div className={`step-rows ${ongoingConfigurationStep ? 'editing' : ''}`}>
        {recipe.steps.map((s, i) => <StepRow
          step={s}
          configMap={configMap}
          onStepMoveUp={onStepMoveUp}
          onStepMoveDown={onStepMoveDown}
          machineModelId={machineModelId || ''}
          toolFamily={toolFamiliesById.get(s.toolFamilyId)}
          isActive={ongoingConfigurationStep === s}
          isEditingDisabled={!machineModelId || stepsDirty || (!copyDismissed && showCopy)}
          isEditing={!!ongoingConfigurationStep}
          stepConfig={recipeConfig && recipeConfig.stepConfig.get(s.toolFamilyId)}
          key={s.toolFamilyId}
          onConfigureStep={(step: RecipeStep) => {
            const tf = toolFamiliesById.get(step.toolFamilyId);
            if (tf) {
              onConfigureStep(tf, step);
            }
          }}
          onDeleteConfiguration={onDeleteConfiguration}
          onDeleteStep={onDeleteStep}
          order={i} />) }
      </div>
    </div>
    { (machineModelId && ongoingConfigurationStep) && <div className="editor">
      <StepEditor
        step={ongoingConfigurationStep}
        stepConfig={recipeConfig && recipeConfig.stepConfig.get(ongoingConfigurationStep.toolFamilyId)}
        machineModelId={machineModelId}
        toolFamiliesById={toolFamiliesById}
        configMap={configMap}
        onCancel={() => { onCancelConfigureStep(); }}
        onSave={save} />
      </div> }

    { (!copyDismissed && showCopy) && <CopyMachineConfig
      onDismissCopy={() => { setCopyDismissed(true); }} /> }

    { (recipe.steps && recipe.steps.length === 0) && <div className="editor empty">
      <p>Proceed by <button className="no-btn" onClick={() => { setAddingStep(true); }} type="button">adding one or more steps</button> to the recipe.</p>
    </div>}
    { (!machineModelId && !(recipe.steps && recipe.steps.length === 0)) &&
      <div className="editor empty"><span>Choose machine model, to add configurations</span></div> }
    <AddStepDialog
      show={addingStep}
      onToolFamilyAdded={onSaveAddStep}
      onCancel={() => { setAddingStep(false); }}
      filterByMarketId={marketId}
      toolFamiliesById={toolFamiliesById} />
  </div>;
};

export default RecipeDesignTable;
