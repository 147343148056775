import React, { FC, useMemo } from 'react';
import { UserApiModel, UserId } from 'domain/users/container/user-models';
import LabeledInput from 'app/form/labeled-input/input';
import Input from 'app/form/input/input';
import Select from 'app/form/select/select';
import './user.scss';
import { DetailedUserApiModel } from 'domain/users/container/reducer';

interface Props {
  userId: UserId
  users: Array<UserApiModel>
  userDetails?: DetailedUserApiModel
  onUserChanged: (user: UserApiModel) => void
}

const markets = [{
  value: 'US',
  label: 'US Market'
}, {
  value: 'BRAZIL',
  label: 'Brazil Market'
}, {
  value: 'AU',
  label: 'Australia Market'
}, {
  value: 'GLOBAL',
  label: 'Global Market'
}];

const UserEdit: FC<Props> = ({ users, userId, userDetails, onUserChanged }) => {
  const user = useMemo(
    () => users.find(u => u.uid === userId),
    [users, userId]
  );
  const sessions = useMemo(
    () => {
      if (userDetails && userDetails.sessions) {
        userDetails.sessions.sort((s1, s2) => { return s2.createdAt.localeCompare(s1.createdAt); });
        return userDetails.sessions;
      }
      return [];
    },
    [userDetails]
  );
  const marketChanged = (value: string, currUser: UserApiModel) => {
    const editUser = currUser;
    editUser.marketId = value;
    onUserChanged(editUser);
  };
  const superAdmChanged = (value: boolean, currUser: UserApiModel) => {
    const editUser = currUser;
    editUser.isSuperAdmin = value;
    onUserChanged(editUser);
  };
  const recipeAdmChanged = (value: boolean, currUser: UserApiModel) => {
    const editUser = currUser;
    editUser.isRecipeAdmin = value;
    onUserChanged(editUser);
  };
  if (!user) {
    return <div>Select user</div>;
  }
  return <div className="user-edit-view">
    <div>
      <div className="header">User</div>
      <div>
        <LabeledInput
          label="UID"
          type="text"
          name="uidx"
          value={user?.uid || ''}
          disabled
          />
      </div>
      <div className="values">
        <div>Recipe Admin</div>
        <div className="value">
          <Input type="checkbox" name="recipeAdm" checked={user?.isRecipeAdmin || false} onChange={() => recipeAdmChanged(!user.isRecipeAdmin, user)} />
        </div>
      </div>
      <div className="values">
        <div>User Admin</div>
        <div className="value">
          <Input type="checkbox" name="superAdm" checked={user?.isSuperAdmin || false} onChange={() => superAdmChanged(!user.isSuperAdmin, user)} />
        </div>
      </div>

      <div>
        <LabeledInput
          label="Sales company"
          type="text"
          name="salesCompanyCode"
          value={user?.salesCompanyCode || ''}
          disabled />
      </div>
      <div>
        <LabeledInput
          label="Name"
          type="text"
          name="fullName"
          value={userDetails?.fullName || ''}
          disabled />
      </div>
      <div>
        <LabeledInput
          label="Email"
          type="text"
          name="email"
          value={userDetails?.email || ''}
          disabled />
      </div>
      <div className="values">
        <div>Market</div>
        <div className="value">
          <Select
            name="market"
            blankOption
            value={user?.marketId || ''}
            selectables={markets}
            onChange={(e: any) => { marketChanged(e.target.value, user); }} />
        </div>
      </div>
      <div>{ !sessions ? 'Fetching...' : `Sessions (${sessions.length})`} </div>
      { sessions && <div className="sessions">
        <div className="header">
          <div>Session Id</div>
          <div>Login date</div>
          <div></div>
        </div>
        {sessions.map(s => <div key={s.id}>
          <div>{s.id}</div>
          <div>{s.createdAt}</div>
          <div>{s.client === 'APP' ? <i className="fab fa-apple" /> : <i className="fas fa-laptop" />}</div>
        </div>)}
      </div> }
    </div>
  </div>;
};

export default UserEdit;
