import {
  GET_FAMILIES_REQ, GET_FAMILIES_REQ_SUCCESS, GET_FAMILIES_REQ_FAILURE,
  GET_FAMILY_DETAIL_REQ, GET_FAMILY_DETAIL_REQ_SUCCESS, GET_FAMILY_DETAIL_REQ_FAILURE
} from 'reducers/action-constants';

import { ToolFamilyModel } from 'domain/models/tool-family';

export interface FamiliesStore {
  isFetching: boolean,
  isFetchingDetails: number,
  isFetchedDetails: number,
  items?: Array<ToolFamilyModel>
  itemsById?: Map<string, ToolFamilyModel>
}

const byId = (list: Array<any>) => {
  const results = new Map<string, any>();
  list.forEach(item => results.set(item.id, item));
  return results;
};

const updateItem = (toolFamilyId: string, tfById: Map<string, any>, toolFamilyDetailed?: ToolFamilyModel,) => {
  const tf = tfById.get(toolFamilyId);
  if (!tf.detailedItem && !toolFamilyDetailed) {
    tf.detailedItem = {
      isFetched: false
    };
  } else {
    tf.detailedItem = toolFamilyDetailed;
  }
  return new Map(tfById);
};

export default (state: FamiliesStore = { isFetching: false, isFetchingDetails: 0, isFetchedDetails: 0 }, action: any) => {
  switch (action.type) {
    case GET_FAMILIES_REQ:
      return {
        ...state,
        isFetching: true,
        isFetchedDetails: 0
      };
    case GET_FAMILY_DETAIL_REQ:
      return {
        ...state,
        isFetching: true,
        isFetchingDetails: state.isFetchingDetails + 1,
        // @ts-ignore
        itemsById: updateItem(action.context.toolFamilyId, state.itemsById, undefined)
      };
    case GET_FAMILIES_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.response,
        itemsById: action.response && byId(action.response)
      };
    case GET_FAMILY_DETAIL_REQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingDetails: state.isFetchingDetails - 1,
        isFetchedDetails: state.isFetchedDetails + 1,
        // @ts-ignore
        itemsById: updateItem(action.response.id, state.itemsById, action.response)
      };
    case GET_FAMILIES_REQ_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case GET_FAMILY_DETAIL_REQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingDetails: state.isFetchingDetails - 1
      };
    default:
      return state;
  }
};
